import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BetaGuard  {

  constructor(private oidcIdentityService: OidcSecurityService) {
  }

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasBetaRole()
  }

  hasBetaRole() {
    return this.oidcIdentityService.userData$.pipe( map((userData) => {
        let roles = userData.userData.roles
        return roles != undefined && roles.includes('beta')
      })
    )
  }

}
