<div #appToggleSideMenus class="menu">
  <div class="menu-content ux-revamp">

    <div class="chat-history-header">
      <span class="chat-history-span">Chat History</span>
      <ng-content></ng-content>
    </div>
    <ul class="ul-chat-menu">
      <li id="{{chat.id}}" [ngClass]="{'li-selected' : isMenuItemSelected(chat.id)}" class="li-chat-menu"
        *ngFor="let chat of sortedChats | async" (click)="selectChat(chat)">
        <fa-icon class="message-icon" [icon]="faMessage"></fa-icon>
        <span *ngIf="!isEditing(chat.id)">{{ (chat.title.length > 50) ?
          (chat.title | slice:0:50) + '...' : (chat.title) }}</span>
        <img *ngIf="!isEditing(chat.id)" src="assets/icons/edit alt/Size=24px, Style=Mono.svg" class="chat-icon-edit"
          id="iconEditChatId" (click)="startEditing(chat.title)">
        <fa-icon *ngIf="!isEditing(chat.id)" class="chat-icon-delete" [icon]="faTrashCan" id="iconDeleteChatId"
          (click)="openDeleteModal()"></fa-icon>
        <textarea class="textarea-title" *ngIf="isEditing(chat.id) && isMenuItemSelected(chat.id)"
          [(ngModel)]="inputValue" (blur)="stopEditing(undefined)" (keydown)="onKeyDown($event, chat)" minlength="3"
          maxlength="50" rows="1" (input)="autoSizeTextArea()"></textarea>
      </li>
    </ul>
    <kmd-alerts kmd-success *ngIf="alertType === 'success'" [(show)]="showAlert" [alertText]="alertText"
      [floating]="true"></kmd-alerts>
    <kmd-alerts kmd-error *ngIf="alertType === 'error'" [(show)]="showAlert" [alertText]="alertText"
      [floating]="true"></kmd-alerts>
    <kmd-modal [id]="'delete-chat-modal'" modalSize="mini" headline="Delete chat">
      <div id="text">
        You're about to delete this chat, this action is permanent and you won't be able to recover it. Do you want
        to delete it?
      </div>
      <div class="modal-buttons-container">
        <button id="btnCancelDeleteChatId" (click)="closeDeleteModal()" kmdSecondaryButton>Cancel</button>
        <button id="btnDeleteChatId" (click)="deleteChat(selectedChat!)" kmdFeaturedButton>Delete</button>
      </div>
    </kmd-modal>
  </div>
</div>
