<div class="assistant-message-bubble-header">
  <img src="assets/icons/chat-assistant/chat-assistant-icon.svg" class="assistant-message-bubble-header-icon"
       alt="Agent icon" height="40" width="40">
</div>

<div class="assistant-message-response-container">
  <div class="assistant-message-bubble">
    <ng-container *ngIf="!isGeneratingResponse && messageNode; else generatingResponse">
      <markdown katex mermaid class="markdown-wrapper message assistant" (ready)="onMarkdownReady()"
                [data]="messageNode.content.message!.text"></markdown>
      <div *ngIf="filesUrls.length > 0">
        <markdown *ngFor="let file of filesUrls" katex mermaid class="markdown-wrapper message" (ready)="onMarkdownReady()"
                  [data]="file"></markdown>
      </div>
      <div *ngIf="showReferences()">
        <div id="sources-display-control" (click)="toggleReferenceDisplay()">
          <b>References</b>
          <img class="sources-caret" src="assets/icons/caret/{{caretDisplay()}}-11px-mono-blue.svg">
        </div>
        <div id="web-search-sources-container" *ngIf="showAllSources">
          <b>{{ getSourcesTitle(messageNode.content.message!.sources) }}</b>
          <div *ngFor="let source of messageNode.content.message!.sources; let i = index">
            <a class="web-search-sources" [href]="source.url" target="_blank" *ngIf="i < 5">
              {{ getSourceDisplayTitle(source) }}
            </a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #generatingResponse>
      <div class="generating-response-container">
        <kmd-loading-wheel></kmd-loading-wheel>
        <p>Generating response...</p>
      </div>
    </ng-template>
  </div>
  <div *ngIf="!isGeneratingResponse && messageNode" class="assistant-message-bubble-icons-container">
    <ng-container *ngIf="hasMultipleMessages">
      <div class="multi-response-controls">
        <i id="back-button" class="icon" (click)="showPreviousResponse()"
           [ngClass]="{'disabled': !isPreviousResponseAvailable}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 6.5H6.75L10.12 3.12L8 1L1 8L8 15L10.12 12.88L6.75 9.5H15V6.5Z" fill="#70707A"/>
          </svg>
        </i>
        <span>{{ messageNode.siblingIndex + 1 }}/{{ messageNode.sibling.length + 1 }}</span>
        <i id="forward-button" class="icon" (click)="showNextResponse()"
           [ngClass]="{'disabled': !isNextResponseAvailable}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1 6.5H9.26001L5.88 3.12L8 1L15 8L8 15L5.88 12.88L9.26001 9.5H1V6.5Z" fill="#70707A"/>
          </svg>
        </i>
      </div>
    </ng-container>
    <i appTooltip text="Regenerate response" position="bottom" id="assistant-message-regenerate-response" class="icon" (click)="triggerRegenerateResponse()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6024_92046)">
          <path d="M14.1112 3.55542V6.88875H10.7778" stroke="#70707A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M1.88892 12.4444V9.11108H5.22225" stroke="#70707A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path
            d="M3.28336 6.33324C3.56512 5.53701 4.04399 4.82513 4.67529 4.26403C5.30658 3.70293 6.06973 3.31089 6.89352 3.12449C7.71731 2.9381 8.57489 2.96343 9.38624 3.19811C10.1976 3.4328 10.9363 3.86919 11.5334 4.46657L14.1111 6.8888M1.88892 9.11102L4.46669 11.5332C5.06378 12.1306 5.80246 12.567 6.61381 12.8017C7.42516 13.0364 8.28275 13.0617 9.10653 12.8753C9.93032 12.6889 10.6935 12.2969 11.3248 11.7358C11.9561 11.1747 12.4349 10.4628 12.7167 9.66657"
            stroke="#70707A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_6024_92046">
            <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.33337 1.33325)"/>
          </clipPath>
        </defs>
      </svg>
    </i>
    <i appTooltip text="Copy response" position="bottom" id="assistant-message-bubble-copy" class="icon" (click)="copyAssistantPrompt()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 4.7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z"
              fill="#70707A"/>
      </svg>
    </i>
  </div>
</div>
