<div class="document-translations-notification">
  <div class="notification-dt">
    <div class="notification-right-container">
      <h2 class="notification-header">Not for customer facing translation</h2>
      <p class="notification-info">
        All customer-facing documents must follow a separate translation procedure.
      </p>
      <p class="notification-link">
        <a href="https://thermofisher.sharepoint.com/sites/LanguageServices" target="blank">
          <img src="assets/icons/instrument/instrument.svg" alt="Icon/Science/Instrument">
          <strong>Contact translation services</strong>
        </a>
      </p>
    </div>
  </div>
</div>
