import { CCD_ALL_DATA_ROLE, CCD_NO_CDMO_ROLE, CCD_NO_CRO_ROLE } from "../ccd-roles.constants"

export const askOurDocsRoles = {
  IndexPrefix: "dpe_geneai_index_"
}

export const askOurDataRole = {
  prefix: "dpe_geneai_aodata_"
}

export const sva2Roles = {
  Index: "dpe_geneai_index_Services_Virtual_Assistant"
}

export const ccdRoles = [
  CCD_ALL_DATA_ROLE,CCD_NO_CRO_ROLE,CCD_NO_CDMO_ROLE
]
