<app-modal [id]="id" [headline]="headline">
    <div #modalContent class="member-list-content" [ngClass]="{'scrollable': scrollableModal}">
        <div class="top">
            <p>
                Please verify who you are sharing with. We will only show the first {{MEMBERS_TO_SHOW_COUNT}} group members, you can see the rest
                in <a href="https://myaccount.microsoft.com/groups/groups-i-belong-to" target="_blank">groups you are a member of.</a>
            </p>
        </div>
        <div class="middle">
            <table>
                <thead>
                    <tr>
                        <th>
                            <p>Name</p>
                        </th>
                        <th>
                            <p>Email</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let member of membersToShow">
                        <td>
                            <p>{{member.displayName}}</p>
                        </td>
                        <td>
                            <p>{{member.mail}}</p>
                        </td>
                    </tr>
                    <ng-container *ngIf="groupMembers.length > MEMBERS_TO_SHOW_COUNT">
                        <tr>
                            <td colspan="2" class="show-more">
                                <p><a href="https://myaccount.microsoft.com/groups/{{group!.id}}" target="_blank">See all members</a></p>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="bottom">
            <button kmdFeaturedButton id="close-btn" (click)="closeModal()">Close</button>
        </div>
    </div>
</app-modal>