import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgentsService } from "@services/agents/agents.service";
import { PagedAgents } from "@shared/models/paged-agents.model";
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  EMPTY,
  merge
} from "rxjs";
import {
  addAllowShareToAgents,
  Agent,
  AgentCategoryFilters,
  SortOptions,
  SortOptionValues
} from "@shared/models/agent";
import { KmdModalService, KmdPaginationComponent } from "gds-atom-components";
import { UserData } from "@shared/models/user-data.model";
import { Router } from "@angular/router";
import { AlertService } from "@services/alert/alert.service";
import { AgentCardComponent } from '../agent-list/agent-card/agent-card.component';
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-agent-list-paged',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './agent-list-paged.component.html',
  styleUrls: ['./agent-list-paged.component.css']
})
export class AgentListPagedComponent implements OnInit, OnChanges {
  @ViewChild('agentCard') agentCard!: AgentCardComponent;
  @ViewChild('pagination') pagination!: KmdPaginationComponent;
  @Input() type!: 'public' | 'private'| 'shared';
  @Input() userData$!: Observable<UserData>;
  @Input() scrollDiv!: HTMLDivElement;
  @Input() isUserApprover: boolean = false;
  @Input() overviewAgent!: Agent;
  agents!: PagedAgents;

  selectedAgent?: Agent;
  agentToDelete?: Agent;

  categories = [...AgentCategoryFilters];
  sortOptions = [...SortOptions];

  isSmallAgentList: boolean = false;

  readonly RESULTS_PER_PAGE = 15;
  readonly DEBOUNCE_TIME = 500;

  formGroup = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    sorting: new FormControl('A-Z', { nonNullable: true }),
    category: new FormControl('All agents', { nonNullable: true })
  })

  pageFormControl = new FormControl(0, { nonNullable: true })

  agents$: Observable<PagedAgents> = of();

  constructor(
    private agentService: AgentsService,
    private kmdModalService: KmdModalService,
    private router: Router,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    if (this.type == 'private') {
      this.sortOptions.pop();
    }
  }

  ngOnChanges(): void {
    let option = SortOptionValues['A-Z'];
    if (this.type == 'public') {
      this.formGroup.controls.sorting.patchValue('Most popular');
      option = SortOptionValues['Most popular'];
      this.reloadAgentsToFirstPage();
    }

    let initialAgentPage =
      this.getAgents(0, this.RESULTS_PER_PAGE, '', option.field, option.direction, '');

    let searchObservable = this.formGroup.controls.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this.DEBOUNCE_TIME),
      switchMap(
        () => {
          this.formGroup.controls.category.patchValue('All agents');
          return EMPTY;
        }
      ));

    let categoryObservable = this.formGroup.controls.category.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAgentsToFirstPage();
      })
    )

    let sortingObservable = this.formGroup.controls.sorting.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAgentsToFirstPage();
      })
    );

    let pageObservable = this.pageFormControl.valueChanges.pipe(
      switchMap((partial) => {
        let option = SortOptionValues[this.formGroup.controls.sorting.value];
        let category = this.formGroup.controls.category.value === 'All agents' ? '' : this.formGroup.controls.category.value;
        return this.getAgents(partial, this.RESULTS_PER_PAGE, category, option.field, option.direction,
          this.formGroup.controls.search.value);
      })
    )

    this.agents$ = merge(initialAgentPage, searchObservable, categoryObservable, sortingObservable, pageObservable);
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const width = entry.contentRect.width;
        this.isSmallAgentList = width < 580;
      });
    });

    const element = document.querySelector("#public-agents-tab") as Element;

    if (!element) return;

    observer.observe(element);
  }

  private getAgents(page: number, resultsPerPage: number, category: string, sort: string, sortDirection: string, searchTerm?: string): Observable<PagedAgents> {
    let observableOfEmptyPagedAgents: Observable<PagedAgents> = of({
      content: [], totalPages: 0,
      numberOfElements: 0, empty: true, first: true, totalElements: 0, last: true, size: 0, number: 0
    });
    switch (this.type) {
      case 'public':
        return this.agentService.getPagedPublicAgents(page, resultsPerPage, category, sort, sortDirection, searchTerm)
          .pipe(
            map((agents) => {
              return agents;
            }),
            catchError(() => {
              this.alertService.showError("We encountered an unexpected error. Please try again.");
              return observableOfEmptyPagedAgents;
            })
          );
      case "private":
        return this.agentService.getPagedAgents(page, resultsPerPage, category, sort, sortDirection, searchTerm)
          .pipe(
            map((agents) => {
              agents.content = addAllowShareToAgents(agents.content)
              return agents;
            }),
            catchError(() => {
              this.alertService.showError("We encountered an unexpected error. Please try again.");
              return observableOfEmptyPagedAgents;
            })
          );
      default:
        return observableOfEmptyPagedAgents;
    }
  }

  reloadAgents(): Observable<PagedAgents> {
    this.formGroup.controls.category.patchValue('All agents');
    return this.reloadAgentsToFirstPage();
  }

  agentType() {
    return this.type == 'public' ? 'publicAgent' : 'myAgent'
  }

  viewAgent(agent: Agent) {
    this.selectedAgent = agent
    this.kmdModalService.open('page-view-agent-modal')
  }

  closeModal(modal: string) {
    this.kmdModalService.close(modal)
  }

  confirmDeleteAgent(agent: Agent) {
    this.agentToDelete = agent
    if (agent.isPublic) {
      this.kmdModalService.open('page-delete-public-agent-modal')
    } else {
      this.kmdModalService.open('page-delete-agent-modal')
    }
  }

  showPagePublicAgentOverviewModal(agent: Agent): void {
    this.selectedAgent = agent;
    this.kmdModalService.open("page-public-agent-overview");
  };

  deleteAgent() {
    if (!this.agentToDelete?.id) return;
    this.agentService.delete(this.agentToDelete.id)
      .subscribe({
        next: () => {
          this.alertService.showSuccess("Agent deleted correctly.")
          this.reloadAgents()
          this.kmdModalService.close('page-delete-agent-modal')
        },
        error: () => {
          this.alertService.showError("We encountered an unexpected error. Please try again.")
        }
      })
  }

  deletePublicAgent() {
    if (!this.agentToDelete?.id) return;
    let observer = {
      next: () => {
        this.alertService.showSuccess("Agent deleted correctly.");
        this.reloadAgents();
        this.kmdModalService.close("page-delete-public-agent-modal");
      },
      error: () => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
      },
    };
    if (this.agentToDelete!.publishedByUser) {
      this.agentService.deleteByUser(this.agentToDelete.id).subscribe(observer);
    } else {
      this.agentService.deletePublicAgent(this.agentToDelete.id).subscribe(observer);
    }
  }

  publishAgent(agent: Agent) {
    this.agentService.publishAgent(agent.id!).subscribe({
      next: () => {
        this.alertService.showSuccess("Your agent is now available under Public Agents.")
        this.kmdModalService.close('page-public-agent-overview')
        this.reloadAgentsToFirstPage();
      },
      error: () => {
        this.alertService.showError("We encountered an unexpected error. Please try again.")
        this.kmdModalService.close('page-public-agent-overview')
      }
    })
  }

  onPageChange(event: any) {
    this.pageFormControl.patchValue(event.currentPage - 1);
  }

  private reloadAgentsToFirstPage() {
    if (this.pagination) {
      this.pagination.selectPage(1);
    } else {
      this.pageFormControl.patchValue(0);
    }
    return EMPTY;
  }

  goToCreateNewAgent() {
    this.router.navigate(["/agents/builder"])
  }

  emptySearchText() {
    this.formGroup.controls.search.patchValue('');
  }

  protected readonly scroll = scroll;
}
