import { Component, Inject, OnInit } from '@angular/core';
import { KmdModalService } from 'gds-atom-components';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { AlertService } from '@app/core/services/alert/alert.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  questions: any[] = [];
  defaultQuestions: any[] = [];
  questionTypes: string[] = ['Multiple choice', 'Single choice', 'Long text', 'Linear scale'];
  modelHeadline: string = '';
  modelDescription: string = '';
  modelDescriptionTwo: string = '';
  indexName: string = '';
  initialFormValue: any;
  userNavFlag: boolean = false; // this flag will be used to check if user is navigating away from the page with saving.
  showAlertEmptyInputs: boolean = false;
  emptyFieldFlag: boolean = false;
  repoName: string = '';

  constructor(
    @Inject(KmdModalService) private kmdModalService: KmdModalService,
    private askOurDocsV2Service: AskOurDocsV2Service,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.questions = _.cloneDeep(this.defaultQuestions);
    this.indexName = history.state.indexName;
    this.repoName = history.state.repoName;
    this.initialFormValue = this.getFormState();

    this.askOurDocsV2Service.getFeedbackFormData(this.indexName).pipe(
      switchMap((data: any) => {
        if (data?.detail === "404: Feedback form data not found") {
          return this.askOurDocsV2Service.getDefaultFeedbackForm();
        } else {
          return of(data);
        }
      }),
      catchError(error => {
        console.error('Error in getFeedbackFormData:', error);
        // Check if the error response contains the expected data
        if (error.error && error.error.detail === "404: Feedback form data not found") {
          return this.askOurDocsV2Service.getDefaultFeedbackForm();
        }
        return throwError(error);
      })
    ).subscribe(
      (data: any) => {
        this.transformFormData(data);
        this.initialFormValue = this.getFormState();
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  transformFormData(data: any) {
    this.defaultQuestions = data.questions;
    this.defaultQuestions = this.defaultQuestions.map(question => {
      if (question.questionType === 'Linear scale') {
        if (question.startValue?.value) {
          question.startValue.value = question.startValue.value.toString();
        }
        if (question.endValue?.value) {
          question.endValue.value = question.endValue.value.toString();
        }
      }
      if (question.questionType === 'Multiple choice') {
        return { ...question, 'isOtherOptionChecked': false };
      }
      return question;
    });

    this.questions = _.cloneDeep(this.defaultQuestions);
  }

  onQuestionTypeChange(question: any) {
    if (question.questionType === 'Multiple choice' || question.questionType === 'Single choice') {
      question.options = [{ optionId: 1, optionText: '' }];
      question.otherOption = false; // Set to false by default
      delete question.startValue;
      delete question.endValue;
      question.questionText = '';
    } else if (question.questionType === 'Linear scale') {
      question.startValue = { value: null, text: '' };
      question.endValue = { value: null, text: '' };
      question.questionText = '';
      question.options = question.options.map((option: any) => {
        option.optionText = '';
        return option;
      });
      delete question.options;
      question.otherOption = false; // Set to false by default
    } else {
      delete question.options;
      delete question.startValue;
      delete question.endValue;
      question.otherOption = false; // Set to false by default
      question.questionText = '';
    }
  }

  onStartValueChange(question: any) {
    if (question.startValue.value === question.endValue.value || (question.startValue.value === 1 && question.endValue.value === 1) || (question.startValue.value === 5 && question.endValue.value === 5)) {
      question.endValue.value = null;
    }
  }

  onEndValueChange(question: any) {
    if (question.endValue.value === question.startValue.value || (question.endValue.value === 1 && question.startValue.value === 1) || (question.endValue.value === 5 && question.startValue.value === 5)) {
      question.startValue.value = null;
    }
  }

  addOption(question: any) {
    if (question.options.length < 5) {
      question.options.push({ optionId: question.options.length + 1, optionText: '' });
    }
  }

  deleteOption(question: any, index: number) {
    question.options.splice(index, 1);
  }

  addQuestion() {
    if (this.questions.length < 5) {
      this.questions.push({
        questionOrder: this.questions.length + 1,
        questionText: '',
        questionType: 'Long text',
        isRequired: false,
        otherOption: false // Ensure this is initialized as false
      });
    }
    this.emptyFlagUpdate();
  }

  deleteQuestion(index: number) {
    if (this.questions.length > 0) {
      this.questions.splice(index, 1);
      this.questions.forEach((question, i) => question.questionOrder = i + 1); // Reassign questionId based on the new order
    }
    if(this.questions.length === 0) {
      this.askOurDocsV2Service.getDefaultFeedbackForm().pipe(take(1)).subscribe((data: any) => {
        this.transformFormData(data);
      });
    }
  }

  onOtherOptionChange(question: any) {
    question.otherOption = !question.otherOption;
  }

  cleanQuestions(questions: any[]) {
    return questions.map(question => {
      if (!question) return null; // Handle undefined or null question

      const cleanedQuestion = { ...question };
      if (cleanedQuestion.startValue) {
        delete cleanedQuestion.startValue.textTouched;
      }
      if (cleanedQuestion.endValue) {
        delete cleanedQuestion.endValue.textTouched;
      }
      delete cleanedQuestion.touched;
      delete cleanedQuestion.questionTextTouched;

      if (question.questionType === 'Multiple choice' || question.questionType === 'Single choice') {
        delete cleanedQuestion.startValue;
        delete cleanedQuestion.endValue;
      } else if (question.questionType === 'Linear scale') {
        delete cleanedQuestion.options;
        delete cleanedQuestion.otherOption;
      } else {
        delete cleanedQuestion.options;
        delete cleanedQuestion.startValue;
        delete cleanedQuestion.endValue;
        delete cleanedQuestion.otherOption;
      }

      if (cleanedQuestion.options) {
        cleanedQuestion.options = cleanedQuestion.options.map((option: any) => {
          const cleanedOption = { ...option };
          delete cleanedOption.touched;
          return cleanedOption;
        });
      }
      return cleanedQuestion;
    }).filter(question => question !== null); // Filter out null questions
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.questions.forEach((question, i) => question.questionOrder = i + 1);
  }

  async resetCustomizableForm() {
    await this.askOurDocsV2Service.getDefaultFeedbackForm().subscribe((data: any) => {
      this.transformFormData(data);
    });
  }

  getFormState() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return JSON.stringify(this.questions.map(({ touched, questionTextTouched, ...rest }) => {
      if (rest.startValue?.textTouched) {
        delete rest.startValue.textTouched;
      }
      if (rest.endValue?.textTouched) {
        delete rest.endValue.textTouched;
      }
      return { rest };
    }));
  }

  hasUnsavedChanges(): boolean {
    if (this.emptyFieldFlag) {
      return false;
    }
    return this.initialFormValue !== this.getFormState();
  }

  closeModalNavigate(modal: string) {
    this.userNavFlag = true;
    this.kmdModalService.close(modal);
    this.router.navigate(['../'], { relativeTo: this.route, queryParams: { tab: 3 } });
  }

  closeModalNavigateAlert(modal: string) {
    this.kmdModalService.close(modal);
  }

  closeModal(modal: string) {
    this.kmdModalService.close(modal);
  }

  saveAndPublish() {
    if (this.checkForEmptyInputs()) {
      this.alertService.showError('Some fields are empty!')
      return;
    } else {
      if (this.hasUnsavedChanges()) {
        this.kmdModalService.open('confirmation-modal-save-changes');
        return;
      }
    }
  }

  emptyFlagUpdate() {
    this.emptyFieldFlag = this.checkForEmptyInputs();
  }

  checkForEmptyInputs(): boolean {
    // Check if all inputs are populated
    for (const question of this.questions) {
      if (!question.questionText || question.questionText.trim() === '') {
        return true;
      }
      if (question.options) {
        for (const option of question.options) {
          if (!option.optionText || option.optionText.trim() === '') {
            return true;
          }
        }
      }
      if (question.startValue && (!question.startValue.text || question.startValue.text.trim() === '')) {
        return true;
      }
      if (question.endValue && (!question.endValue.text || question.endValue.text.trim() === '')) {
        return true;
      }
    }
    return false;
  }

  ConfirmAndNavigate() {
    this.userNavFlag = true;
    // Calling API and Navigate to required
    this.closeModal('confirmation-modal-save-changes');
    const cleanedQuestions = this.cleanQuestions(this.questions);
    const customizableFeedbackFormPayload = { "indexName": this.indexName, "questions": cleanedQuestions };

    this.askOurDocsV2Service.saveCustomizableFeedbackForm(customizableFeedbackFormPayload).then(() => {
      this.alertService.showSuccess('Feedback form saved successfully!');
      setTimeout(() => {
        this.router.navigate(['../'], { relativeTo: this.route, queryParams: { tab: 3 } });
      }, 1500);
    }).catch(error => {
      console.log('Error', error);
    });
  }

  ConfirmAndNavigateAlert() {
    this.closeModalNavigateAlert('confirmation-modal-unsaved-changes-nav');

    const cleanedQuestions = this.cleanQuestions(this.questions);
    const customizableFeedbackFormPayload = { "indexName": this.indexName, "questions": cleanedQuestions };

    this.askOurDocsV2Service.saveCustomizableFeedbackForm(customizableFeedbackFormPayload).then(response => {
      if (response && response.success) {
        this.alertService.showSuccess('Feedback form saved successfully!');
      }
    }).catch(error => {
      console.log('Error', error);
    }).finally(() => {
      this.userNavFlag = false;
    });
  }

  alertRedirection(): Promise<boolean> {
    return new Promise((resolve) => {
      this.kmdModalService.open('confirmation-modal-unsaved-changes-nav');
      this.kmdModalService.closeModal$.subscribe((modalName: string) => {
        if (modalName === 'confirmation-modal-unsaved-changes-nav') {
          resolve(true);
        }
      });
    });
  }

  cancel() {
    if (this.hasUnsavedChanges()) {
      this.kmdModalService.open('confirmation-modal-unsaved-changes');
    } else {
      this.userNavFlag = true;
      this.router.navigate(['../'], { relativeTo: this.route, queryParams: { tab: 3 } });
    }
  }
}