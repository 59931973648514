import { Component, HostListener, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgentsService } from '@services/agents/agents.service';
import { Agent } from '@shared/models/agent';
import { Router } from "@angular/router";
import { AgentFormComponent } from "@app/agents/agent-form/agent-form.component";
import {
  AgentSharingModalComponent
} from '@app/agents/agent-sharing/agent-sharing-modal/agent-sharing-modal.component';
import { FileUpload } from '@shared/models/file-upload.model';

@Component({
  selector: 'app-agent-creation',
  templateUrl: './agent-creation.component.html',
  styleUrls: ['./agent-creation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentCreationComponent {

  @ViewChild('dropDownComponent') dropDownComponent: any;
  @ViewChild('agentForm') agentForm!: AgentFormComponent;
  @ViewChild('sharingAgentModal') sharingAgentModal: AgentSharingModalComponent | undefined;
  @Input() agent?: Agent;

  sharingDetails?: {
    agent: Agent,
    selectedFiles: FileUpload[]
  };

  showErrorAlert = false;
  unexpectedErrorText = "We encountered an unexpected error. Please try again.";
  invalidAgentText = "You already have an agent with this name. Please use a unique name for your agent.";
  instructionsTemplate = "PERSONA:\n" +
    "MISSION:\n" +
    "PERSONALITY:\n" +
    "RULES AND BEHAVIOR:\n" +
    "STEP-BY-STEP PROCESS:\n" +
    "IMPORTANT: Ensure all steps are completed before concluding."
  errorAlertText = this.unexpectedErrorText;
  showExampleButton = true
  showExampleAgent = false;
  showAgentCreationForm = true;
  windowWidth = window.innerWidth;
  showAgentCreateForm = true;
  validForm: boolean = false;
  isAodEnabled: boolean = false;

  constructor(
    private agentsService: AgentsService,
    private router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.showAgentCreationForm = !(this.windowWidth <= 992 && this.showExampleAgent);
  }

  isAgentSaved() {
    return this.agent !== undefined && this.agent.id != undefined;
  }

  saveAgent(agent: Agent) {
    this.agentsService.save(agent).subscribe({
      next: (agent) => {
        this.agent = agent;
        this.navigateToAgentChat();
      },
      error: (error) => {
        let conflictError = 409;
        this.errorAlertText = error.status === conflictError ? this.invalidAgentText : this.unexpectedErrorText;
        this.showErrorAlert = true;
      }
    });
  }

  formStatusChanged(valid: boolean) {
    this.validForm = valid
  }

  submit() {
    this.agentForm.submit();
  }

  navigateToAgentChat() {
    this.router.navigate([`/agents/${this.agent!.id}/chat`]);
  }

  navigateToSharedWithMeAgents() {
    this.router.navigate(['/agents'], { queryParams: { tab: 'shared-with-me' }, replaceUrl: true });
  };

  navigateToMyAgents() {
    this.router.navigate(['/agents']);
  };

  toggleAgentExample() {
    if (this.showAgentCreationForm) {
      this.agent = this.agentForm.storeFormValues();
    }
    this.showExampleButton = !this.showExampleButton;
    this.showExampleAgent = !this.showExampleAgent;
    this.showAgentCreationForm = !(this.windowWidth <= 992 && this.showExampleAgent);
  }

  openAgentSharingModal() {
    this.sharingDetails = {
      agent: this.agentForm.currentAgent,
      selectedFiles: this.agentForm.selectedFiles
    };

    this.sharingAgentModal?.openModal();
  }

  updateAodStatus(aodEnabled: boolean) {
    this.isAodEnabled = aodEnabled;
  }

  updateAmdStatus(amdEnabled: boolean) {
    this.sharingAgentModal!.toggleMakePublicOptionDisabled(amdEnabled)}
}
