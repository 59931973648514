import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Member } from "@app/shared/models/group-membership/members/member.model";

export const USER_NOT_IN_GROUP_VALIDATOR_KEY = 'userNotInGroup';

function setControlError(control: AbstractControl, errorKey: string, errorValue: any): void {
  const existingErrors = control.errors || {};
  existingErrors[errorKey] = errorValue;
  control.setErrors(existingErrors);
}

export function memberInGroupValidator(group: Member[]): ValidatorFn {
  return (formArray: AbstractControl): ValidationErrors | null => {
    if (!group || !formArray || !(formArray instanceof FormArray)) {
      return null;
    }

    const selectedMembers = formArray.value as Member[];
    const groupEmails = group.map(member => member.mail.trim().toLowerCase());
    const userNotInGroup = selectedMembers.some(member => !groupEmails.includes(member.mail.trim().toLowerCase()));

    formArray.controls.forEach((control) => {
      const memberEmail = control.value.mail.trim().toLowerCase();
      const isNotInGroup = !groupEmails.includes(memberEmail);
      if (isNotInGroup) {
        setControlError(control, USER_NOT_IN_GROUP_VALIDATOR_KEY, true);
      }
    });

    return userNotInGroup ? { userNotInGroup: true } : null;
  };
}
