import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { modelList } from '../prompt-settings/prompt-settings.data';
import { ContextMenuItem } from "@shared/models/context-menu-item";
import { ContextMenuDropdownComponent } from "@shared/components/context-menu-dropdown/context-menu-dropdown.component";
import { ModelFactory } from "@shared/models/gpt-models/model-factory";
import { PromptSubmitEvent } from '@shared/models/prompt-submit-event';
import { Model, PromptSettings } from '@shared/models/prompt-settings.model';
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";

@Component({
  selector: 'prompt-input-new',
  templateUrl: './prompt-input-new.component.html',
  styleUrls: ['./prompt-input-new.component.css']
})
export class PromptInputNewComponent implements OnInit {
  @ViewChild('modelContextMenu') modelDropdown!: ContextMenuDropdownComponent;
  @ViewChild("inputDiv") inputDiv?: ElementRef;
  @ViewChild('inputComponentWrapper', { static: true }) inputWrapper!: ElementRef;
  @ViewChild('inputComponentContainer', { static: true }) inputContainer!: ElementRef;

  @Input() settings!: PromptSettings;
  @Input() isStreamingResponse!: boolean;
  @Input() addFileButtonVisibility : boolean = true;
  @Input() modelChangeButtonVisibility : boolean = true;
  @Input() promptSettingsButtonVisibility : boolean = true;
  @Output() onSubmit = new EventEmitter<PromptSubmitEvent>();
  @Output() promptSettingsButtonClick = new EventEmitter();
  @Output() stopStreaming = new EventEmitter<any>();

  modelList: ContextMenuItem[] = [];
  showModelDropdown = false;
  prompt: string = '';
  isInputPopulated: boolean = false;
  textTrimmed: boolean = false;
  responseGeneratedFlag: boolean = false; // for keeping the send buton disabled until the response generation is completed

  imageOptions: Map<string, { url: string; cssClass: string; onClick: () => void }> = new Map([
    ['inactive', {
      url: '../../../../assets/icons/send/Send-Inactive.svg', cssClass: 'inactive', onClick: () => {
      }
    }],
    ['active', {
      url: '../../../../assets/icons/send/Send-Active.svg',
      cssClass: '',
      onClick: () => this.onSubmitClick()
    }],
    ['stop', {
      url: '../../../../assets/icons/stop/stop-white.svg', cssClass: 'stop', onClick: () => this.onStopStreaming()
    }]
  ]);

  currentSubmitStatus = this.imageOptions.get('inactive');

  constructor(
    private modelFactory: ModelFactory,
    private fileManagerService: FileManagerService
  ) { }

  ngOnInit() {
    this.modelList = modelList.map(model => ({
      id: model.model,
      label: this.getModelLabel(model.model),
      description: model.description
    }));

    this.checkInputAndToggleSubmitButton();
  }

  @HostListener('input') onInputChange() {
    this.trimPromptInput();
    this.changeInputBorderColor();
  }

  private trimPromptInput() {
    if (this.isPromptLargerThanModelLimit()) {
      this.trimTextToModelLimit();
      this.textTrimmed = true;
    } else {
      this.textTrimmed = false;
    }
  }

  private isPromptLargerThanModelLimit() {
    return this.prompt.length > this.maxInputCharLimit();
  }

  private trimTextToModelLimit() {
    this.prompt = this.prompt.trim().substring(0, this.maxInputCharLimit());
  }

  get selectedModel() {
    const model = modelList.find(item => this.settings?.model === item.model)?.model;
    return this.getModelLabel(model || 'gpt-4-o') as any;
  }

  setPromptInInput(prompt: string) {
    this.prompt = prompt;
    this.inputContainer.nativeElement.focus();

    this.onInputChange();
    this.checkInputAndToggleSubmitButton();
    this.changeInputBorderColor();
  }

  setSelectedModel(item: ContextMenuItem) {
    this.settings.model = item.id as any;
  }

  toggleModelDropdown() {
    this.showModelDropdown = !this.showModelDropdown;
    this.modelDropdown.toggleMenu();
  }

  isContextMenuOpen(isOpen: boolean) {
    this.showModelDropdown = isOpen;
  }

  closeContextMenu() {
    this.modelDropdown.closeMenu();
  }

  checkInputAndToggleSubmitButton(): void {
    if (this.prompt.trim() === '' || this.isProcessingFiles() || this.isStreamingResponse || this.responseGeneratedFlag) {
      this.disableSendButton();
    } else {
      this.enableSendButton();
    }
  }

  maxInputCharLimit() {
    const model = this.modelFactory.create(this.settings.model);
    return model.MAX_NUMBER_OF_CHARACTERS;
  }

  showCounter() {
    const model = this.modelFactory.create(this.settings.model);
    return (model.MAX_NUMBER_OF_CHARACTERS - this.prompt.length <= 1000);
  }

  onPromptSettingsButtonClick() {
    this.promptSettingsButtonClick.emit();
  }

  onInputKeydownEnter(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.currentSubmitStatus?.onClick();
    }
  };

  onSubmitClick(): void {
    this.removeFilesWithError();
    this.onSubmit.emit({
      prompt: this.prompt,
      settings: this.settings,
      files: this.getFiles()
    });
  }

  private getFiles() {
    return this.fileManagerService.selectedFiles$.getValue()
      .map(file => file.id)
      .filter((id): id is string => id !== undefined);
  }

  private changeInputBorderColor() {
    this.isInputPopulated = this.prompt.length > 0;
  }

  private getModelLabel(model: Model) {
    switch (model) {
      case 'gpt-4':
        return 'GPT-4';
      default:
        return 'GPT-4o';
    }
  }

  private enableSendButton() {
    this.currentSubmitStatus = this.imageOptions.get('active');
  }

  private disableSendButton() {
    if (this.isStreamingResponse) {
      this.enableStopResponseButton();
    } else {
      this.currentSubmitStatus = this.imageOptions.get('inactive');
    }
  }

  private enableStopResponseButton() {
    this.currentSubmitStatus = this.imageOptions.get('stop');
  }

  get selectedFiles() {
    return this.fileManagerService.selectedFiles$.value;
  }

  private isProcessingFiles(): boolean {
    const { processed, error } = this.fileManagerService.fileStatusIds;
    if (this.selectedFiles.length === 0) {
      return false;
    }

    return this.selectedFiles.some(file => !processed.includes(file.id!) && !error.includes(file.id!));
  }

  removeFilesWithError() {
    const { error } = this.fileManagerService.fileStatusIds;
    this.fileManagerService.selectedFiles$.next(
      this.selectedFiles.filter(file => !error.includes(file.id!))
    );
    this.fileManagerService.selectedFiles$.next(this.selectedFiles);
  }

  get isLimitExceeded() {
    if (this.prompt.length > this.maxInputCharLimit()) {
      this.disableSendButton();
      return true;
    }
    this.checkInputAndToggleSubmitButton();
    return false;
  }

  get isTextTrimmed() {
    return this.textTrimmed && this.prompt.length == this.maxInputCharLimit();
  }

  private onStopStreaming() {
    this.stopStreaming.emit();
  }
}
