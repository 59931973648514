<div class="personal-docs">
  <ng-template #loading>
    <div style="margin-top: 10px">
      <kmd-alerts id="loadError" kmd-error [show]="showError" [alertText]="errorMessage"></kmd-alerts>
    </div>
  </ng-template>
  <div class="uploader-container">
    <span class="title">My Docs</span>
    <core-notification class='notification-amd'
                       label='{{newNotificationMessageLabel}}'
                       message='{{newNotificationMessage}}' variant='inline'
                       state='information' timer='0' nocloseicon>
      <core-button (click)="goToFAQS()" label="Gen AI FAQs" [type]="'button'"
                   variant="outline" size="regular" slot="button"></core-button>
    </core-notification>
    <span class="upload-message">Upload and process your documents so you can chat with them!</span>

  </div>
  <div class="uploaded-files-list">
    <table>
      <thead>
      <tr>
        <th>File Name</th>
        <th>Status</th>
        <th>Date Processed</th>
        <th>Delete File</th>
      </tr>
      </thead>
      <ng-container *ngIf="processedFiles | async as processedFiles; else loading">
        <tbody *ngIf="processedFiles.length > 0">
        <tr *ngFor="let processedFile of processedFiles">
          <td>{{ processedFile.name }}</td>
          <td>{{ getFileStatus(processedFile.status!) }}</td>
          <td>{{ getDateForFile(processedFile) }}</td>
          <td>
            <button kmdLinkButton (click)="confirmDelete(processedFile)" class="delete-file-button"
                    [disabled]="!isDeleteEnabled(processedFile)">
              Delete
            </button>
          </td>
        </tr>
        </tbody>
      </ng-container>
      <td class="center" *ngIf="(processedFiles | async)?.length === 0" colspan="4">
        It seems like you don't have any documents available to query. Upload your documents to interact with them.
      </td>
    </table>
  </div>
  <kmd-modal [id]="'delete-file-upload'" modalSize="mini" headline="Warning!">
    <div>
      <b *ngIf="isFileLinkedToAgents; then fileLinkedToAgents else regularFile"></b>
    </div>
    <div class="modal-buttons-container">
      <button id="btnCancelDeleteFileUpload" (click)="cancelDeleteFileUpload()" kmdSecondaryButton>Cancel</button>
      <button id="btnDeleteFileUpload" (click)="deleteFileUpload()" kmdFeaturedButton>Delete</button>
    </div>
  </kmd-modal>
  <kmd-alerts [(show)]="showDeleteError" kmd-error [alertText]="deleteErrorMessage"></kmd-alerts>
  <kmd-alerts [(show)]="showDeleteSuccess" kmd-success [alertText]="deleteSuccessMessage"></kmd-alerts>

</div>

<ng-template #fileLinkedToAgents>
  <b id="fileLinkedToAgents">The file will be permanently deleted and removed from any linked Agents, do you want to
    proceed?</b>
</ng-template>
<ng-template #regularFile>
  <b id="regularFile">You're about to delete this file, this action is permanent and you won't be able to recover it. Do
    you want to
    delete it?</b>
</ng-template>
