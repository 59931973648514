import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-admin-landing-feedback-form',
  templateUrl: './admin-landing-feedback-form.component.html',
  styleUrls: ['./admin-landing-feedback-form.component.css']
})
export class AdminLandingFeedbackFormComponent {
  @Input() indexName: string = '';
  @Output() redirectionToCustomizableFeedback = new EventEmitter<boolean>;

  constructor(private router: Router, private route: ActivatedRoute) {}

  redirectToFeedback() {
    this.redirectionToCustomizableFeedback.emit(true);
  }
}
