import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Agent, AgentCategoryFilter, AgentCategoryFilters, SortOption, SortOptions } from "@app/shared/models/agent";
import { debounceTime, distinctUntilChanged, isEmpty, Subject } from "rxjs";

@Component({
  selector: 'app-agent-list-toolbar',
  templateUrl: './agent-list-toolbar.component.html',
  styleUrls: ['./agent-list-toolbar.component.css']
})
export class AgentListToolbarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() agents: Agent[] = [];
  @Input() type!: string;
  @Output() onFilteredAgentsEvent = new EventEmitter<Agent[]>();
  @ViewChild('agentSearchInput') agentSearchInput: ElementRef | undefined;
  filteredAgents: Agent[] = [];
  selectedFilter: AgentCategoryFilter = "All agents";
  agentCountLabel: string = "";
  protected readonly AgentCategoryFilters = [...AgentCategoryFilters];
  selectedSortOption: SortOption = 'A-Z';
  SortOptions = [...SortOptions];

  searchText$ = new Subject<string>();
  searchTerm: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.filteredAgents = this.agents;
    this.sortFilteredAgents();
    this.onFilteredAgentsEvent.emit(this.filteredAgents);
    this.setAgentCountLabel();

    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    );

  }

  ngOnDestroy(): void {
    this.searchText$.complete();
  }

  ngOnChanges() {
    this.sortFilteredAgents();
    this.onFilteredAgentsEvent.emit(this.filteredAgents);
  }

  getSearchValue(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement)?.value;
    this.searchTerm = searchTerm.trim().toLowerCase();
    this.searchText$.next(searchTerm);
    this.filterAgentsByName(searchTerm);
  }

  onCategoryFilterSelect(category: AgentCategoryFilter) {
    this.selectedFilter = category;

    this.searchText$.pipe(
      isEmpty()
    ).subscribe(isEmpty => {
      if (!isEmpty) {
        this.selectedFilter = "All agents";
      }
    });
    this.setFilteredAgents();
  }

  setFilteredAgents(): void {
    if (this.selectedFilter === "All agents") {
      if (this.searchTerm === '') {
        this.filteredAgents = this.agents.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.filteredAgents = this.agents
          .filter(agent => agent.name.toLowerCase().includes(this.searchTerm) || agent.description.toLowerCase().includes(this.searchTerm))
          .sort((a, b) => a.name.localeCompare(b.name));
      }
    } else {
      this.filteredAgents = this.agents
        .filter(agent => agent.category === this.selectedFilter && (agent.name.toLowerCase().includes(this.searchTerm) || agent.description.toLowerCase().includes(this.searchTerm)))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    this.onSortDropdownChange(this.selectedSortOption);
    this.setAgentCountLabel();
    this.onFilteredAgentsEvent.emit(this.filteredAgents);
  }

  setAgentCountLabel(): void {
    this.agentCountLabel = `${ this.filteredAgents.length } ${ this.filteredAgents.length === 1 ? 'agent' : 'agents' }`;
  }

  onCategoryDropdownChange(category: string): void {
    const newCategory = AgentCategoryFilters.find(filter => filter === category);

    if (newCategory) {
      this.onCategoryFilterSelect(newCategory);
    }
  }

  onSortDropdownChange(sortBy: SortOption): void {
    if (sortBy === 'A-Z') {
      this.sortAlphabetically();
    } else if (sortBy === 'Newest to oldest') {
      this.filteredAgents.sort((a, b) => (new Date(b.created!).getTime() || 0) - (new Date(a.created!).getTime() || 0));
    } else if (sortBy === 'Oldest to newest') {
      this.filteredAgents.sort((a, b) => (new Date(a.created!).getTime() || 0) - (new Date(b.created!).getTime() || 0));
    } else if (sortBy === 'Most popular') {
      this.sortByMostPopular();
    }
    this.onFilteredAgentsEvent.emit(this.filteredAgents);
  }

  sortByMostPopular() {
    this.filteredAgents.sort((a, b) => {
      return (b.rating?.likes || 0) - (a.rating?.likes || 0);
    });
  }

  sortAlphabetically(): void {
    this.filteredAgents.sort((a, b) => a.name.localeCompare(b.name));
  }

  filterAgentsByName(searchTerm: string): void {
    this.filteredAgents = this.agents.filter(agent => {
      const nameMatch = agent.name.toLowerCase().includes(searchTerm.toLowerCase());
      const descriptionMatch = agent.description.toLowerCase().includes(searchTerm.toLowerCase());
      return nameMatch || descriptionMatch;
    });
    this.setAgentCountLabel();
    this.onFilteredAgentsEvent.emit(this.filteredAgents);
  }

  private sortFilteredAgents() {
    if (this.type === 'publicAgent') {
      this.addSortByMostPopularToPublicAgents();
    } else {
      this.sortAlphabetically();
    }
  }

  private addSortByMostPopularToPublicAgents() {
    if (this.SortOptions.indexOf('Most popular') === -1) {
      this.SortOptions.push('Most popular');
    }
    this.selectedSortOption = 'Most popular';
    this.sortByMostPopular();
  }
}
