<div class="repository-details-container">
  <h2>About {{ repositoryName }}</h2>
  <div class="repository-details-box">
    <p>
      <span class="custom-bold">Repository owner</span><br />{{ repoOwner }}
    </p>
    <p class="repository-details-box-p1">
      <span class="custom-bold">Last updated</span><br />{{ last_run_date }}
    </p>

    <p>
      <span class="custom-bold">Repository details</span><br />{{
        repositorydetails
      }}
    </p>
    <p *ngIf="linkrequired && repositorylink === 'No repository link available'" >
      <span class="custom-bold">Repository Link</span><br />{{ repositorylink }}
    </p>
    <p *ngIf="linkrequired && repositorylink !== 'No repository link available'">
      <span class="custom-bold">Repository Link</span><br />
      <a [href]="repositorylink" target="_blank">{{ repositorylink }}</a>
    </p>


  </div>
</div>
