<div class="feedback-container">
  <div class="aod-user-manage-header">
    <a class="back-to-aod" routerLink="/ask-our-docs-v2">
      <img src="../../../assets/icons/arrows/arrowLeft.svg"><span>Ask Our Docs</span>
    </a>
    <h1 id="repoName">{{ repoName }}</h1>
  </div>
  <div class="main-content">
    <div class="header-container">
      <div class="heading-container">
        <span class="heading-container-one">
          Customize feedback form
        </span>
        <span class="normal-text heading-container-two">(Maximum 5 questions)</span>
      </div>
      <div class="reset-container" (click)="resetCustomizableForm()">
        <svg id="restart-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.0662 21.9806C9.20321 21.9806 6.35721 20.7766 4.37121 18.4386C2.64021 16.4016 1.81121 13.8156 2.03621 11.1606C2.26321 8.49956 3.52021 6.08656 5.57621 4.36556C9.81521 0.817562 16.1702 1.35556 19.7452 5.56156L20.0552 5.92756L17.7462 7.86056L17.4412 7.50156C17.1002 7.10356 16.7182 6.74556 16.3052 6.43556C13.2092 4.11856 8.78921 4.73056 6.45321 7.80156C5.32421 9.28756 4.84521 11.1196 5.10321 12.9616C5.36021 14.8096 6.33021 16.4466 7.83121 17.5696C10.7932 19.7896 14.9632 19.3236 17.3652 16.5896L15.1922 15.0986L21.7842 12.9596L22.0002 19.8036L19.8532 18.3096C19.4582 18.7916 19.0182 19.2346 18.5402 19.6346C16.6572 21.2106 14.3552 21.9806 12.0662 21.9806Z"
            fill="#1B1B1D" />
        </svg>
        <span class="reset-text">Reset default</span>
      </div>
    </div>
    <div class="alert-container-important">
      <kmd-alerts kmd-warning [icon]="true" [show]="true" [alertTitle]="'Important:'"
        [alertText]="'Additional questions may reduce user engagement'" [dismissible]="false"
        [type]="'important'"></kmd-alerts>
    </div>
    <div cdkDropList class="questions-container example-list" (cdkDropListDropped)="drop($event)">
      <div class="loader-container" *ngIf="questions.length === 0">
        <kmd-loading-wheel></kmd-loading-wheel>
      </div>
      <div *ngFor="let question of questions; let i = index" class="feedback-box example-box" cdkDrag>
        <div class="header">
          <div class="heading-with-icon">
            <svg id="drag-n-drop-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5 19C9.5 17.896 8.604 17 7.5 17C6.396 17 5.5 17.896 5.5 19C5.5 20.104 6.396 21 7.5 21C8.604 21 9.5 20.104 9.5 19Z"
                fill="#1B1B1D" />
              <path
                d="M18.5 19C18.5 17.896 17.604 17 16.5 17C15.396 17 14.5 17.896 14.5 19C14.5 20.104 15.396 21 16.5 21C17.604 21 18.5 20.104 18.5 19Z"
                fill="#1B1B1D" />
              <path
                d="M16.5 10C17.604 10 18.5 10.896 18.5 12C18.5 13.104 17.604 14 16.5 14C15.396 14 14.5 13.104 14.5 12C14.5 10.896 15.396 10 16.5 10Z"
                fill="#1B1B1D" />
              <path
                d="M9.5 12C9.5 10.896 8.604 10 7.5 10C6.396 10 5.5 10.896 5.5 12C5.5 13.104 6.396 14 7.5 14C8.604 14 9.5 13.104 9.5 12Z"
                fill="#1B1B1D" />
              <path
                d="M16.5 3C17.604 3 18.5 3.896 18.5 5C18.5 6.104 17.604 7 16.5 7C15.396 7 14.5 6.104 14.5 5C14.5 3.896 15.396 3 16.5 3Z"
                fill="#1B1B1D" />
              <path
                d="M9.5 5C9.5 3.896 8.604 3 7.5 3C6.396 3 5.5 3.896 5.5 5C5.5 6.104 6.396 7 7.5 7C8.604 7 9.5 6.104 9.5 5Z"
                fill="#1B1B1D" />
            </svg>
            <h3>{{ question.questionType }} question</h3>
          </div>
          <div class="options">
            <label for="required">Required</label>
            <kmd-toggle id="required" [(ngModel)]="question.isRequired"></kmd-toggle>
            <svg id="bin-mono.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" (click)="deleteQuestion(i)" class="delete-icon">
              <path d="M12.75 9H11.25V18H12.75V9Z" fill="#1B1B1D" />
              <path d="M15.75 9H14.25V18H15.75V9Z" fill="#1B1B1D" />
              <path d="M9.75 9H8.25V18H9.75V9Z" fill="#1B1B1D" />
              <path
                d="M21 5H15.5V3C15.5 2.45 15.05 2 14.5 2H9.5C8.95 2 8.5 2.45 8.5 3V5H3V7H4.18005L5.5 22H18.5L19.8201 7H21V5ZM10 3.5H14V5H10V3.5ZM16.67 20H7.32996L6.18005 7H17.8101L16.66 20H16.67Z"
                fill="#1B1B1D" />
            </svg>
          </div>
        </div>
        <div class="form-group">
          <label for="questionText">Question</label>
          <div class="input-group">
            <input type="text" id="questionText" [(ngModel)]="question.questionText" class="form-control question-input"
              placeholder="Enter your question" required (blur)="question.questionTextTouched = true"
              (keyup)="emptyFlagUpdate()">
            <kmd-dropdown id="questionType" [(ngModel)]="question.questionType" [options]="questionTypes"
              (ngModelChange)="onQuestionTypeChange(question)" class="form-control question-type" required
              (blur)="question.questionTypeTouched = true"></kmd-dropdown>
          </div>
        </div>
        <div *ngIf="question.options" class="form-group">
          <label>Options (maximum 6 options)</label>
          <div *ngFor="let option of question.options; let j = index" class="input-group">
            <svg id="drag-n-drop-bold.svg" width="24" height="20" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5 19C9.5 17.896 8.604 17 7.5 17C6.396 17 5.5 17.896 5.5 19C5.5 20.104 6.396 21 7.5 21C8.604 21 9.5 20.104 9.5 19Z"
                fill="#1B1B1D" />
              <path
                d="M18.5 19C18.5 17.896 17.604 17 16.5 17C15.396 17 14.5 17.896 14.5 19C14.5 20.104 15.396 21 16.5 21C17.604 21 18.5 20.104 18.5 19Z"
                fill="#1B1B1D" />
              <path
                d="M16.5 10C17.604 10 18.5 10.896 18.5 12C18.5 13.104 17.604 14 16.5 14C15.396 14 14.5 13.104 14.5 12C14.5 10.896 15.396 10 16.5 10Z"
                fill="#1B1B1D" />
              <path
                d="M9.5 12C9.5 10.896 8.604 10 7.5 10C6.396 10 5.5 10.896 5.5 12C5.5 13.104 6.396 14 7.5 14C8.604 14 9.5 13.104 9.5 12Z"
                fill="#1B1B1D" />
              <path
                d="M16.5 3C17.604 3 18.5 3.896 18.5 5C18.5 6.104 17.604 7 16.5 7C15.396 7 14.5 6.104 14.5 5C14.5 3.896 15.396 3 16.5 3Z"
                fill="#1B1B1D" />
              <path
                d="M9.5 5C9.5 3.896 8.604 3 7.5 3C6.396 3 5.5 3.896 5.5 5C5.5 6.104 6.396 7 7.5 7C8.604 7 9.5 6.104 9.5 5Z"
                fill="#1B1B1D" />
            </svg>
            <input type="text" [(ngModel)]="option.optionText" class="form-control" placeholder="Option {{j + 1}}"
              required (blur)="option.touched = true" (keyup)="emptyFlagUpdate()">
            <svg id="bin-mono.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" (click)="deleteOption(question, j)" class="delete-icon">
              <path d="M12.75 9H11.25V18H12.75V9Z" fill="#1B1B1D" />
              <path d="M15.75 9H14.25V18H15.75V9Z" fill="#1B1B1D" />
              <path d="M9.75 9H8.25V18H9.75V9Z" fill="#1B1B1D" />
              <path
                d="M21 5H15.5V3C15.5 2.45 15.05 2 14.5 2H9.5C8.95 2 8.5 2.45 8.5 3V5H3V7H4.18005L5.5 22H18.5L19.8201 7H21V5ZM10 3.5H14V5H10V3.5ZM16.67 20H7.32996L6.18005 7H17.8101L16.66 20H16.67Z"
                fill="#1B1B1D" />
            </svg>
          </div>
          <div *ngIf="question.questionType === 'Single choice' || question.questionType === 'Multiple choice'">
            <input type="checkbox" class="form-check-input" id="otherOption{{i}}" [(ngModel)]="question.otherOption"
              (keyup)="emptyFlagUpdate()">
            <label class="form-check-label" for="otherOption{{i}}">Show ‘Other’ option.</label>
          </div>
          <div class="add-option-container">
            <a (click)="addOption(question)" [class.disabled]="question.options.length >= 5"
              [attr.aria-disabled]="question.options.length >= 5" class="add-option-link">+ Add Option</a>
          </div>
        </div>
        <div *ngIf="question.startValue" class="form-group">
          <div class="linear-scale-group">
            <div class="form-group">
              <label for="startValue.value" class="value-label">Start Value</label>
              <div class="input-group dropdown-linear">
                <kmd-dropdown id="startValue.value" [(ngModel)]="question.startValue.value" [options]="['1', '5']"
                  (ngModelChange)="onStartValueChange(question)" class="form-control small-dropdown" required
                  (blur)="question.startValue.valueTouched = true"></kmd-dropdown>
  
                <input type="text" [(ngModel)]="question.startValue.text" class="form-control large-input label"
                  placeholder="Start Label" required (blur)="question.startValue.textTouched = true"
                  (keyup)="emptyFlagUpdate()">
                <small class="small">e.g. Poor</small>
              </div>
            </div>
            <div class="form-group">
              <label for="endValue.value" class="value-label">End Value</label>
              <div class="input-group dropdown-linear">
                <kmd-dropdown id="endValue.value" [(ngModel)]="question.endValue.value" [options]="['1','5']"
                  (ngModelChange)="onEndValueChange(question)" class="form-control small-dropdown" required
                  (blur)="question.endValue.valueTouched = true"></kmd-dropdown>
                <input type="text" [(ngModel)]="question.endValue.text" class="form-control large-input label"
                  placeholder="End Label" required (blur)="question.endValue.textTouched = true"
                  (keyup)="emptyFlagUpdate()">
                <small class="small">e.g. Excellent</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add-question-container" *ngIf="questions.length > 0">
        <button (click)="addQuestion()" [disabled]="questions.length >= 5" class="add-question-button">Add Another
          Question</button>
      </div>
      <div class="space-occupier"></div>
    </div>
  </div>
</div>

<div class="feedback-form-footer">
  <button class="cancel-button" (click)="cancel()">Cancel</button>
  <button [ngClass]="hasUnsavedChanges()? 'save-and-publish-button-enabled' : 'save-and-publish-button-disabled'"
    (click)="saveAndPublish()">Save and Publish</button>
  <!-- [disabled]="!hasUnsavedChanges()" -->
</div>

<!-- When user clicks on cancel button -->
<kmd-modal [id]="'confirmation-modal-unsaved-changes-nav'" class="confirmation-modal" modalSize="mini"
  headline="Unsaved changes" (clickedOutside)=" closeModalNavigateAlert('confirmation-modal-unsaved-changes-nav')">
  <div>
    <span class="text">
      You're about to leave this place. Any unsaved changes will be lost
    </span>
  </div>
  <div>
    <span class="text-two">
      Would you like to save the changes before leaving?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)=" closeModalNavigateAlert('confirmation-modal-unsaved-changes-nav')"
      kmdPrimaryButton>Don't save</button>
    <button id="confirm-button-model" (click)="ConfirmAndNavigateAlert()" kmdFeaturedButton>Save changes</button>
  </div>
</kmd-modal>

<!-- When user clicks on cancel button -->
<kmd-modal [id]="'confirmation-modal-unsaved-changes'" class="confirmation-modal" modalSize="mini"
  headline="Unsaved changes" (clickedOutside)=" closeModalNavigate('confirmation-modal-unsaved-changes')">
  <div>
    <span class="text">
      You're about to leave this place. Any unsaved changes will be lost
    </span>
  </div>
  <div>
    <span class="text-two">
      Would you like to save the changes before leaving?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)=" closeModalNavigate('confirmation-modal-unsaved-changes')"
      kmdPrimaryButton>Don't save</button>
    <button id="confirm-button-model" (click)="ConfirmAndNavigate()" kmdFeaturedButton>Save changes</button>
  </div>
</kmd-modal>

<!-- When clicked on Save and Publish -->
<kmd-modal [id]="'confirmation-modal-save-changes'" class="confirmation-modal" modalSize="mini" headline="Save changes"
  (clickedOutside)=" closeModalNavigate('confirmation-modal-save-changes')">
  <div>
    <span class="text">
      Are you sure you want to save changes to the feedback form?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)=" closeModal('confirmation-modal-save-changes')"
      kmdPrimaryButton>Cancel</button>
    <button id="confirm-button-model" kmdFeaturedButton (click)="ConfirmAndNavigate()">Confirm</button>
  </div>
</kmd-modal>

<div style="margin-top: 10px">
  <kmd-alerts kmd-warning [floating]="true" [show]="showAlertEmptyInputs" [icon]="true">Some fields are
    empty!</kmd-alerts>
</div>