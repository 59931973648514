<app-modal [id]="id" [headline]="headline" [subHeadline]="!isPublicShare ? subHeadline : undefined">
    <div #modalContent class="modal-body-content" [ngClass]="{'scrollable': scrollableModal}">
        <div class="top-section">
            <ng-container #publicAgentShareFormProperties *ngIf="isPublicShare">
                <div class="modal-row columns three-sections justify-to-start">
                    <div id="name-display" class="property-wrapper">
                        <label>Name:</label>
                        <p>{{sharingDetails?.agent?.name}}</p>
                    </div>
                    <div class="property-wrapper">
                        <label>Category:</label>
                        <p>{{sharingDetails?.agent?.category}}</p>
                    </div>
                    <div class="property-wrapper">
                        <label>Created By:</label>
                        <p>{{agentOwner}}</p>
                    </div>
                </div>
            </ng-container>
            <ng-container #shareAgentFormProperties *ngIf="!isPublicShare">
                <div class="modal-row columns three-sections">
                    <div class="modal-row rows">
                        <div id="name-display" class="property-wrapper">
                            <label>Name:</label>
                            <p>{{sharingDetails?.agent?.name}}</p>
                        </div>
                        <div class="property-wrapper">
                            <label>Share to:</label>
                            <p>Microsoft group</p>
                        </div>
                    </div>
                    <div class="modal-row rows">
                        <div class="property-wrapper">
                            <label>Category:</label>
                            <p>{{sharingDetails?.agent?.category}}</p>
                        </div>
                        <div class="property-wrapper">
                            <label>Group name:</label>
                            <p><a href="https://myaccount.microsoft.com/groups/{{group?.id}}" target="_blank">{{group?.displayName}}</a></p>
                        </div>
                    </div>
                    <div class="modal-row rows">
                        <div class="property-wrapper empty"></div>
                        <div class="property-wrapper">
                            <label>Admins:</label>
                            <p>{{adminsLabel}}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="modal-row">
                <div class="property-wrapper">
                    <label>Description</label>
                    <p>{{sharingDetails?.agent?.description}}</p>
                </div>
            </div>
            <div class="modal-row">
                <div class="property-wrapper">
                    <label>Conversation Starter:</label>
                    <p>{{conversationStarter}}</p>
                </div>
            </div>
            <div class="modal-row">
                <div id="instructions-display" class="property-wrapper">
                    <label>Instructions</label>
                    <p>{{sharingDetails?.agent?.instructions}}</p>
                </div>
            </div>
            <div class="modal-row" *ngIf="sharingDetails?.selectedFiles?.length">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div class="table-header" (click)="sortByFileName()">
                                    <label>File name</label>
                                    <div class="sorting-action-icon">
                                        <i class="up"></i>
                                        <i class="down"></i>
                                    </div>
                                </div>                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of sharingDetails?.selectedFiles">
                            <td>
                                <span class="link" (click)="showFile(file.id!)">{{file.name}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-row rows">
                <ng-container #publicAgentShareFormConsent *ngIf="isPublicShare">
                    <div id="public-sharing-disclaimer">
                        <p>By making my Agent public, I confirm that my Agent complies with the <a href="/guidelines" target="_blank">Gene.AI Guidelines</a> and agree to print my name in association with my agent.</p>
                    </div>
                </ng-container>
                <ng-container #shareAgentFormConsent *ngIf="!isPublicShare">
                    <div id="public-sharing-disclaimer">
                        <p>By sharing your agent you are agreeing to display your name and any other admins under the “Admin:” field.</p>
                    </div>
                    <div class="checkbox">
                        <core-checkbox label="" (onClick)="toggleCreationGuidelinesCompliance()"
                            [checked]="creationGuidelinesCompliance" />
                        <label>I confirm I've read and comply with <a href="/guidelines" target="_blank">Agent creation guidelines.</a></label>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="bottom-section">
            <div class="left">
                <label><a (click)="onReturnToEditAgentClick()">Edit agent</a></label>
            </div>
            <div id="modal-action-buttons" class="right">
                <button kmdInfoButton (click)="closeModal()">Cancel</button>
                <button kmdFeaturedButton (click)="shareAgent()" [disabled]="isShareButtonDisabled">Share agent</button>
            </div>
        </div>
    </div>
</app-modal>