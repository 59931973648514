import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { ccdRoles } from "@shared/constants/roles/roles";

@Injectable({
  providedIn: "root",
})
export class CCDRoleGuard {
  constructor(
    private oidcIdentityService: OidcSecurityService,
    private router: Router
  ) {}

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.hasCCDRoles().pipe(
      map((hasRoles) =>
        hasRoles ? true : this.router.createUrlTree(["/error"])
      )
    );
  }

  hasCCDRoles() {
    return this.oidcIdentityService.userData$.pipe(
      map((userData) => {
        let roles = userData?.userData?.["roles"];
        for (let role of ccdRoles) {
          if (roles.includes(role)) {
            return true;
          }
        }
        return false;
      })
    );
  }
}
