<body>
<ng-container *ngIf="!featureFlags.maintenanceMode; else maintenanceTemplate">
  <ng-container *ngIf="showHeaderFooter">
    <div [ngClass]="{'header-wrapper ux-revamp-header' : isMobileScreen()}">
      <ng-container *ngIf="isMobileScreen()">
        <div class="left">
          <div [id]="navMenuToggleId" *ngIf="isAuthenticated"
               class="header-menu-icon trigger-toggle-chat-history">
            <img src="../assets/icons/hamburger/hamburger-24px-mono.svg">
          </div>
          <div class="header-logo">
            <img src="assets/images/gene-ai-header.svg" height="24" id="headerLogoId">
          </div>
        </div>
        <div class="right user-menu-wrapper" *ngIf="isAuthenticated">
          <div id="user-initials-menu-icon" (click)="openContextMenu($event)">
            <app-circle-with-initials [user]="'User'"
                                      [username]="(userData$ | async)?.name || 'NA'"/>
          </div>
          <app-user-context-menu/>
        </div>
      </ng-container>
      <div id="floating-user-menu" *ngIf="!isMobileScreen()">
        <div *ngIf="isAuthenticated">
          <div id="user-initials-menu-icon" (click)="openContextMenu($event)">
            <app-circle-with-initials [user]="'User'"
                                      [username]="(userData$ | async)?.name || 'NA'"/>
          </div>
          <app-user-context-menu/>
        </div>
      </div>
    </div>
  </ng-container>

  <app-alert></app-alert>

  <div class="content-wrapper ux-revamp">
    <ng-container *ngIf="isAuthenticated">
      <navbar-new [navMenuToggleId]="navMenuToggleId"></navbar-new>
    </ng-container>
    <div class="right-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-template #maintenanceTemplate>
  <app-maintenance></app-maintenance>
</ng-template>
</body>
