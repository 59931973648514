import { Component, EventEmitter, ViewEncapsulation, Output, ViewChild } from "@angular/core";
import { KmdModalService } from "gds-atom-components";
import { ClipboardService } from "ngx-clipboard";
import { TranslationService } from "src/app/core/services/translation/translation.service";
import { ModelFactory } from "src/app/shared/models/gpt-models/model-factory";
import { PromptTokenRatio } from "@app/prompt/prompttokenratio/prompt-token-ratio";
import { FileManagerMyDocsComponent } from "@shared/components/file-manager/my-docs/file-manager-my-docs.component";
import { FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { FileManagerUpload } from "@app/shared/models/file-manager/file-manager-upload.model";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { featureFlags } from '@app/environments/environment';

@Component({
  selector: "app-translations",
  templateUrl: "./translations.component.html",
  styleUrls: ["./translations.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class TranslationsComponent {
  @Output() onModalClose = new EventEmitter<void>();
  @Output() onFileTypeSelected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(FileManagerMyDocsComponent) myDocsComponent!: FileManagerMyDocsComponent;
  protected readonly fileUploadOptions = FileUploadOptions;
  protected readonly featureFlags = featureFlags;

  languageList = [
    "Chinese",
    "Dutch",
    "English",
    "French",
    "German",
    "Italian",
    "Japanese",
    "Korean",
    "Portuguese",
    "Spanish",
  ];

  fileType: string;
  translationContentType: 'Document' | 'Text' = "Text";
  uploadType: FileUploadType = this.fileUploadOptions['TEXT'];
  confirmationHeadline: string = 'Switch to Spreadsheets files?';
  isUploading = false;
  docsExist = false;
  inputText = "";
  response = "";
  selectedLanguage = "";
  dropdownLabel = "Select language";
  showErrorAlert = false;
  errorAlertText = "We encountered an unexpected error. Please try again.";
  isCopied = false;
  alertCopyText = "Prompt successfully added to clipboard."
  readonly TRANSLATIONS_MODEL = "gpt-4";

  constructor(
      private clipboard: ClipboardService,
      private kmdModalService: KmdModalService,
      private translationService: TranslationService,
      private modelFactory: ModelFactory,
      private fileManagerService: FileManagerService,
  ) {
    this.fileType = "Text";
  }

  initFileFetch(): void {
    const interval = setInterval(() => {
      if (this.myDocsComponent) {
        this.myDocsComponent.initFileFetch();
        clearInterval(interval);
      }
    }, 100);
  }

  closeModal(): void {
    this.onModalClose.emit();
  };

  onFileUploaded(event: FileManagerUpload) {
    this.myDocsComponent.addFile(event);
  }

  onUploadingInProgress(isUploading: boolean) {
    this.isUploading = isUploading;
  }

  onLanguageChange(event: string) {
    this.selectedLanguage = event;
  }

  isCopyEnabled() {
    return this.response.length > 0;
  }

  copyResponse() {
    if (this.isCopyEnabled()){
      this.clipboard.copyFromContent(this.response);
      this.isCopied = true;
    }
  }

  translateText() {
    this.kmdModalService.open("loadingResponseModal");
    this.translationService
        .translate(this.inputText, this.selectedLanguage)
        .subscribe({
          next: (translation) => {
            this.response = translation.translatedText;
            this.kmdModalService.close("loadingResponseModal");
          },
          error: () => {
            this.showErrorAlert = true;
            this.kmdModalService.close("loadingResponseModal");
          },
        });
  }

  isTranslateTextEnabled() {
    return this.inputText.length > 0 && this.selectedLanguage.length > 0;
  }

  translateDoc() {
    this.kmdModalService.open("loadingResponseModal");
    this.translationService
        .translate(this.inputText, this.selectedLanguage) // TODO -switch to call translate document
        .subscribe({
          next: (translation) => {
            this.response = translation.translatedText;
            this.kmdModalService.close("loadingResponseModal");
          },
          error: () => {
            this.showErrorAlert = true;
            this.kmdModalService.close("loadingResponseModal");
          },
        });
  }

  isTranslateDocEnabled(): boolean {
    return true;
  }

  maxInputCharLimit(): number {
    const gptWordToTokenRatio: number = 3.5;
    const modelTokens = this.modelFactory.create(this.TRANSLATIONS_MODEL).TOKENS;
    return ((modelTokens / 8) * PromptTokenRatio.TRANSLATIONS) * gptWordToTokenRatio;
  }

  isClearTextEnabled(): boolean {
    return this.inputText.length > 0 || this.response.length > 0;
  }

  clearText (): void {
    this.inputText = "";
    this.response = "";
  }

  updateUploadType() {
    this.uploadType = this.fileType === FileUploadOptions['Text'].fileType
        ? this.fileUploadOptions['Text']
        : this.fileUploadOptions['Document'];
  }

  setConfirmationHeadline(fileType: string) {
    this.confirmationHeadline = `Switch to ${fileType === 'Text' ? 'Document' : 'Text'} files?`;
  }

  resetTextButton() {
    this.setFileType('Text');
  }

  setFileType(fileType: string) {
    this.fileType = fileType;
    this.onFileTypeSelected.emit(fileType);
    this.updateUploadType();
    this.setConfirmationHeadline(fileType);
  }

  protected readonly document = document;
}
