import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ResizeService } from "@app/core/services/resize/resize.service";
import { ModalService } from "@services/modal/modal.service";
import { MemberOfGroup } from "@shared/models/group-membership/member-of/member-of-group.model";
import { Member } from "@shared/models/group-membership/members/member.model";

@Component({
    selector: 'app-agent-sharing-group-member-list-modal',
    templateUrl: './agent-sharing-group-member-list-modal.component.html',
    styleUrls: ['./agent-sharing-group-member-list-modal.component.css']
})
export class AgentSharingGroupMemberListModalComponent implements AfterViewInit {
    
    @Input() group: MemberOfGroup | null = null;
    @Input() groupMembers: Member[] = [];

    @ViewChild('modalContent') modalContent?: ElementRef;

    readonly id = 'agent-sharing-group-member-list-modal';
    
    readonly MEMBERS_TO_SHOW_COUNT = 10;
    readonly MODAL_PADDING = 150;

    membersToShow: Member[] = [];
    scrollableModal = false;

    modalHeight: number = 0;
    windowHeight: number = 0;

    constructor(
        private modalService: ModalService,
        private resizeService: ResizeService,
        private cdRef: ChangeDetectorRef
    ) { };

    ngAfterViewInit(): void {
        this.resizeService.setObserver(this.modalContent!.nativeElement, (entry: any) => this.onModalResize(entry));
        this.resizeService.setObserver(document.body, (entry: any) => this.onWindowResize(entry));
    };

    onModalResize(element: any): any {
        const { height } = element.contentRect;
        this.modalHeight = height + this.MODAL_PADDING;

        if (!this.scrollableModal) {
            this.scrollableModal = (this.windowHeight - this.modalHeight) < 0;
            this.cdRef.detectChanges();
        }
    };

    onWindowResize(element: any): any {
        const { height } = element.contentRect;
        this.windowHeight = height;
        this.scrollableModal = false;
        this.cdRef.detectChanges();
        this.closeModal();
    };

    get headline(): string {
        return (this.group) ? `Group Members of ${this.group.displayName}` : 'Group Members';
    };

    openModal(): void {
        this.modalService.open(this.id);
        this.cdRef.detectChanges();
        this.setMembersToShow();
    };

    closeModal(): void {
        this.modalService.close(this.id);
    };

    setMembersToShow(): void {
        const members = this.groupMembers.map(member => {
            const name = `${member.givenName} ${member.surname}`;

            return {
                ...member,
                displayName: name
            };
        });

        members.sort((a, b) => {
            if (a.displayName < b.displayName) {
                return -1;
            }
            if (a.displayName > b.displayName) {
                return 1;
            }
            return 0;
        });

        this.membersToShow =  (members.length <= this.MEMBERS_TO_SHOW_COUNT) ? members : members.slice(0, this.MEMBERS_TO_SHOW_COUNT);
    };
}