<div class="document-translations-mobile-notification">
  <div class="notification-dt">
    <div class="notification-left-container">
      <img src="assets/icons/info/size=24px, style=bold.svg" alt="Icon/Info/Bold">
    </div>
    <div class="notification-right-container">
      <p class="notification-info">
        Translations created with this tool are <strong>strictly for internal use</strong> and must not be shared with customers.
      </p>
      <p class="notification-link">
        <a href="https://thermofisher.sharepoint.com/sites/LanguageServices" target="blank">
          <img src="assets/icons/instrument/instrument.svg" alt="Icon/Science/Instrument">
          <strong>Contact translation services</strong>
        </a>
      </p>
    </div>
  </div>
</div>
