import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, map } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '@app/shared/models/user-data.model';
import { ChatAuthor, ChatMessage } from '@shared/models/ask-our-data.model';

import { PromptSettings } from '@shared/models/prompt-settings.model';
import { PromptInputComponent } from '@app/shared/components/prompt-input/prompt-input.component';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { ClipboardService } from "ngx-clipboard";
import { Messages } from '@app/shared/constants/ask-our-data-constants';
import { PlotlyService }  from 'src/app/core/services/ask-our-data/plotly.service';
import { featureFlags } from '@app/environments/environment';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LZStringService } from '@app/core/services/ask-our-data/lz-string.service';

@Component({
  selector: 'app-chat-with-db',
  templateUrl: './chat-with-db.component.html',
  styleUrls: ['./chat-with-db.component.css']
})
export class ChatWithDbComponent implements OnInit, OnDestroy{
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;
  userData$: Observable<UserData>;
  selectedChatId: string;
  promptSettings: PromptSettings;
  isLoading: boolean = false;
  generatingResponse: boolean = false;
  receivedResponse = false;
  lastSystemMessage?: ChatMessage;
  dbname = '';
  dbId = '';
  ischatEmpty = true;
  isDbConnectErr = false;
  private subscriptions = new Subscription();
  generateSqlFailed = false;
  generatingChart: boolean[] = [];

  messages: ChatMessage[] = [];
  visibleMessages: ChatMessage[] = [];
  showSavePrompt: boolean = false;
  showCopyPrompt: boolean = true;
  inputPrompt: string = '';
  generateSubscription: Subscription | undefined;
  runSqlSubscription: Subscription | undefined;
  sqlResponse = '';
  showErrorIcon: boolean[] = [];
  jsonResponse: any;
  resultObtanined: boolean[] = [];
  protected readonly featureFlags = featureFlags;
  graphData: any[] = [];
  graphLayout: any[] = [];
  openGraph: boolean[] = [];
  chartGenerated: boolean[] = [];
  sessionId = this.generateSessionId();
  chatId = 1;
  userScrolling = false;
  isConversationResponse: any = [];
  chatInteractions: any[] = [];

  @HostListener('window:unload', [ '$event' ])
  unloadHandler() {
    this.removeLocalStorage();
  }


  constructor(private router: Router,
    private askOurDataService: AskOurDataService,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private clipboard: ClipboardService,
    private alertService: AlertService,
    private plotly: PlotlyService,
    private lZStringService: LZStringService,
    private route: ActivatedRoute) {
    this.selectedChatId = '';
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
    this.promptSettings = new PromptSettings(0, 'PROFESSIONAL', false, 'gpt-4-o');
  }

  ngOnInit(): void {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        this.dbId = params['id'];
        this.dbname = params['name'];
      });
    }

  }

  generateSessionId() {
    const chars = '0123456789';
    let sessionId = '';
    for (let i = 0; i < 10; i++) {
      sessionId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return sessionId;
  }

  change(type: string, chatId: number) {
    this.plotly.restyle('plot_child_chat_' + chatId, type);
  }

  async onSubmit(inputPrompt: string) {
    this.chatId ++;
    this.resultObtanined[this.chatId] = false;
    this.isConversationResponse[this.chatId] = false;
    this.showErrorIcon[this.chatId] = false;
    if(!this.featureFlags.conversationChat) {
      this.messages = [];
      this.sqlResponse = '';
    }
    this.isDbConnectErr = false;
    /* istanbul ignore else */
    if (inputPrompt.trim() == "") return;
    /* istanbul ignore else */
    if (this.isLoading) return;
    this.inputPrompt = inputPrompt;
    this.receivedResponse = false;
    this.ischatEmpty = false;
    this.generatingResponse = true;
    this.generateSqlFailed = false;
    let promptValue = (inputPrompt || '').trim();
    this.promptInputComponent.setPrompt('');
    let author = new ChatAuthor("USER");

    let message = new ChatMessage(promptValue , author, new Date().toISOString(), promptValue, this.lastSystemMessage?.id, this.chatId);
    message.id = 'chat_' + this.chatId;
    this.addMessage(message);
    this.generatingResponse = true;
    this.isLoading = true;
    this.cdRef.detectChanges();
    this.scrollToChat('USER');

    let requestData = this.featureFlags.conversationChat ? {
      database_id: this.dbId,
      question: promptValue,
      session_id: this.sessionId,
      chat_history: (this.chatInteractions.length > 0 ? this.chatInteractions : null)
    } : {id: this.dbId, question: promptValue};
    let apiCall = this.featureFlags?.conversationChat ? this.askOurDataService.callConversationChat(requestData) : this.askOurDataService.generateQuery(requestData);
    this.generateSubscription = apiCall.subscribe({
      next: (response: any) => {
        let isNotNullResponse = this.featureFlags.conversationChat ? response.response : response;
        if (isNotNullResponse) {
          let msg = this.featureFlags.conversationChat ? response.response : response.text;
          let systemResponse = new ChatMessage(msg, new ChatAuthor("SYSTEM"), new Date().toISOString(), msg, response.id, this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.generatingResponse = true;
          this.cdRef.detectChanges();
          if(response.tool_used === 'generate_sql' || !this.featureFlags.conversationChat) {
            this.runSql(msg, systemResponse, promptValue, response.chat_history_summary, response.contextual_question);
          } else {
            this.generatingResponse = false;
            this.receivedResponse = true;
            this.isLoading = false;
            this.resultObtanined[this.chatId] = true;
            this.isConversationResponse[this.chatId] = true;
            if(response.chat_history_summary) {
              this.chatInteractions = [];
              this.chatInteractions.push({
                "role": "assistant",
                "content": response.chat_history_summary,
                "content_type": "chat_history_summary"
              });
            } else{
              this.chatInteractions.push({
                "role": "user",
                "content": promptValue,
                "content_type": "Question"
              });
              this.chatInteractions.push({
                "role": "assistant",
                "content": response.response,
                "content_type": "chat_response"
              });
            }
            let systemResponse = new ChatMessage(`<div class="copyable-${this.chatId}">` + response.response + `</div>`, new ChatAuthor("SYSTEM"), new Date().toISOString(), msg, '1', this.chatId);
            systemResponse.id = 'child_chat_' + this.chatId;
            systemResponse.parentId = 'chat_' + this.chatId;
            this.addMessage(systemResponse);
            this.cdRef.detectChanges();
            this.scrollToChat('SYSTEM');
          }
        } else {
          this.generatingResponse = false;
          this.receivedResponse = true;
          this.isLoading = false;
          this.showErrorIcon[this.chatId] = true;
          let generateErr =  Messages.GENERATE_ERROR;
          let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', '1', this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.cdRef.detectChanges();
          this.scrollToChat('SYSTEM');
        }
      },
      error: (error) => {
        if(this.featureFlags.conversationChat && error.error?.error?.status_code === 204) {
          this.generatingResponse = false;
          this.receivedResponse = true;
          this.isLoading = false;
          this.showErrorIcon[this.chatId] = true;
          let generateErr =  Messages.GENERATE_ERROR;
          let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', '1', this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.cdRef.detectChanges();
        } else {
          if (error.status === 503) {
            this.isDbConnectErr = true;
            this.generatingResponse = false;
            this.receivedResponse = false;
            this.isLoading = false;
          } else {
            let errMsg = (!this.featureFlags.conversationChat) ? error.error?.error_message : error.error?.error?.error_message;
            let generateErr =  (errMsg?.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED :  Messages.GENERATE_SQL_ERROR);

            this.showErrorIcon[this.chatId] = true;
            let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', 'parentId', this.chatId);
            systemResponse.id = 'child_chat_' + this.chatId;
            systemResponse.parentId = 'chat_' + this.chatId;
            this.addMessage(systemResponse);
            this.generateSqlFailed = true;
            this.generatingResponse = false;
            this.isLoading = false;
          }
        }
        this.receivedResponse = true;
        this.cdRef.detectChanges();
        this.scrollToChat('SYSTEM');
      }
    });
    this.subscriptions.add(this.generateSubscription);
  }

  copyToClipboard(text:  string, chatId: number, role: string, isText: boolean = true) {
    if(role !== 'USER' && !isText) {
      this.copyResponse(chatId);
    } else {
      if(text) {
        this.clipboard.copyFromContent(text);
        this.alertService.showSuccess(Messages.COPIED_TO_CLIPBOARD);
      }
    }
  }

  addMessage(message: ChatMessage) {
    let messageIndex = this.messages.findIndex(m => m.id === message.id);
    if (messageIndex === -1) {
      this.messages.push(message);
    } else {
      this.messages[messageIndex] = message;
    }
  }

  runSql(sql: string, systemResponse: ChatMessage, promptValue: string, chat_history: string, contextual_question: string): void {
    this.isDbConnectErr = false;
    this.runSqlSubscription = this.askOurDataService.runQuery({
      id: this.dbId,
      sql: sql,
      question: promptValue,
      generate_summary: true,
      session_id: this.sessionId 
    }).subscribe({
      next: (response) => {
        let jsonResponse = JSON.parse(response?.df_json || "[]");
        if(response && jsonResponse.length > 0) {
          this.generatingResponse = false;
          if(this.featureFlags.enableAoDataChart) {
            this.resultObtanined[this.chatId] = true;
            this.chartGenerated[this.chatId] = false;
            this.getChart(sql, response.df_json, promptValue, this.chatId);
          }
          this.cdRef.detectChanges();
          this.sqlResponse = response.df_markdown;
          let headers = Object.keys(jsonResponse[0]);
          let responseMessage = `<div class="chat-response "><p class="copyable-${this.chatId}">`+ systemResponse.text +`</p> <div class="chat-table copyable-${this.chatId}"><table>`;
          responseMessage += `<tr>`;
          headers.forEach(header => {
            responseMessage += `<th>`+header+`</th>`;
          });
          responseMessage += `</tr>`;
          let firstFiveRecords = jsonResponse.slice(0, 5);
          firstFiveRecords.forEach((data: any) => {
            responseMessage += `<tr>`;
            headers.forEach(header => {
              responseMessage += `<td>`+data[header]+`</td>`;
            });
            responseMessage += `</tr>`;
          });
          responseMessage += `</table></div>`;
          if(jsonResponse.length > 5) {
            responseMessage += `<div class="table-bot-div library-icomoon"><a href="/ask-our-data/response/${this.chatId}_${this.sessionId}" target="_blank" >View Full Table <i class="pl-2 icon-external-link-mono"></i></a></div>`;
            try {
              let ftItems = JSON.parse(localStorage.getItem('FullTable_items') || "[]"); 
              ftItems.push(this.chatId + '_' + this.sessionId);
              localStorage.setItem('FullTable_items', JSON.stringify(ftItems));
              localStorage.setItem('fullTableData_'+ this.chatId + '_' + this.sessionId, this.lZStringService.compress(JSON.stringify({
                name:this.dbname,
                id: this.dbId,
                sql: sql,
                promptValue: promptValue,
                session_id: this.sessionId
              })));
            } catch (error) {
              console.log(error);
            }
          }
          responseMessage += `</div>`;
          
          if(chat_history) {

            this.chatInteractions = [];
            this.chatInteractions.push({
              "role": "assistant",
              "content": chat_history,
              "content_type": "chat_history_summary"
            });

          } else {
            this.chatInteractions.push({
              "role": "user",
              "content": contextual_question || promptValue,
              "content_type": "Question"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": sql,
              "content_type": "sql"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": JSON.stringify(jsonResponse.slice(0, 10)),
              "content_type": "sql_result"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": response.summary,
              "content_type": "sql_result_summary"
            });
          }
          let systemRunSqlResponse = new ChatMessage(responseMessage, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, response.id, this.chatId, response.summary);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.isLoading = false;
          this.receivedResponse = true;
          this.cdRef.detectChanges();
          this.scrollToChat('SYSTEM');
        } else {
          if(chat_history) {
            this.chatInteractions = [];
            this.chatInteractions.push({
              "role": "assistant",
              "content": chat_history,
              "content_type": "chat_history_summary"
            });
          } else {
            this.chatInteractions.push({
              "role": "user",
              "content": contextual_question || promptValue,
              "content_type": "Question"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": sql,
              "content_type": "sql"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": JSON.stringify([]),
              "content_type": "sql_result"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": response.summary,
              "content_type": "sql_result_summary"
            });
          }
          this.generateSqlFailed = true;
          systemResponse.parentId = 'parentId';
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.generatingResponse = false;
          this.isLoading = false;
          this.receivedResponse = true;
          this.cdRef.detectChanges();
          this.scrollToChat('SYSTEM');
        }
      },
      error: (error) => {
        if (error.status === 503) {
          this.isDbConnectErr = true;
        } else if (error.status === 400) {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let injectionErr = (error.error?.error_message.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED :  Messages.NO_RESPONSE);
          let sqlErr =  (error.error?.error_message.match(/^Syntax error\.*/g)) ? Messages.GENERATE_SQL_ERROR : injectionErr;
          let sqlErrMsg = `<p>` + sqlErr + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErrMsg, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        } else {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        }
        this.isLoading = false;
        this.generatingResponse = false;
        this.receivedResponse = true;
        this.cdRef.detectChanges();
        this.scrollToChat('SYSTEM');
      }
    });
    this.subscriptions.add(this.runSqlSubscription);
  }

  scrollToChat(role: string) {
    let main = document.querySelector('.chat-overview-container');
    let childId = '#chat_response_' + role + '_' + this.chatId;
    let child = document.querySelector(childId);
    this.scrollToElm(main, child, 400)
  }

  viewGraph(id: number) {
    this.openGraph[id] = !this.openGraph[id];
    this.cdRef.detectChanges();
    this.generatingChart[id] = true;
    if(this.openGraph[id] && this.graphData[id]) {
      this.plotly.plotLine("", "plot_child_chat_"+ id, [...this.graphData[id]], {...this.graphLayout[id]});
      let graphDiv = document.getElementById('plot_end_'+ id);
      if (graphDiv !== null) {
        graphDiv.scrollIntoView();
      }
    } else if(!this.graphData[id]){
      this.alertService.showError('Unable to generate chart')
    }
    this.generatingChart[id] = false;
  }

  getChart(sql: string, chatResponse: any, promptValue: string, chatId: number) {
    this.generatingChart[chatId] = true;
    let request = {
        "id" : this.dbId,
        "question" : promptValue,
        "sql" : sql,
        "df" : chatResponse
      };
    this.askOurDataService.generateChart(request).subscribe({
      next: (response) => {
        this.generatingChart[chatId] = false;
        if(response) {
          this.chartGenerated[chatId] = true;
          let graph = JSON.parse(response.fig);
          this.graphData[chatId] = graph.data;
          this.graphLayout[chatId] = graph.layout;
        }
      },
      error: () => {
        this.generatingChart[chatId] = false;
        this.cdRef.detectChanges();
      }
    });
  }

  regenerate() {
    this.messages.pop();
    this.openGraph[this.chatId] = false;
    this.graphData[this.chatId] = '';
    this.showErrorIcon[this.chatId] = false;
    this.chartGenerated[this.chatId] = false;
    this.chatId--;
    this.onSubmit(this.inputPrompt);
  }

  toShowCopyPrompt(role: string, chatId: number): boolean {
    return  role === 'USER' || this.resultObtanined[chatId];
  }

  stopResponse() {
    this.isLoading = false;
    this.generatingResponse = false;
    this.generateSubscription?.unsubscribe();
    this.runSqlSubscription?.unsubscribe();
  }

  isSubmitDisabled(): boolean {
    return this.isLoading || this.generatingResponse || this.isDbConnectErr;
  }

  back() {
     this.router.navigate(['/ask-our-data']);
  }

  startNewChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/ask-our-data/chat'],{ queryParams: { id: this.dbId, name: this.dbname }}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.removeLocalStorage();
  }

 scrollToElm = (container: any, elm: any, duration: any) =>{
  let pos = this.getRelativePos(elm);
  this.scrollTo(container, pos.top, duration/1000);  // duration in seconds
 }

 getRelativePos = (elm: any) => {
  let pPos = elm?.parentNode?.parentNode?.getBoundingClientRect(), // parent pos
      cPos = elm?.getBoundingClientRect(), // target pos
      pos: any = {};
  if(pPos && cPos) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    pos.top    = !this.userScrolling ? cPos.top    - pPos.top + elm.parentNode.scrollTop : cPos.top,
    pos.right  = cPos.right  - pPos.right,
    pos.bottom = cPos.bottom - pPos.bottom,
    pos.left   = cPos.left   - pPos.left;
  }
  return pos;
 }

 scrollTo = (element: any, to: any, duration: any, onDone?: () => void) => {
    let start = element.scrollTop,
        change = to - start,
        startTime = performance.now(), now, elapsed, t;

      let animateScroll = () => {
        now = performance.now();
        elapsed = (now - startTime)/1000;
        t = (elapsed/duration);

        element.scrollTop = start + change * this.easeInOutQuad(t);
        this.userScrolling = false;

        if( t < 1 )
            window.requestAnimationFrame(animateScroll);
        else
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onDone && onDone();
    };

    animateScroll();
 }

 easeInOutQuad(t: any){ return t<.5 ? 2*t*t : -1+(4-2*t)*t };

 scrollChat(event: any) {
    this.userScrolling = !(event.target!.offsetHeight + event.target!.scrollTop >= event.target!.scrollHeight);
 }

 copyResponse = (chatId: number): void => {
    const divs = document.querySelectorAll('.copyable-'+ chatId);
    if (!divs || divs.length === 0) {
      this.alertService.showError('Unable to copy');
      return;
    }

    const tempContainer = document.createElement('div');
    tempContainer.style.position = 'absolute';
    tempContainer.style.left = '-9999px';  
    tempContainer.style.whiteSpace = 'pre-wrap';
    document.body.appendChild(tempContainer);
 
    divs.forEach((div) => {
      const clonedDiv = div.cloneNode(true); 
      tempContainer.appendChild(clonedDiv);
      tempContainer.appendChild(document.createElement('br'));
    });

    let svgContent = '';
    let svgContentEle = document.querySelector('.plot_' + chatId + ' .svg-container svg') as HTMLOrSVGImageElement; 
    if (svgContentEle) {
      let s = new XMLSerializer();
      svgContent = s.serializeToString(svgContentEle);
    } else {
      const chartContainer = document.createElement('div');
      chartContainer.style.position = 'absolute';
      chartContainer.style.left = '-9999px';  
      chartContainer.style.whiteSpace = 'pre-wrap';
      chartContainer.id = "plot_temp";
      document.body.appendChild(chartContainer);

      if(this.graphData[chatId]) {
        this.plotly.plotLine("", "plot_temp", [...this.graphData[chatId]], {...this.graphLayout[chatId]});
        svgContentEle = document.querySelector('#plot_temp' + ' .svg-container svg') as HTMLOrSVGImageElement; 
        if (svgContentEle) {
          let s = new XMLSerializer();
          svgContent = s.serializeToString(svgContentEle);
        }
      }
    }

    if(svgContent) {
      const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
      let img1 = document.createElement('img');
      this.convertSvgToPngDataUri(svgData, img1);
      tempContainer.appendChild(img1);
    }

    setTimeout(()=> { 
      const range = document.createRange();
      range.selectNodeContents(tempContainer);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        this.alertService.showError('Unable to copy');
        return;
      }
      try {
        document.execCommand('copy');
        console.log('Copied to clipboard.');
        this.alertService.showSuccess('Copied to clipboard');
      } catch (err) {
        console.error('Failed to copy content:', err);
        this.alertService.showError('Unable to copy');
      }
  
      selection?.removeAllRanges();
      document.body.removeChild(tempContainer);

    }, 500);
     
  }

  convertSvgToPngDataUri(svgDataUri: string, img: any): any {
    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngDataUri = canvas.toDataURL('image/png');

          img.src = pngDataUri;
          
         resolve(pngDataUri);
        } else {
          this.alertService.showError('Unable to download chart');
         reject('Could not get canvas context');
        }
     };
      img.onerror = () => {
        this.alertService.showError('Unable to download chart');
        reject('Failed to load SVG image');
      };
      img.src = svgDataUri;
    });
  }

  removeLocalStorage() {
    let ftItems = JSON.parse(localStorage.getItem('FullTable_items') || "[]"); 
    ftItems.forEach((ftItem: any) => {
      localStorage.removeItem('fullTableData_'+ ftItem);
    });
    localStorage.removeItem('FullTable_items');
  }

}

