import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { featureFlags } from '@app/environments/environment';
declare let Plotly: any;
@Injectable({
  providedIn: 'root'
})
export class PlotlyService {
  protected readonly featureFlags = featureFlags;
  constructor(private alertService: AlertService) { }
  plotLine(title: string, plotDiv: string, data: any, Glayout: any){
    let layout = {
      title:title,
      showlegend: true,
      xaxis: {
        automargin: true,
      },
      yaxis: {
        automargin: true,
      }
    };
    if(Glayout){
      layout = Glayout;
      layout.title = title;
      if(!layout.xaxis) {
        layout.xaxis = {automargin: true};
      } else {
        layout.xaxis.automargin = true;
      }
      if(!layout.yaxis) {
        layout.yaxis = {automargin: true};
      } else {
        layout.yaxis.automargin = true;
      }
    }
    if(data && data[0]?.hovertemplate){
      delete data[0]?.hovertemplate;
    }

    let isGLData = this.checkIfItIsGLGraph(data);
    
    data = this.changeGlToNonGL(data);

    let modeBarButtonsToAdd = [
      {
        name: 'Download chart',
        icon: Plotly.Icons.camera,
        click: () => {
          let date = new Date();
          let svgContent = '';
          let svgContentEle = document.querySelector('#' + plotDiv + ' .svg-container svg'); 
          if (svgContentEle) {
            let s = new XMLSerializer();
            svgContent = s.serializeToString(svgContentEle);
          }
          if(svgContent) {
            const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
            // Create an anchor element for download
            const downloadLink = document.createElement("a");
            downloadLink.href = svgData; // Set the base64 source
            downloadLink.download ='chart_'+ date.getTime() +'.svg'; // Set file name
            downloadLink.style.display = 'none';
          
      
            // Append the link, trigger click, and remove it
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink); 
            this.alertService.showSuccess('Chart downloaded successfully!');
          } else {
            this.alertService.showError('Unable to download chart');
          }
        }
      }
    ];

    if (this.featureFlags.copyChart) {
      modeBarButtonsToAdd.push({
        name: 'Copy chart',
        icon: {
          width: 16,
          height: 16,
          path: 'M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 .7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z'
        },
        click: () => {
          let svgContent = '';
          let svgContentEle = document.querySelector('#' + plotDiv + ' .svg-container svg'); 
          if (svgContentEle) {
            let s = new XMLSerializer();
            svgContent = s.serializeToString(svgContentEle);
          }
          if(svgContent) {
            const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
            const img = new Image();
            img.src = svgData;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                  if (blob) {
                    const item = new ClipboardItem({ 'image/png': blob });
                    navigator.clipboard.write([item]).then(() => {
                      this.alertService.showSuccess('Chart copied to clipboard!');
                    }).catch((error) => {
                      console.log(error);
                      this.alertService.showError('Unable to copy chart');
                    });
                  } else {
                    this.alertService.showError('Unable to copy chart');
                  }
                });
              } else {
                this.alertService.showError('Unable to copy chart');
              }
            };
          } else {
            this.alertService.showError('Unable to copy chart');
          }
        }
      });
    }

    Plotly.newPlot(plotDiv, data, layout, {
      responsive: true, 
      displaylogo: false,
      modeBarButtonsToRemove: ['toImage'],
      modeBarButtonsToAdd: modeBarButtonsToAdd
    }); 
    
    if(isGLData) {
      Plotly.restyle(plotDiv, 'type', 'scatter', {responsive: true, showlegend: true , displaylogo: false, modeBarButtonsToRemove: ['toImage']});
    }
  }

  changeGlToNonGL(data: any) {
    if(data[0] && data[0].type) {
      switch(data[0].type.toLowerCase()){
        case 'scattergl': 
              data[0].type = 'scatter';
              break;
        case 'heatmapgl': 
              data[0].type = 'heatmap';
              break;
        case 'scatter3d': 
              data[0].type = 'scatter';
              break;
        case 'pointcloud': 
              data[0].type = 'scatter';
              break;
        case 'parcoords': 
              data[0].type = 'scatter';
              break;
        case 'surface': 
              data[0].type = 'heatmap';
              break;
        case 'mesh3d': 
              data[0].type = 'scatterternary';
              break;
        case 'line3d': 
              data[0].type = 'line';
              break;
        case 'cone': 
              data[0].type = 'scatterpolar';
              break;
        case 'streamtube': 
              data[0].type = 'scatterternary';
              break;
        case 'volume': 
              data[0].type = 'heatmap';
              break;
      }
    } 
    return data;
  }

  checkIfItIsGLGraph(data: any) {
    if(data[0] && data[0].type) {
      let webGlGraphs = ['scattergl', 'heatmapgl', 'scatter3d', 'pointcloud', 'parcoords', 'surface', 'mesh3d', 'line3d', 'cone', 'streamtube', 'volume'];
      return webGlGraphs.indexOf(data[0].type.toLowerCase()) !== -1;
    }
    return false;
  }
  
  restyle(plotDiv: string, type: string) {
    Plotly.restyle(plotDiv, 'type', type, {responsive: true, displaylogo: false, modeBarButtonsToRemove: ['toImage'], showlegend: true});
  }
}
