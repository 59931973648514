import { LogLevel } from "angular-auth-oidc-client";
import EnvFeatureFlags from "@app/feature-flags/env.FeatureFlags.json";
import { Environment } from "@environments/environment.model";
import { FeatureFlags } from "@environments/feature-flags.model";

export const environment: Environment = {
  apiUrl: 'https://gene-ai-api.tfcdev.thermofisher.net/api/dpe-gene-ai',
  askOurDataApiUrl: 'https://gene-ai-solutions-ask-our-data.tfcdev.thermofisher.net/api/gene-ai-solutions-ask-our-data',
  authAppId: '48bd3841-c7fc-4dd2-b973-080d1d7e1b47',
  resourcesUrl: 'https://geneai.tfcdev.thermofisher.com/resources/',
  betaLink: 'https://geneai.tfcstage.thermofisher.com',
  apjAlbIframeUrl: 'https://geneaiapac.thermofisher.com/ai/',
  askOurDocsIframeUrl: 'https://askourdocs-webapp-temp-eus2-01.azurewebsites.net',
  askOurDocsIframeUrlV2: 'https://askourdocs-api.geneai.tfcdev.thermofisher.com',
  authLogLevel: LogLevel.Debug,
  aodSuperAdminGroupId: 'dcb24beb-2cbf-4976-84ce-0269625aae97',
  svabaseUrl: 'https://api.geneai-dev.tfcdev.thermofisher.com',
  svanotificationsUrl: 'https://sva-api.geneai-dev.tfcdev.thermofisher.com/nm/nmsvc',
  svasessionUrl: 'https://api.geneai-dev.tfcdev.thermofisher.com/platformheader',
  svaApiUrl: 'https://api.geneai-dev.tfcdev.thermofisher.com',
  ccdBaseUrl: 'https://ccd-api.geneai-dev.tfcdev.thermofisher.com',
  ccdPowerBiReportID: "a5307e06-4646-4c5f-adfe-85e66668aa54",
  ccdPowerBiHostName: "https://app.powerbi.com"
};

export const featureFlags: FeatureFlags = {
  imageCreation: true,
  askOurDocsAdminList: false,
  recommendedAgents: true,
  sva2: true,
  maintenanceMode: typeof EnvFeatureFlags.maintenanceMode === 'boolean' ? EnvFeatureFlags.maintenanceMode : false,
  enableAoDataChart: true,
  askOurDocsV2: true,
  sqlCuration: true,
  chatSummary: true,
  codeInterpreter: true,
  conversationChat: true,
  copyWholeResponse: true,
  copyChart: true,
  ccd:true,
  enableRedshift: true,
  agentSharing: true,
  documentTab: true,
  documentTranslationNotification: true,
  savePrompt: false,
};
