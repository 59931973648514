import { Component } from '@angular/core';

@Component({
  selector: 'app-document-translations-mobile-notification', // This is the selector to use
  templateUrl: './document-translations-mobile-notification.component.html',
  styleUrls: ['./document-translations-mobile-notification.component.css']
})
export class DocumentTranslationsMobileNotificationComponent {
  translationContentType: string = 'Document';

  constructor() { }
}
