<div class="context-menu"
     [@slide]="getAnimationState()"
     [ngClass]="{
      'disabled': !isMenuOpen,
      'repo-dropdown' : isRepoDropdown
     }">
  <ul>
    <li *ngFor="let item of items"
      (click)="onItemClick(item)"
      [ngClass]="{'selected-item': item === selectedItem}">
      <div class="label-container">
        <img *ngIf="isRepoDropdown" [src]="item.image_url">
        <p>{{ item.label }}</p>
      </div>
      <div [ngClass]="isRepoDropdown? 'menu-item-description-repo-dropdown' : 'menu-item-description'">{{ item.description }}</div>
    </li>
  </ul>
</div>
