import { Injectable } from '@angular/core';
import {  CanDeactivate } from '@angular/router';
import { FeedbackComponent } from '@app/ask-our-docs-v2/components/feedback/feedback.component';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class AskOurDocsCustomizableFeedbackGuard implements CanDeactivate<FeedbackComponent> {
  canDeactivate(component: FeedbackComponent): Observable<boolean> | boolean {
    if(component.hasUnsavedChanges() && component.userNavFlag === false) {
      return new Observable<boolean>(observer => {
        component.alertRedirection().then((result : boolean) => {
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true
  }
}