import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Message } from "../../shared/models/message.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { CopyResponseComponent } from '../../shared/components/copy-response/copy-response.component';
import { KmdModalService } from "gds-atom-components";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: 'app-chat-image-messages',
  templateUrl: './chat-image-messages.component.html',
  styleUrls: ['./chat-image-messages.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChatImageMessagesComponent implements OnInit {
  @ViewChild('copyResponseComponent') copyResponseComponent?: CopyResponseComponent;
  @Input() message!: Message;

  @Output() responseSelectEventImage = new EventEmitter<string>();

  currentDateAndTime!: string;
  fallbackUrl = window.location.origin + "/assets/images/404-not-found-or-expired.png";
  loading: boolean = false;
  imagePresent: boolean = false;

  constructor(
    private http: HttpClient,
    private kmdModalService: KmdModalService,
    private clipboard: ClipboardService
  ) {
  }

  ngOnInit() {
    this.message.imageUrl ??= this.fallbackUrl
    this.currentDateAndTime = new Date().toISOString().replace(/:/g, '-');
  }

  showIconAndMessage() {
    this.imagePresent = true;
  }

  downloadImage(): void {
    this.loading = true;
    this.http.get(this.message.imageUrl!, { observe: 'events', responseType: 'blob' }).subscribe(event => {
      if (event instanceof HttpResponse) {
        const blob = new Blob([event.body as any], { type: 'data:attachment/image' });
        let url = window.URL.createObjectURL(blob);

        this.createAnchorAndClickOnIt(url);

        window.URL.revokeObjectURL(url);
        this.loading = false;
      }
    }, () => {
      let a = document.createElement("a");
      a.href = this.fallbackUrl;
      a.download = `Image-not-found-or-expired.png`;
      a.click();
      this.loading = false;
    });
  }

  createAnchorAndClickOnIt(url: string) {
    const imageCreationDownloadLink = document.createElement("a");
    imageCreationDownloadLink.href = url;
    imageCreationDownloadLink.download = `DALL-E_Image_Creation_${this.currentDateAndTime}.png`;
    imageCreationDownloadLink.click();
  }

  showFullSizeImage() {
    this.loading = true;
    this.http.get(this.message.imageUrl!, { responseType: 'blob', observe: 'response' }).subscribe(response => {
      if (response.status === 200) {
        const blob = new Blob([response.body as BlobPart], { type: response.headers.get('Content-Type') || 'image/*' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
        this.loading = false;
      }
    }, () => {
      window.open(this.fallbackUrl, '_blank');
      this.loading = false;
    });
  }

  openCopyResponse(event: MouseEvent, response: string) {
    this.copyResponseComponent?.showPopover(event, response);
  }

  responseSelected(responseText: string) {
    this.responseSelectEventImage.emit(responseText);
  }

  toggleAccordionItem($event: MouseEvent, id: string) {
    const panel = document.querySelector(".ap-item-" + id);
    if (panel) {
      panel.classList.toggle("accordion-panel-visibility");
    }
  }

  toggleAccordionIcon(accordionVisibility: boolean) {
    return accordionVisibility ? "chevron up" : "chevron down";
  }

  openModal(id: string) {
    this.kmdModalService.open('modal-image-message-' + id);
  }

  closeModal(id: string) {
    this.kmdModalService.close('modal-image-message-' + id);
  }

  copyToClipboard(id: string) {
    this.clipboard.copyFromContent(this.message.imageRevisedPrompt!);
    this.kmdModalService.close('modal-image-message-' + id);
  }

  isImageExpired(): boolean {
    return <boolean>this.message.imageUrl?.includes('not-found-or-expired.png');
  }
}
