<div class="my-docs-container">
  <div class="my-docs-wrapper">
    <div #filesTable class="file-table-wrapper" [ngClass]="{ 'loading': isLoading }" (scroll)="onScroll($event)">
      <table>
        <ng-container *ngIf="isLoading">
          <div class="loading-spinner">
            <kmd-loading-wheel></kmd-loading-wheel>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <ng-container *ngIf="hasFiles; else noResults">
            <thead>
            <tr class="data-headers">
              <th class="row-selector" id="select-all-checkbox">
                <core-checkbox label="" [disabled]="isSelectAllDisabled()"
                               [checked]="selectAllCheckBoxState === 'active'"
                               (onClick)="onAllRowsCheckboxSelection()"/>
              </th>
              <th class="file-name">
                <div class="file-table-head" (click)="sortFiles('name')">
                  <label>File name</label>
                  <i></i>
                </div>
              </th>
              <th class="file-status"></th>
              <th class="date-processed">
                <div class="file-table-head" (click)="sortFiles('processed')">
                  <label>Date added</label>
                  <i></i>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let file of myFiles">
              <tr class="data-row" [ngClass]="{ 'selected': file.isSelected }" (click)="onRowCheckboxSelection(file)">
                <td class="row-selector">
                  <core-checkbox label="" [disabled]="isDisabledRow(file)" [checked]="file.isSelected"
                                 (onClick)="onRowCheckboxSelection(file)"/>
                </td>
                <td class="file-name">
                  <p>{{ getDisplayName(file) }}</p>
                  <ng-container *ngIf="getFileStatus(file) as fileStatus">
                    <app-chip *ngIf="fileStatus.type !== 'default'" class="mobile-chip" [ngClass]="fileStatus.type"
                              [text]="fileStatus.label"/>
                  </ng-container>
                </td>
                <td class="file-status">
                  <ng-container *ngIf="getFileStatus(file) as fileStatus">
                    <app-chip *ngIf="fileStatus.type !== 'default'" [ngClass]="fileStatus.type"
                              [text]="fileStatus.label"/>
                  </ng-container>
                </td>
                <td class="file-date">
                  <p>{{ file.date }}</p>
                </td>
              </tr>
            </ng-container>
            <tr>
              <td colspan="4" class="lazy-loading-spinner" *ngIf="isLazyLoading">
                <div class="loading-spinner">
                  <kmd-loading-wheel></kmd-loading-wheel>
                </div>
            </tr>
            </tbody>
          </ng-container>
          <ng-template #noResults>
            <div id="no-results-container">
              <div class="no-results-image">
                <img src="../../../../../assets/icons/no-results/no-results-icon.svg" alt="">
              </div>
              <div class="no-results-title">
                No results found.
              </div>
              <div class="no-results-text">
                Refine your search or upload a file.
              </div>
            </div>

          </ng-template>
        </ng-container>
      </table>
    </div>
  </div>
</div>
