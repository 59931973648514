import { Component } from '@angular/core';
import { FileUpload } from "@shared/models/file-upload.model";
import { FilesService } from "@services/files/files.service";
import { catchError, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { KmdModalService } from "gds-atom-components";
import { featureFlags } from "@environments/environment";
import { AgentsService } from '@app/core/services/agents/agents.service';
import { FileUploadOptions } from "@shared/constants/file-manager/file-upload-options";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent {

  processedFiles!: Observable<Array<FileUpload>>;

  showError = false;
  showDeleteError = false;
  showDeleteSuccess = false;
  showEmptyMessage = false;
  canClearFiles$: Observable<boolean> = of(false);
  errorMessage = "Unable to load the file list";
  deleteErrorMessage = "There was an error while deleting the file!";
  deleteSuccessMessage = "The file has been successfully deleted!";
  isFileLinkedToAgents: boolean = false;
  newNotificationMessageLabel = "Note: Uploads are now available in main chat. ";
  newNotificationMessage = "Click the paperclip icon to upload up to five files in .txt, .docx, or .pdf formats, or one file in .csv or .xlsx format. " +
    "Presentations, images, and videos are not supported. For details, please visit our Gen AI FAQs"

  fileUploadToBeDeleted?: FileUpload;

  protected readonly featureFlags = featureFlags;

  constructor(private filesService: FilesService,
              private kmdModalService: KmdModalService,
              private agentsService: AgentsService) {
    this.getProcessedFiles();
  }

  deleteFileUpload() {
    this.kmdModalService.close('delete-file-upload')
    this.filesService.delete(this.fileUploadToBeDeleted!).subscribe({
      next: () => {
        this.getProcessedFiles();
        this.showDeleteSuccess = true;
      },
      error: () => {
        this.showDeleteError = true;
      }
    });
  }

  cancelDeleteFileUpload() {
    this.kmdModalService.close('delete-file-upload')
  }

  confirmDelete(fileUpload: FileUpload) {
    this.fileUploadToBeDeleted = fileUpload
    this.agentsService.getAgentsLinkedToFile(fileUpload).subscribe(
      {
        'next': (agents) => {
          this.isFileLinkedToAgents = agents.length > 0;
        },
        'error': () => {
          this.showDeleteError = true;
        }
      }
    );

    this.kmdModalService.open('delete-file-upload')
  }

  getProcessedFiles() {
    this.processedFiles = this.filesService.list().pipe(
      map((processedFiles) => {
        return processedFiles
          .sort((fileOne: FileUpload, fileTwo: FileUpload) => {
            return fileTwo.updatedAt!.getTime() - fileOne.updatedAt!.getTime()
          })
      }),
      catchError((err) => {
        this.showError = true
        return throwError(err)
      })
    )
    if (this.processedFiles == null) {
      this.showEmptyMessage = true
    }
  }

  getDateForFile(file: FileUpload) {
    if (file.status != "PROCESSED") {
      return "---"
    }
    return formatDate(file.updatedAt!, 'MMM-dd-yyyy', 'en-US')
  }

  getFileStatus(status: string) {
    return (status == "UPLOAD_REQUESTED") ? "UPLOADING" : status
  }

  isDeleteEnabled(file: FileUpload) {
    return file.status === 'PROCESSED'
      || file.status === 'ERROR'
      || file.status === 'UPLOAD_REQUESTED'
      || (file.status === 'UPLOADED' && this.isSpreadsheet(file.name));
  }

  private isSpreadsheet(fileName: string) {
    const extensions = FileUploadOptions['SPREADSHEETS'].extensions;
    const fileExtension = '.' + fileName.split('.').pop()?.toLowerCase();
    return extensions.includes(fileExtension);
  }
  goToFAQS(): void {
    window.open('https://thermofisher.sharepoint.com/:u:/r/sites/GenerativeAICenterofExcellence/SitePages/Education%20%26%20resources/Generative-AI-FAQs.aspx?csf=1&web=1&e=ageFFd', '_blank');
  }
}
