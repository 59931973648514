<div [ngClass]="getExamplePromptClass()">
  <img id="gene-ai-logo" src="../../../assets/images/GeneAI-Logo.svg" alt="" height="271" [ngClass]="{'hide-icon': bannerEnabled}">
  <div *ngIf="showHeaderMessage" class="header-wrapper">
    <h2 class="greeting">Hello<span id="username">{{ userName }}</span> how can I assist you today?</h2>
    <p class="title">{{ titleText }}</p>
    <p class="disclaimer">
      Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information.
      Please <a [routerLink]="['/guidelines']">consult our guidelines</a>
      for additional information.
    </p>
  </div>
  <div class="example-wrapper">
    <ng-container *ngFor="let example of examplePrompts">
      <div class="example">
        <app-chip [text]="example.title" [closeable]="false"
                  [type]="ChipTypeConstant.EXAMPLE_PROMPT"
                  (click)="exampleSelected(example)"/>
      </div>
    </ng-container>
  </div>
</div>
