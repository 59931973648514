import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ResizeService } from "@services/resize/resize.service";
import { ModalService } from "@services/modal/modal.service";
import { Agent } from "@shared/models/agent";
import { MemberOfGroup } from "@shared/models/group-membership/member-of/member-of-group.model";
import { Member } from "@shared/models/group-membership/members/member.model";
import { FileUpload } from "@shared/models/file-upload.model";
import { FilesService } from "@services/files/files.service";
import { catchError, map, tap, throwError } from "rxjs";
import { AlertService } from "@services/alert/alert.service";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
    selector: 'app-agent-sharing-confirmation-modal',
    templateUrl: './agent-sharing-confirmation-modal.component.html',
    styleUrls: ['./agent-sharing-confirmation-modal.component.css']
})
export class AgentSharingConfirmationModalComponent implements OnInit, AfterViewInit {

    @Input() sharingDetails?: { agent: Agent, selectedFiles: FileUpload[] };
    @Input() group?: MemberOfGroup;
    @Input() admins?: Member[]
    @Input() isPublicShare: boolean = false;
    @Input() forceDisable: boolean = false;

    @Output() onEditAgentClickEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() onShareAgentClickEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSharePublicAgentClickEvent: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('modalContent') modalContent?: ElementRef;

    readonly id = 'agent-sharing-confirmation-modal';
    readonly headline = 'Agent Overview';
    readonly subHeadline = 'Ensure compliance with Agent guidelines; violating them may result in deletion of your agent.';
    
    readonly MODAL_PADDING = 150;

    creationGuidelinesCompliance = false;

    scrollableModal: boolean = false;

    modalHeight: number = 0;
    windowHeight: number = 0;

    agentOwner: string = '';

    fileNameSortState: 'asc' | 'desc' = 'asc';

    constructor(
        private modalService: ModalService,
        private resizeService: ResizeService,
        private filesService: FilesService,
        private alertService: AlertService,
        private oidcIdentityService: OidcSecurityService,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.fetchAgentOwnerName();
    };

    ngAfterViewInit(): void {
        this.resizeService.setObserver(this.modalContent!.nativeElement, (entry: any) => this.onModalResize(entry));
        this.resizeService.setObserver(document.body, (entry: any) => this.onWindowResize(entry));
    };

    onModalResize(element: any): any {
        const { height } = element.contentRect;
        this.modalHeight = height + this.MODAL_PADDING;

        if (!this.scrollableModal) {
            this.scrollableModal = (this.windowHeight - this.modalHeight) < 0;
            this.cdRef.detectChanges();
        }
    };

    fetchAgentOwnerName(): void {
        this.oidcIdentityService.userData$.pipe(
            map((info) => info.userData?.['name']),
            tap((name) => this.agentOwner = name)
        ).subscribe();
    };

    onWindowResize(element: any): any {
        const { height } = element.contentRect;
        this.windowHeight = height;
        this.scrollableModal = false;
        this.cdRef.detectChanges();
        this.closeModal();
    };

    openModal(): void {
        this.modalService.open(this.id);
        this.cdRef.detectChanges();
    };

    closeModal(): void {
        this.modalService.close(this.id);
    };

    shareAgent(): void {
        if (this.isPublicShare) {
            return this.onSharePublicAgentClickEvent.emit();
        }
        
        return this.onShareAgentClickEvent.emit();
    };

    onReturnToEditAgentClick(): void {
        this.onEditAgentClickEvent.emit();
        this.closeModal();
    };

    toggleCreationGuidelinesCompliance(): void {
        this.creationGuidelinesCompliance = !this.creationGuidelinesCompliance;
    };

    get isShareButtonDisabled(): boolean {
        if (this.forceDisable) {
            return true;
        }

        if (!this.isPublicShare) {
            return !this.creationGuidelinesCompliance;
        }

        return false;
    };

    get conversationStarter(): string {
        return this.sharingDetails?.agent?.conversationStarters?.join(', ').trim() || 'N/A';
    }

    get adminsLabel(): string {
        if (!this.admins || this.admins.length === 0) {
            return 'N/A';
        }

        return this.admins.map(admin => admin.mail).join(', ') || 'N/A';
    }

    showFile(fileId: string): void {
        this.filesService.get(fileId).pipe(
            catchError((error) => {
                this.alertService.showError('An error occurred while trying to open the file. Please try again later.');
                return throwError(() => error)
            }),
            tap((file: FileUpload) => window.open(file.url, '_blank'))
        ).subscribe();
    };

    sortByFileName(): void {
        this.fileNameSortState = this.fileNameSortState === 'asc' ? 'desc' : 'asc';
        this.sharingDetails?.selectedFiles.sort((a, b) => {
            if (this.fileNameSortState === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
    }
};
