  <div class="file-manager-drawer" [ngClass]="{'expanded': isExpanded}" >
    <div class="mobile-drawer-container" (click)="hideFMDrawer.emit()">
      <div class="mobile-drawer-handle"></div>
    </div>
    <div class="fm-drawer-title">
      <h2>Add files</h2>
        <p>Enhance your prompt's results by adding files. You can select multiple text files or a single spreadsheet.</p>
    </div>
    <ng-content></ng-content>
  </div>

