import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { featureFlags } from '@app/environments/environment';

import { ChipTypeConstant } from "@shared/constants/chip/chip-type";

@Component({
  selector: 'app-prompt-page',
  templateUrl: './prompt-page.component.html',
  styleUrls: ['./prompt-page.component.css']
})
export class PromptPageComponent implements OnInit, OnChanges{
  @Input() banner: boolean = true;
  @Input() showHeaderMessage: boolean = true;
  @Input() userName: string = '';
  @Input() repoDetails : any;
  @Output() exampleSelectEvent = new EventEmitter<any>();

  firstPart: string = '';
  highlightedPart: string = '';
  hyperLinkPart: string = '';
  lastPart: string = '';

  selectedChip: any = '';
  titleText : string = '';

  protected readonly featureFlags = featureFlags;
  protected readonly ChipTypeConstant = ChipTypeConstant;

  constructor(){}

  ngOnInit(): void {
    if (this.repoDetails?.custom_banner_text) {
      this.parseString(this.repoDetails.custom_banner_text);
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['repoDetails'] &&
      this.repoDetails &&
      Object.keys(this.repoDetails).length > 0 &&
      this.repoDetails.custom_banner_text) {
      this.parseString(this.repoDetails.custom_banner_text);
    }

    if(changes['repoDetails'] && this.repoDetails && this.repoDetails.subtitle){
      this.titleText = this.repoDetails.subtitle;
    }

    if(changes['userName']){
      this.userName = this.getUserName(this.userName);
    }
  }

  getUserName(name: string): string {
    return name ? ` ${name.split(' ')[1]},` : ',';
  }

  parseString(input: string | undefined) {
    // Adding null/undefined check
    if (!input) {
      // To Set default values when input is undefined or empty
      this.firstPart = '';
      this.highlightedPart = '';
      this.hyperLinkPart = '';
      this.lastPart = '';
      return;
    }

    try {
      // To Split the string by the delimiter '#%#'
      const parts = input.split('#%#');

      if (parts.length < 3) {
        // To Handle invalid format
        this.firstPart = input;
        this.highlightedPart = '';
        this.hyperLinkPart = '';
        this.lastPart = '';
        return;
      }

      // To Split the middle part by '|' to separate text and URL
      const middleParts = parts[1].split('|');

      if (middleParts.length < 2) {
        // To Handle invalid middle part format
        this.firstPart = parts[0];
        this.highlightedPart = parts[1];
        this.hyperLinkPart = '';
        this.lastPart = parts[2];
        return;
      }

      this.firstPart = parts[0];
      this.highlightedPart = middleParts[0];
      this.hyperLinkPart = middleParts[1];
      this.lastPart = parts[2];
    } catch (error) {
      // To Handle any errors by setting default values
      console.error('Error parsing string:', error);
      this.firstPart = '';
      this.highlightedPart = '';
      this.hyperLinkPart = '';
      this.lastPart = '';
    }
  }

  getExamplePromptClass() {
    const bannerClass = this.banner ? 'with-banner' : 'with-no-banner';
    const revampClass = 'revamp-width';
    return `example-prompt example-prompt-${bannerClass} example-prompt-${revampClass}`;
  }

  exampleSelected(prompt: any) {
    this.selectedChip = prompt;
    if (this.repoDetails?.tile_prompts) {
    this.exampleSelectEvent.emit(prompt.longPrompt);
    } else {
      this.exampleSelectEvent.emit(prompt);
    }
    setTimeout(() => {
      this.selectedChip = null;
    }, 100);
  }
}
