<div #scrollDiv class="content-agents-list">
  <div class="agent-list">
    <div class="agents-page-bar">
      <div class="agent-list-toolbar-content">
        <div class="agent-title-section">
          <div class="agents-list-title">
            <h1>Agents</h1>
            <core-badge class="beta-badge" variant="standard" size="regular" color="green" label="Beta"/>
          </div>
          <div class="toolbar-buttons">
            <button kmdFeaturedButton id="new-agent-top" class="agent-btn" (click)="goToCreateNewAgent()">
              <img class="icon-agents icon-sm-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">
              Create New Agent
            </button>
          </div>
        </div>
        <div class="agent-description-section">
          <span>
            <p>Agents are digital assistants that can enhance your work efficiency by executing specific tasks or
              responding to your questions.</p>
            <p>Check out our <a href="/guidelines" target="_blank">Agent Sharing Guidelines</a> for best practices.</p>
          </span>
        </div>
      </div>
    </div>
    <div class="agents-list-section">
      <core-tabs-container id="agents-tab-container" position="columns">
        <core-tabs id="agents-tabs" variant="default">
          <core-tabs-item label="My Agents"
                          id="my-agents-tab-item"
                          variant="default"
                          targetid="my-agents-tab"
                          [selected]="myAgentsTabSelected"
                          (tabSelected)="handleTabChange(tabChangeEvents.MY_AGENTS)"/>
          <core-tabs-item label="Public Agents"
                          id="public-agents-tab-item"
                          variant="default"
                          targetid="public-agents-tab"
                          [selected]="publicAgentTabSelected"
                          (tabSelected)="handleTabChange(tabChangeEvents.PUBLIC_AGENTS)"/>
          <core-tabs-item label="Shared With Me"
                          id="shared-agents-tab-item"
                          variant="default"
                          targetid="shared-agents-tab"
                          [selected]="sharedAgentsTabSelected"
                          (tabSelected)="handleTabChange(tabChangeEvents.SHARED_AGENTS)"/>
        </core-tabs>
        <core-tabs-content>
          <div id="my-agents-tab">
            <ng-container *ngTemplateOutlet="myAgentsTabView"/>
          </div>
          <div id="public-agents-tab">
            <ng-container *ngTemplateOutlet="publicAgentsTabView"/>
          </div>
          <div id="shared-agents-tab">
            <ng-container *ngTemplateOutlet="sharedAgentsTabView"></ng-container>
          </div>
        </core-tabs-content>
      </core-tabs-container>
    </div>
  </div>
</div>

<ng-template #myAgentsTabView>
  <app-agent-list-paged #privateAgents
                        [type]="'private'"
                        [userData$]="userData$"
                        [isUserApprover]="isUserApprover"
                        [scrollDiv]="scrollDiv"/>
</ng-template>

<ng-template #publicAgentsTabView>
  <app-agent-list-paged #publicAgents
                        [type]="'public'"
                        [userData$]="userData$"
                        [isUserApprover]="isUserApprover"
                        [scrollDiv]="scrollDiv"/>
</ng-template>

<ng-template #sharedAgentsTabView>
  <app-agent-list-paged #sharedAgents
                        [type]="'shared'"
                        [userData$]="userData$"
                        [isUserApprover]="isUserApprover"
                        [scrollDiv]="scrollDiv"/>
</ng-template>

