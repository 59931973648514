import { Injectable } from "@angular/core";
import { FilesService } from "../files/files.service";
import {
  BehaviorSubject,
  catchError,
  delay,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import { formatDate } from "@angular/common";
import { FileUpload } from "@shared/models/file-upload.model";
import { createFileManagerDoc, FileManagerDoc } from "@shared/models/file-manager/file-manager-doc.model";
import { S3UploadService } from "../files/s3-upload.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { FileManagerUpload } from "@shared/models/file-manager/file-manager-upload.model";
import { FileManagerUploadStatus } from "@shared/models/file-manager/file-manager-upload-status.model";
import { FileUploadStatus } from "@shared/constants/file-manager/file-upload-status";
import { FileTypes, FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { SpreadsheetsService } from "../files/spreadsheets.service";
import { SpreadsheetAnalysisUsageConsent } from "@app/shared/constants/file-manager/spreadsheet-analysis-usage-consent";

export enum FileNameFormatOption {
  WITH_EXTENSION = 'WITH_EXTENSION',
  WITHOUT_EXTENSION = 'WITHOUT_EXTENSION'
}

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  private readonly CSV_MAX_FILE_SIZE = 80000000;
  private readonly EXCEL_MAX_FILE_SIZE = 15000000;
  private readonly supportedTextFileTypes = [
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ];
  private readonly PDF_UPLOAD_TYPE = 'pdf';
  private readonly DOCX_UPLOAD_TYPE = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
  private readonly XLSX_UPLOAD_TYPE = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  private readonly supportedSpreadsheetsFileTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  selectedFileType$ = new BehaviorSubject<FileUploadType>(FileUploadOptions['TEXT']);
  allFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);
  selectedFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);

  readonly fileStatusIds = {
    processed: new Array<string>(),
    error: new Array<string>()
  };

  private _isSpreadsheetUsageAcknowledged: boolean | undefined;

  constructor(
    private filesService: FilesService,
    private spreadsheetsService: SpreadsheetsService,
    private s3UploadService: S3UploadService
  ) {
    this.isSpreadsheetUsageAcknowledged()
      .pipe(
        take(1),
        tap((isAcknowledged) => this._isSpreadsheetUsageAcknowledged = isAcknowledged),
      ).subscribe({
      error: () => {
      }
    });
  };

  setSelectedFileType(fileType: FileUploadType): void {
    this.selectedFileType$.next(fileType);
  }

  private hasBeenProcessingForMoreThanOneHour(file: FileUpload, fileManagerDoc: FileManagerDoc) {
    return fileManagerDoc.status === 'PROCESSING' && file.updatedAt && this.getDifferenceInHours(file.updatedAt) >= 1
  }

  getLazyFiles$(fileType: FileUploadType, offset: number = 0, sortBy: string = "processed",
                sortDirection: string = "DESC", searchTerm: string = ''): Observable<FileManagerDoc[]> {
    if (this.selectedFileType$.value !== fileType && offset === 0) {
      this.allFiles$.next([]);
    }

    this.setSelectedFileType(fileType);

    return this.filesService.lazyList(fileType.extensions.join(','), offset, sortBy, sortDirection, searchTerm)
      .pipe(
        map((files) => {
          return files.map((file) => {
            const parsedFile = this.fileUploadToFileManagerDoc(file, 60);

            if (parsedFile.status === 'ERROR') {
              this.addToFileStatusError(parsedFile);
            } else if (parsedFile.status === 'PROCESSED') {
              this.updateProcessedFileStatus(parsedFile);
            } else if (this.hasBeenProcessingForMoreThanOneHour(file, parsedFile)) {
              this.addToFileStatusError(parsedFile);
            } else {
              this.fetchProcessingStatus(parsedFile.id!, fileType);
            }

            return parsedFile;
          });
        }),
        tap((files) => {
          if (offset === 0) {
            this.allFiles$.next(files);
          } else {
            this.allFiles$.next(this.allFiles$.value.concat(files));
          }
        })
      );
  };

  getFiles$(fileType: FileUploadType, sortBy: string = "processed", sortDirection: string = "DESC", excludeStatus = ""): Observable<FileManagerDoc[]> {
    return this.filesService.list(fileType.extensions.join(','), sortBy, sortDirection, excludeStatus).pipe(
      map((files) => {
        return files.map((file) => {
          const parsedFile = this.fileUploadToFileManagerDoc(file, 65);

          if (parsedFile.status === 'ERROR') {
            this.addToFileStatusError(parsedFile);
          } else if (parsedFile.status === 'PROCESSED') {
            this.updateProcessedFileStatus(parsedFile);
          } else {
            this.fetchProcessingStatus(parsedFile.id!, fileType);
          }

          return parsedFile;
        });
      }),
      tap((files) => this.allFiles$.next(files))
    );
  }

  getFiles(fileIds: string[]): Observable<FileManagerDoc[]> {
    if (!fileIds.length) {
      return of([]);
    }

    const fileObservables = fileIds.map((id) => {
      return this.filesService.get(id).pipe(
        map((file) => {
          const parsedFile = this.fileUploadToFileManagerDoc(file, 65);
          if (parsedFile.status === 'ERROR') {
            this.addToFileStatusError(parsedFile);
          } else if (parsedFile.status === 'PROCESSED') {
            this.updateProcessedFileStatus(parsedFile);
          } else {
            this.fetchProcessingStatus(parsedFile.id!, this.getCategoryForFileType(file.fileType!)!);
          }
          return parsedFile;
        }),
        catchError(() => {
          console.error(`Error fetching file with ID ${id}`);
          return of(null);
        })
      );
    });

    return forkJoin(fileObservables).pipe(
      map((files) => files.filter((file): file is FileManagerDoc => file !== null)),
      tap((files) => this.setSelectedFiles(files))
    );
  }

  validateFileUploadBatch(files: FileList, fileType: string): any {
    let validationResponse = this.getSupportedFilesValidationResponse(fileType, files);
    this.validateMaxFileSizeForSpreadsheets(fileType, files, validationResponse);
    return validationResponse;
  };

  private getSupportedFilesValidationResponse(fileType: string, files: FileList) {
    const supportedFileTypes = fileType === 'text'
      ? this.supportedTextFileTypes : this.supportedSpreadsheetsFileTypes;
    const validationResponse = {
      isValid: true,
      unsupportedFileType: false,
      maxFileSizeExceeded: false
    };

    for (let i = 0; i < files.length; i++) {
      if (!supportedFileTypes.includes(files.item(i)!.type)) {
        validationResponse.isValid = false;
        validationResponse.unsupportedFileType = true;
      }
    }
    return validationResponse;
  }

  private validateMaxFileSizeForSpreadsheets(fileType: string, files: FileList, validationResponse: {
    isValid: boolean;
    unsupportedFileType: boolean
    maxFileSizeExceeded: boolean;
  }) {

    if (fileType === 'spreadsheets' && this.fileLimitExceeded(files.item(0)!)) {
      validationResponse.isValid = false;
      validationResponse.unsupportedFileType = false;
      validationResponse.maxFileSizeExceeded = true;
    }
  }

  validFileName(fileName: string): boolean {
    if (!fileName) {
      return false;
    }
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return nameWithoutExtension.trim().length > 0;
  }

  uploadFiles(files: File[], uploadType: FileUploadType): FileManagerUpload[] {
    const fileUploads: FileManagerUpload[] = [];

    for (let file of files) {
      const fileUpload = new FileUpload(undefined, file.name, undefined, undefined, undefined, file.size);

      let id: string;
      const upload = this.filesService.create(fileUpload)
        .pipe(
          tap((uploadedFile: FileUpload) => id = uploadedFile.id!),
          mergeMap((uploadedFile: FileUpload) => this.s3UploadService.uploadFile(file, uploadedFile.url!)),
          mergeMap((event: HttpEvent<any>) => this.mapProgress(id, event, uploadType))
        );

      fileUploads.push({ file, upload });
    }

    return fileUploads;
  };

  getFileUploadStatus(data: FileManagerUpload): FileManagerUpload {
    const cancel$: Subject<boolean> = new Subject<boolean>();

    const upload: FileManagerUpload = {
      file: data.file,
      status: FileUploadStatus.IN_PROGRESS,
      progress: 0,
      cancel: () => cancel$.next(true),
      fetch: () => data.upload!.pipe(
        takeUntil(cancel$),
        catchError((err) => {
          upload.status = FileUploadStatus.ERROR;
          upload.progress = 0;
          return throwError(() => err);
        }),
        tap((uploadStatus) => {
          upload.id = uploadStatus.id!;
          upload.status = uploadStatus.status;
          upload.progress = uploadStatus.progress;
        })
      ).subscribe()
    };
    upload.fetch!();
    return upload;
  };

  getReadableFileSize(size?: number): string {
    if (!size) {
      return "N/A"
    }

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;

    while (size! >= 1000 && index < units.length - 1) {
      size! /= 1000;
      index++;
    }

    return `${size!.toFixed(2)}${units[index]}`;
  };

  private mapProgress(id: string, event: HttpEvent<any>, uploadType: FileUploadType): Observable<FileManagerUploadStatus> {
    if (event.type === HttpEventType.Response) {
      return of({
        id,
        status: FileUploadStatus.COMPLETED,
        progress: 100
      });
    }

    if (event.type === HttpEventType.UploadProgress) {
      return of({
        id,
        status: FileUploadStatus.IN_PROGRESS,
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0
      });
    }

    this.fetchProcessingStatus(id, uploadType);
    return of({
      id,
      status: FileUploadStatus.IN_PROGRESS,
      progress: 0
    });
  };

  private fetchProcessingStatus(id: string, uploadType: FileUploadType): void {
    if (uploadType === FileUploadOptions['SPREADSHEETS']) {
      this.fileStatusIds.processed.push(id);
      return;
    }

    this.filesService.get(id)
      .pipe(
        mergeMap((file) => of(file).pipe(
          delay(3000),
          catchError((err) => of({ ...err, status: 'ERROR' }))
        )),
        tap((file: FileUpload) => {
          switch (file.status) {
            case 'PROCESSED':
              this.fileStatusIds.processed.push(id);
              break;
            case 'ERROR':
              this.fileStatusIds.error.push(id);
              break;
            default:
              this.fetchProcessingStatus(id, uploadType);
              break;
          }
        })
      ).subscribe();
  };

  setSelectedFiles(files: FileManagerDoc[]): void {
    files.forEach((file) => {
      if (!this.fileStatusIds.error.includes(file.id!)) {
        this.fileStatusIds.processed.push(file.id!);
      }
    });
    this.selectedFiles$.next(files);
  };

  addSelectedFile(file: FileManagerDoc): void {
    const selectedFiles = this.selectedFiles$.value;
    if (selectedFiles.some((f) => f.id === file.id)) {
      return;
    }
    selectedFiles.push(file);
    this.selectedFiles$.next(selectedFiles);
    this.updateAllFiles(file);
  };

  private updateAllFiles(file: FileManagerDoc) {
    const allFiles = this.allFiles$.value;
    if (allFiles.some((f) => f.id === file.id)) {
      const fileIndex = allFiles.findIndex((f) => f.id === file.id);
      allFiles[fileIndex].isSelected = true;
    } else {
      allFiles.unshift(file);
    }
    this.allFiles$.next(allFiles);
  }

  removeSelectedFile(file: FileManagerDoc): void {
    const files = this.selectedFiles$.value.filter((f) => f.id !== file.id);
    this.selectedFiles$.next(files);
  };

  reset(): void {
    this.setSelectedFileType(FileUploadOptions['TEXT']);
    this.clearSelectedFiles();
  }

  clearSelectedFiles(): void {
    this.selectedFiles$.next([]);
  };

  private updateProcessedFileStatus(file: FileManagerDoc): void {
    if (!this.fileStatusIds.processed.includes(file.id!)) {
      this.fileStatusIds.processed.push(file.id!);
    }
  };

  private addToFileStatusError(file: FileManagerDoc): void {
    if (!this.fileStatusIds.error.includes(file.id!)) {
      this.fileStatusIds.error.push(file.id!);
    }
  };

  private parseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
    const fileNameParts = name.split('.');
    const extension = fileNameParts.length > 1 ? fileNameParts.pop() : '';
    const fileNameWithoutExtension = fileNameParts.join('.');

    if (formatOption === FileNameFormatOption.WITHOUT_EXTENSION) {
      name = fileNameWithoutExtension;
    }

    if (name.length <= maxFileNameLength) {
      return name;
    }

    const firstPart = name.substring(0, maxFileNameLength - 10);
    const last3Chars = fileNameWithoutExtension.substring(fileNameWithoutExtension.length - 3);

    return formatOption === FileNameFormatOption.WITH_EXTENSION
      ? `${firstPart}(...)${last3Chars}.${extension}`
      : `${firstPart}(...)${last3Chars}`;
  };

  getParseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
    return this.parseFileNameToDisplayName(name, maxFileNameLength, formatOption);
  }

  private parseShortFileDisplayName(name: string): string {
    const maxFileNameLength = 15;
    if (name.length <= maxFileNameLength) {
      return name;
    }

    const fileNameParts = name.split('.');
    const extension = fileNameParts[fileNameParts.length - 1];
    return `${fileNameParts[0].substring(0, maxFileNameLength - extension.length)}...${extension}`;
  };

  convertUploadToFileManagerDoc(upload: FileManagerUpload): FileManagerDoc {
    const file = {
      id: upload.id!,
      name: upload.file.name,
      displayName: this.parseFileNameToDisplayName(upload.file.name, 50),
      miniDisplayName: this.parseFileNameToDisplayName(upload.file.name, 30),
      shortDisplayName: this.parseShortFileDisplayName(upload.file.name),
      size: this.getReadableFileSize(upload.file.size),
      date: formatDate(new Date(), 'MMM-dd-yyyy', 'en-US'),
      isSelected: true,
      status: FileUploadStatus.IN_PROGRESS,
      isSelectDisabled: false
    };

    this.addSelectedFile(file);
    return file;
  }

  isSpreadsheetUsageAcknowledged(): Observable<boolean> {
    if (this._isSpreadsheetUsageAcknowledged !== undefined) {
      return of(this._isSpreadsheetUsageAcknowledged);
    }

    return this.spreadsheetsService.getUsageAcknowledgement()
      .pipe(
        map((usage) => usage.state === SpreadsheetAnalysisUsageConsent.ACKNOWLEDGED)
      );
  };

  acknowledgeSpreadsheetUsage(): Observable<void> {
    return this.spreadsheetsService.acknowledgeUsage({ state: SpreadsheetAnalysisUsageConsent.ACKNOWLEDGED })
      .pipe(
        tap(() => this._isSpreadsheetUsageAcknowledged = true)
      );
  };

  fileLimitExceeded(file: File): boolean {
    switch (file.type) {
      case 'text/csv':
        return file.size > this.CSV_MAX_FILE_SIZE;
      case 'application/vnd.ms-excel':
        return file.size > this.EXCEL_MAX_FILE_SIZE;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return file.size > this.EXCEL_MAX_FILE_SIZE;
      default:
        return false;
    }
  }

  fileUploadToFileManagerDoc(file: FileUpload, maxFileNameLength: number): FileManagerDoc {
    return createFileManagerDoc(
      file,
      maxFileNameLength,
      this.parseFileNameToDisplayName.bind(this),
      this.parseShortFileDisplayName.bind(this),
      this.getReadableFileSize.bind(this)
    );
  }

  getCategoryForFileType(fileType: string): FileUploadType {
    if (!fileType)
      throw new Error('Invalid file type');

    for (const category in FileTypes) {
      if (Object.values(FileTypes[category]).includes(fileType.toUpperCase())) {
        return FileUploadOptions[category];
      }
    }

    throw new Error(`File type ${fileType} not found`);
  }

  getDifferenceInHours(date: Date): number {
    const currentDate = new Date();
    return Math.abs(Math.round((currentDate.getTime() - date.getTime()) / 36e5));
  }

  async isFilePasswordProtected(file: File): Promise<boolean> {
    const fileType = file.type.split('/')[1];
    switch (fileType) {
      case this.PDF_UPLOAD_TYPE:
      case this.DOCX_UPLOAD_TYPE:
      case this.XLSX_UPLOAD_TYPE:
        return  await this.checkFileForEncryption(file, fileType);
      default:
        return true;
    }
  }

  async checkFileForEncryption(file: File, type: string): Promise<boolean> {
    try {
      const reader = new FileReader();
      const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });

      const blob = new Blob([arrayBuffer], { type });
        const text = await blob.text();
          return text.includes("Encrypt");

    } catch (error) {
      console.error('Error reading uploaded file:', error);
      return false;
    }
  }
}
