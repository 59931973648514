<div class="wrapper" [ngClass]="contentPosition">
  <div class="saved-prompts" *ngIf="savedPrompts; else chatWrapper">
    <app-saved-prompts
    (promptSelectEvent)="promptSelected($event)"
    (backToChatEvent)="showChat()"
    ></app-saved-prompts>
  </div>
</div>
<ng-template #chatWrapper>
  <app-prompt-settings-new (savedPromptsSelectedEvent)="showSavedPrompts()" [settings]="promptSettings"></app-prompt-settings-new>
  <ng-content></ng-content>
  <prompt-input-new
                    [settings]="promptSettings"
                    [isStreamingResponse]="isStreamingResponse"
                    (promptSettingsButtonClick)="openPromptSettings()"
                    (onSubmit)="onPromptSubmit.emit($event)"
                    [addFileButtonVisibility]="addFileButtonVisibility"
                    [modelChangeButtonVisibility]="modelChangeButtonVisibility"
                    [promptSettingsButtonVisibility]="promptSettingsButtonVisibility"
                    (stopStreaming)="stopStreaming.emit($event)"></prompt-input-new>
  </ng-template>
