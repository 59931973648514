import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export interface HasUnsavedChanges {
  hasUnsavedChanges(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard  {
  private skipGuard = false;

  public setSkipGuard(value: boolean): void {
    this.skipGuard = value;
  };

  canDeactivate(component: HasUnsavedChanges,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                currentRoute: ActivatedRouteSnapshot,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                currentState: RouterStateSnapshot,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                nextState: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.skipGuard) {
      this.skipGuard = false;
      return true;
    }

    if (component.hasUnsavedChanges()) {
      return confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    return true;
  }
}
