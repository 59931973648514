<div class="landing-feedback-container">
  <div class="landing-feedback-header">
    <div class="feedback-header-section-one">
      <div class="landing-feedback-header-title"><h2>Feedback form</h2></div>
      <div class="landing-feedback-title-description">
        A feedback form helps you gather information to analyze and improve AI
        responses.
      </div>
    </div>
    <div class="feedback-header-section-two">
      <button class="customization-button" (click)="redirectToFeedback()">Customize form</button>
    </div>
  </div>
  <div class="landing-feedback-info-container">
    <div class="info-icon">
      <!-- icon -->
      <svg
        id="info-mono.svg"
        width="16"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4C16.41 4 20 7.58998 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.58998 7.59 4 12 4ZM12 2C6.48 2 2 6.47998 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.47998 17.52 2 12 2Z"
          fill="#1B1B1D"
        />
        <path d="M13 10.5H11V17.5H13V10.5Z" fill="#1B1B1D" />
        <path d="M13 6.5H11V8.5H13V6.5Z" fill="#1B1B1D" />
      </svg>
    </div>
    <div class="info-description">
      Contact the Gen.AI general inbox to request permission from the
      administrator to access the database where all user answers are stored.
    </div>
  </div>
  <div class="landing-feedback-form-preview">
    <div class="preview-important-one">
      <span class="important-text">Important:</span>This is a preview. You
      cannot enter any information.
    </div>
    <div class="preview-feedback-form">
      <app-feedback-form [indexName]="indexName" class="non-clickable" [hideButtons]="true" [showMCQ]="true"></app-feedback-form>
    </div>
  </div>
</div>
