import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { ChatQAService } from '@app/core/services/chat-qa/chat-qa.service';
import { ChatWrapperComponent } from '@app/shared/components/chat-wrapper/chat-wrapper.component';
import { ContextMenuDropdownComponent } from '@app/shared/components/context-menu-dropdown/context-menu-dropdown.component';
import { modelList } from '@app/shared/components/prompt-settings/prompt-settings.data';
import { ContextMenuItem } from '@app/shared/models/context-menu-item';
import { RepoChatDropdownMenuLabels } from '@app/shared/models/repo-chat-dropdown-menu.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { KmdAccordionComponent, KmdModalService, KmdPopoverComponent } from 'gds-atom-components';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Conversation, ChatMessage } from '@app/shared/models/ask-our-docs-v2.model';
import { DrawerComponent } from '@app/drawer/drawer.component';
import { featureFlags } from "@app/environments/environment";
import { AskOurDocsV2ChatHistoryComponent } from '../modals/ask-our-docs-v2-chat-history/ask-our-docs-v2-chat-history.component';

@Component({
  selector: 'app-ask-our-docs-chat-page',
  templateUrl: './ask-our-docs-chat-page.component.html',
  styleUrls: ['./ask-our-docs-chat-page.component.css', '../../chat/chat.component.markdown.css']
})
export class AskOurDocsChatPageComponent implements OnInit  {
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any> | undefined;
  @ViewChild('headerContainer', { static: true }) headerContainer: ElementRef | undefined;
  @ViewChild('accordion', { static: false }) accordion!: KmdAccordionComponent;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;

  @ViewChild(ChatWrapperComponent) chatWrapperComponent!: ChatWrapperComponent;
  @ViewChild('modelContextMenu') modelDropdown!: ContextMenuDropdownComponent;
  @ViewChild('popOver1') popover!: KmdPopoverComponent;
  @ViewChild('chatHistoryComponent') chatHistoryComponent! : AskOurDocsV2ChatHistoryComponent
  showModelDropdown: boolean = false;
  modelList: ContextMenuItem[] = [];
  aodUrl: string = 'ask-our-docs-version2/';
  id: string = '';
  isUserAdmin: boolean = false;
  repositoryGroup: string = '';
  repoDetails: any = {};
  userName: string = '';
  userEmail: string = '';
  repositoryName: string = '';
  enableCitationDownload: boolean = true;
  repoOwner: string = 'No admin details available';
  internal_index_name: string = '';
  isChatInitiated: boolean = false;
  currentCitations: any = null;
  isExpanded = false;
  expandedIndex: number | null = null;
  last_run_date: string = 'No last run date available';
  repositorydetails: string = 'No information available';
  internalIndexName: string ="";
  linkrequired: boolean = false;
  repositorylink: string = 'No repository link available';
  answerHelpful: boolean = false;
  selectedFeedback: string | null = null;
  position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'bottom';
  visible: boolean = false;
  isAppDrawerOpen = false;
  enable_tile: boolean = true;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private chatQAService: ChatQAService,
    private router: Router,
    private askOurDocsV2Service: AskOurDocsV2Service,
    private route: ActivatedRoute,
    private clipboard: ClipboardService,
    private sanitizer: DomSanitizer,
    private kmdModalService: KmdModalService
  ) {
    this.specificText$.subscribe(value => {
      this.specificText = value;
    });
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      const state = navigation.extras.state as {
        id: string;
        isUserAdmin: boolean;
        repositoryGroup: string;
        repositoryName: string;
      };
      this.id = state.id;
      this.isUserAdmin = state.isUserAdmin;
      this.repositoryGroup = state.repositoryGroup;
      this.repositoryName = state.repositoryName;
    }
  }

  showFeedbackForm(assistantPromptValue: ChatMessage, answerHelpful: boolean): void {
    assistantPromptValue.showFeedbackForm = true;
    assistantPromptValue.selectedFeedback = answerHelpful ? 'useful' : 'notUseful';
    this.answerHelpful = answerHelpful;
  }

  closeFeedbackForm(assistantPromptValue: ChatMessage): void {
    assistantPromptValue.showFeedbackForm = false;
  }


  ngOnInit(): void {

    this.askOurDocsV2Service.getDetails(this.id).then(response => {
      this.repoDetails = response;
      this.enable_tile = this.repoDetails.enable_tile;
      this.enableCitationDownload = this.repoDetails.enable_citation_download;
      this.repoOwner = this.repoDetails.repo_admin;
      this.internal_index_name = this.repoDetails.internal_index_name;
      this.repositorydetails = this.repoDetails.repo_description;
      this.linkrequired = this.repoDetails.enable_repository_link;
      this.repositorylink = this.repoDetails.repository_link;
      this.internalIndexName = this.repoDetails.internal_index_name;
      return this.askOurDocsV2Service.getLastRunDate(this.internal_index_name + '-indexer');
    }).then((response : any) => {
      this.last_run_date = response.last_run_date;
      this.modelList.forEach(item => {
        if (item.id === "3") {
          item.description = this.last_run_date;
        }
      });
    }).catch(e => {
      console.log(e);
    });

    this.oidcSecurityService.userData$.subscribe((result) => {
      this.userName = result.userData.name;
      this.userEmail = result.userData.email;
    });

    this.modelList = modelList.map(model => {
      return {
        id: model.model,
        label: model.model,
        description: model.description
      }
    })
    this.modelList = [
      {
        id: "0",
        label: "New Chat",
        image_url: "../../../../assets/icons/chat/chat-mono.svg"
      },
      {
        id: "2",
        label: "History",
        description: "See all chat history with the repository",
        image_url: "../../../../assets/icons/clock/clock-24px-mono-new.svg"
      },
      {
        id: "3",
        label: "Source updated:",
        description: `${this.last_run_date}`,
        image_url: "../../../../assets/icons/ask-our-docs/calendar-mono.svg"
      },
      {
        id: "4",
        label: "About repository",
        description: "",
        image_url: "../../../../assets/icons/info/info-mono.svg"
      }
    ]

    if (this.isUserAdmin) {
      this.modelList.push({
        id: "5",
        label: "Repository settings",
        description: "Edit your configuration, manage users",
        image_url: "../../../../assets/icons/ask-our-docs/settings-mono.svg"
      })
    }

    if(featureFlags.savePrompt === true){
      this.modelList.push({
        id: "1",
        label: "Saved prompts",
        description: "See prompt suggestions created by the admin(s).",
        image_url: "../../../../assets/icons/ask-our-docs/save-mono.svg"
      });
    }
    this.modelList.sort((a:any,b:any)=> {return a.id - b.id})
  }


  setPrompt(prompt: any) {
    this.chatWrapperComponent.setPromptText(prompt);
  }

  onTabOpen() {
    this.isExpanded = true;
  }

  onTabClose() {
    this.isExpanded = false;
  }

  responseMessage$ = new BehaviorSubject<string>(''); // To track the first response message
  citations$ = new BehaviorSubject<any[]>([]); // To track citations
  intent$ = new BehaviorSubject<string>(''); // To track intent
  specificText$ = new BehaviorSubject<string>(''); // To track specific text
  referenceCount$ = new BehaviorSubject<number>(0); // To track reference count
  referenceLinks$ = new BehaviorSubject<string[]>([]); // To track reference links
  isFirstChunk = true; // To track if it's the first chunk
  specificText: string = ''; // Local variable to bind to template
  userPrompt: string = ''; // To Store user prompt
  // To track conversarion
  conversation: Conversation = {
    id: uuidv4(),
    messages: [],
    date: ''
  };

  onPromptSubmit(promptEvent: any) {
    this.userPrompt = promptEvent.prompt;
    this.isFirstChunk = true; // Reset for new prompt
    this.responseMessage$.next(''); // Reset response message
    this.citations$.next([]); // Reset citations
    this.intent$.next(''); // Reset intent
    this.referenceCount$.next(0); // Reset reference count
    this.referenceLinks$.next([]); // Reset reference links
    this.specificText$.next('')

    const now = new Date();
    this.conversation.messages.push({
      "id": uuidv4(),
      "role": "user",
      "content": promptEvent.prompt,
      "date": now.toISOString()
    });
    this.conversation.messages.push({
      "id": '',
      "role": "assistant",
      "content": "",
      "date": now.toISOString(),
      "showFeedbackForm": false
    });
    const transformedArray = this.conversation.messages.map(item => {
      if (item.role === 'assistant') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { citations, intent, showFeedbackForm, ...rest } = item;
        return rest;
      }
      return item;
    });

    const conversationApiPayload = {
      "messages": transformedArray,
      "index_name": this.id
    };
    this.chatWrapperComponent.promptInputNewComponent.responseGeneratedFlag = true;
    this.askOurDocsV2Service.conversationApi(conversationApiPayload).then(async response => {
      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          buffer += decoder.decode(value || new Uint8Array(), { stream: !done });
          buffer = this.processChunks(buffer);
        }
        if (done) {
          this.conversation.messages.push({
            "id": '',
            "role": "tool",
            "content": JSON.stringify({
              citations: this.citations$.value,
              intent: this.intent$.value
            }),
            "date": now.toISOString()
          });
          this.conversation.messages[this.conversation.messages.length - 2] = {
            "id": '',
            "role": "assistant",
            "content": this.specificText,
            "date": now.toISOString(),
            "citations": this.citations$.value,
            "intent": this.intent$.value
          };
          const data = {
            "conversation": this.conversation.messages,
            "conversationId": this.conversation.id,
            "indexName": this.repoDetails.internal_index_name,
            "promptCount": 1,
            "title": this.conversation.messages[0].content.slice(0,20)
          };
          this.askOurDocsV2Service.saveToDB(data);
        }
        this.chatWrapperComponent.promptInputNewComponent.responseGeneratedFlag = false;
      } else {
        console.error('Response body is null');
      }
    }).catch(error => {
      console.error('Error in conversation API call:', error);
    });
    this.isChatInitiated = true;
    this.chatWrapperComponent.setPromptText('');
  }

  processChunks(buffer: string): string {
    let boundary = buffer.indexOf('\n');
    while (boundary !== -1) {
      const chunk = buffer.slice(0, boundary).trim();
      buffer = buffer.slice(boundary + 1);
      if (chunk) {
        try {
          const parsedChunk = JSON.parse(chunk);

          if (this.isFirstChunk) {
            this.isFirstChunk = false;
            parsedChunk.choices?.forEach((choice: any) => {
              choice.messages?.forEach((message: any) => {
                if (message.role === 'tool') {
                  const toolContent = JSON.parse(message.content);
                  if (toolContent.citations) {
                    this.citations$.next(toolContent.citations);
                    this.referenceCount$.next(toolContent.citations.length);
                    this.referenceLinks$.next(toolContent.citations.map((citation: any) => citation.filepath || ''));
                  }
                  if (toolContent.intent) {
                    this.intent$.next(toolContent.intent);
                  }
                } else if (message.role === 'assistant') {
                  this.responseMessage$.next(message.content);
                }
              });
            });
          } else {
            parsedChunk.choices?.forEach((choice: any) => {
              choice.messages?.forEach((message: any) => {
                if (message.content) {
                  let currentSpecificText = this.specificText$.getValue();
                  currentSpecificText += message.content;
                  this.specificText$.next(currentSpecificText);
                }
              });
            });
          }
        } catch (e) {
          console.error('Failed to parse chunk:', chunk, e);
        }
      }
      boundary = buffer.indexOf('\n');
    }
    return buffer;
  }

  copyAssistantPrompt(message: string) {
    this.clipboard.copyFromContent(message);
  };
  copyUserPrompt(message: string) {
    this.clipboard.copyFromContent(message);
  }

  repoDropdownTrigger() {
    this.showModelDropdown = !this.showModelDropdown;
    this.modelDropdown.toggleMenu();
  }

  isContextMenuOpen(isOpen: any) {
    this.showModelDropdown = isOpen;
  }

  closeDropdownMenu() {
    if (this.showModelDropdown) {
      this.showModelDropdown = !this.showModelDropdown;
      this.modelDropdown.toggleMenu();
    }
  }

  menuItemSelected(menuItem: any) {
    switch (menuItem.label) {
      case RepoChatDropdownMenuLabels.NEW_CHAT:
        this.startNewChat();
        break;

      case RepoChatDropdownMenuLabels.SAVED_PROMPTS:
        // this.showSavedPrompts();
        break;

      case RepoChatDropdownMenuLabels.HISTORY:
        this.showRepositoryHistory('repositoryHistory');
        break;

      case RepoChatDropdownMenuLabels.SOURCE_UPDATED:
        // this.showSourceDetails();
        break;

      case RepoChatDropdownMenuLabels.ABOUT_REPOSITORY:
        this.openAboutRepositoryModal();
        break;

      case RepoChatDropdownMenuLabels.REPOSITORY_SETTINGS:
        this.redirectToManagment();
        break;

      default:
    }
  }

  openAboutRepositoryModal() {
    this.kmdModalService.open('about-repository-modal');
  }

  closeModal() {
    this.kmdModalService.close('about-repository-modal');
  }

  startNewChat() {
    this.isChatInitiated = false;
    this.conversation = {
      id: uuidv4(),
      messages: [],
      date: ''
    };
  }

  showRepositoryHistory(id : string){
    if(this.isMobileScreen()) {
      this.visible = true;
    }
    else {
      this.kmdModalService.open(id);
    }
    this.chatHistoryComponent.tableDataRefresh();
  }

  closeRepositoryHistory(id : string){
    this.kmdModalService.close(id);
  }

  getFileType(link: string): string {
    const extension = link.split('.').pop()?.split('/')[0];
    return extension ? extension.toUpperCase() : '';
  }

  redirectToManagment() {
    this.router.navigate(['admin/' + this.repositoryGroup], {
      relativeTo: this.route
    });
  }

  openDrawer(citations: any): void {
    this.currentCitations = citations;
    this.drawerComponent.openDrawer();
  }

  closeDrawer(): void {
    this.drawerComponent.dismissDrawer();
  }

  toggleText(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  decodeLink(link: string): string {
    return decodeURIComponent(link);
  }

  replaceSpaces(title: string): string {
    return title.replace(/%20/g, ' ');
  }

  openSourceFile(citation: any): void {
    if (!this.enableCitationDownload) {
      return;
    }
    let fullUrl = '';
    this.askOurDocsV2Service.getSasToken(this.repoDetails.internal_index_name).then(response => {
      const { sas_token, base_url } = response;

      if (!citation.url) {
        // If the URL is blank, use the title to construct the full URL
        fullUrl = `${base_url}/${encodeURIComponent(citation.title || "default-title")}${sas_token}`;
      } else if (!citation.url.includes("blob.core")) {
        // If the URL is not blank but doesn't include "blob.core", prepend the base URL
        fullUrl = `${base_url}/${citation.url}${sas_token}`;
      } else {
        // If the citation URL already includes "blob.core", it might already be a full URL
        fullUrl = citation.url;
      }
      window.open(fullUrl, '_blank');
    }).catch(e => {
      console.log(e);
    });
  }

  async conversationChatHistoryClicked(conversationHistory: any) {
    this.isChatInitiated = true;
    this.conversation.id = conversationHistory.id;
    this.chatWrapperComponent.promptInputNewComponent.responseGeneratedFlag = false;
    // We have 3 formats of data which are stored in DB through saveToDB API call
    if(typeof conversationHistory.conversation != 'string') {
      //Case 1: Treating it as JSON
      this.conversation.messages = conversationHistory.conversation;
    } else {
      try {
        // Case 2: Trying to treat it as a stringified JSON and parse the JSON string
        const parsedConversation = JSON.parse(conversationHistory.conversation);
        this.conversation.messages = parsedConversation;
      } catch {
        // Case 3: If parsing fails, treating it as normal string
        this.conversation.messages = this.convertConversationToJson(conversationHistory.conversation);
      }
    }
    this.closeRepositoryHistory('repositoryHistory');
    this.closeDialog();
  }

  convertConversationToJson(conversationString: string): any[] {
    const conversation: any[] = [];
    const messages = conversationString.split('\n');
    let currentMessage = '';
    let currentRole = '';
    const currentDate = new Date().toISOString();

    // Helper function to add message to conversation array
    const addMessageToConversation = (role: string, content: string) => {
        if (content.trim()) {
            conversation.push({
                id: role === 'user' ? crypto.randomUUID() : '',
                role: role,
                content: content.trim(),
                date: currentDate
            });

            // Add tool message after assistant message
            if (role === 'assistant') {
                conversation.push({
                    id: '',
                    role: 'tool',
                    content: JSON.stringify({
                        citations: [],
                        intent: '[]'
                    }),
                    date: currentDate
                });
            }
        }
    };

    // Processing each line
    messages.forEach((line, index) => {
        if (line.startsWith('user: ')) {
            // If there was a previous message, add it
            if (currentRole && currentMessage) {
                addMessageToConversation(currentRole, currentMessage);
            }
            currentRole = 'user';
            currentMessage = line.substring(6); // Removing 'user: '
        } else if (line.startsWith('assistant: ')) {
            // If there was a previous message, then adding it
            if (currentRole && currentMessage) {
                addMessageToConversation(currentRole, currentMessage);
            }
            currentRole = 'assistant';
            currentMessage = line.substring(11); // Remove 'assistant: '
        } else {
            // Appending to current message if it's a continuation
            if (currentMessage && line) {
                currentMessage += '\n' + line;
            }
        }

        // If this is the last line, then we are adding the final message
        if (index === messages.length - 1 && currentRole && currentMessage) {
            addMessageToConversation(currentRole, currentMessage);
        }
    });

    return conversation;
  }

  isMobileScreen(): boolean {
    return (500 >= window.innerWidth);
  }

  showDialog() {
    this.visible = true;
  }

  closeDialog() {
    this.visible = false;
  }

  onDrawerStateChange(event:any){
    this.isAppDrawerOpen = event === 'in';
  }

}
