import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { UnsavedChangesGuard } from "@app/core/guards/unsaved-changes-guard.service";
import { ChipTypeConstant } from "@shared/constants/chip/chip-type";
import { AgentsService } from "@services/agents/agents.service";
import { AlertService } from "@services/alert/alert.service";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";
import { ModalService } from "@services/modal/modal.service";
import { ResizeService } from "@services/resize/resize.service";
import { Agent } from "@shared/models/agent";
import { FileUpload } from "@shared/models/file-upload.model";
import { MemberOfGroup } from "@shared/models/group-membership/member-of/member-of-group.model";
import { Member } from "@shared/models/group-membership/members/member.model";
import { catchError, finalize, Observable, switchMap, take, takeUntil, tap, throwError } from "rxjs";
import { AgentSharingConfirmationModalComponent } from "../agent-sharing-confirmation-modal/agent-sharing-confirmation-modal.component";
import { memberInGroupValidator, USER_NOT_IN_GROUP_VALIDATOR_KEY } from "./member-in-group.validator";
import { DUPLICATE_MEMBER_VALIDATOR_KEY, uniqueMembersValidator } from "./unique-members.validator";
import { HttpErrorResponse } from "@angular/common/http";
import { AgentSharingGroupMemberListModalComponent } from "../agent-sharing-group-member-list-modal/agent-sharing-group-member-list-modal.component";

@Component({
  selector: 'app-agent-sharing-modal',
  templateUrl: './agent-sharing-modal.component.html',
  styleUrls: ['./agent-sharing-modal.component.css']
})
export class AgentSharingModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() sharingDetails?: { agent: Agent, selectedFiles: FileUpload[] };

  @Output() onSharedAgent: EventEmitter<void> = new EventEmitter<void>();
  @Output() onPubliclySharedAgent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalContent') modalContent?: ElementRef;
  @ViewChild('confirmationModal') confirmationModal?: AgentSharingConfirmationModalComponent;
  @ViewChild('memberListModal') memberListModal?: AgentSharingGroupMemberListModalComponent;

  readonly maxAllowedAdmins: number = 5;
  readonly MIN_ADMIN_COUNT: number = 2;

  readonly id = 'agent-sharing-modal';
  readonly headline = 'Share your agent';
  readonly subHeadline = 'Select how do you want to share your agent.';
  readonly MakePublicOption = 1;

  readonly MODAL_PADDING = 150;

  shareToOptions = [
    { id: 'select-one', label: 'Select one', hideOption: true },
    { id: 'keep-personal', label: 'Keep personal' },
    { id: 'make-public', label: 'Make public', disabled: true },
    { id: 'closed-group', label: 'Closed group' },
  ];

  selectedShareToOption = this.shareToOptions[this.MakePublicOption];

  isShareToMenuOpen: boolean = false;
  loadingGroups: boolean = false;
  loadingMembers: boolean = false;
  displayClosedGroupForm: boolean = false;
  displayGroupList: boolean = false;
  scrollableModal: boolean = false;
  isPublicAgentShareFlow: boolean = false;
  isRequestProcessing: boolean = false;

  modalHeight: number = 0;
  windowHeight: number = 0;

  userInformation: Member | null = null;

  groups: MemberOfGroup[] = [];
  formGroup!: FormGroup;
  filteredGroups: MemberOfGroup[] = [];
  groupMembers: Member[] = [];
  groupMemberCountLabel: string = '';
  currentMemberInGroupValidator: ValidatorFn = memberInGroupValidator([]);

  cancelFetchGroupMemberRequest$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private groupMembershipService: GroupMembershipService,
    private alertService: AlertService,
    private agentsService: AgentsService,
    private resizeService: ResizeService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private unsavedChangesGuard: UnsavedChangesGuard
  ) { };

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      admins: new FormArray<FormControl<Member>>([], [uniqueMembersValidator()]),
      guidelinesComply: new FormControl(false),
      group: new FormControl<MemberOfGroup | null>(null),
      typedGroupName: new FormControl(''),
      typedEmail: new FormControl({ value: '', disabled: true }),
    });
  };

  ngOnDestroy(): void {
    this.cancelFetchGroupMemberRequest$.emit();
  };

  openModal(): void {
    this.modalService.open(this.id);
    this.cdRef.detectChanges();
  };

  closeModal(): void {
    this.modalService.close(this.id);
  };

  ngAfterViewInit() {
    this.resizeService.setObserver(this.modalContent!.nativeElement, (entry: any) => this.onModalResize(entry));
    this.resizeService.setObserver(document.body, (entry: any) => this.onWindowResize(entry));
  }

  onModalResize(element: any): any {
    const { height } = element.contentRect;
    this.modalHeight = height + this.MODAL_PADDING;

    if (!this.scrollableModal) {
      this.scrollableModal = (this.windowHeight - this.modalHeight) < 0;
      this.cdRef.detectChanges();
    }
  };

  onWindowResize(element: any): any {
    const { height } = element.contentRect;
    this.windowHeight = height;
    this.scrollableModal = false;
    this.cdRef.detectChanges();
    this.closeModal();
  };

  get admins(): FormArray {
    return this.formGroup.controls['admins'] as FormArray;
  }

  get isGroupSelected(): boolean {
    return this.selectedGroup !== null;
  };

  get isComplianceChecked(): boolean {
    return this.formGroup.controls['guidelinesComply'].value!;
  };

  get selectedGroup(): MemberOfGroup | null {
    return this.formGroup.controls['group'].value || null;
  };

  get selectedGroupAdmins(): Member[] {
    return this.admins.value;
  }

  get isEmailInputDisabled(): boolean {
    if (this.selectedGroup === null || this.groupMembers.length === 0 || this.selectedGroupAdmins.length === this.maxAllowedAdmins) {
      return true;
    }

    if (this.selectedGroupAdmins.length === this.groupMembers.length) {
      this.formGroup.controls['typedEmail'].disable();
      return true;
    }

    return false;
  };

  get isFormSubmitDisabled(): boolean {
    if (this.selectedShareToOption.id !== 'closed-group') {
      return false;
    }

    if (this.selectedGroup === null
      || this.groupMembers.length === 0
      || this.selectedGroupAdmins.length < this.MIN_ADMIN_COUNT
      || this.formGroup.controls['guidelinesComply'].value === false
      || this.admins.errors) {
      return true;
    }

    return false;
  };

  onShareToDropdownClick(): void {
    if (!this.isDropdownDisabled) {
      this.isShareToMenuOpen = true;
    }
  }

  get isDropdownDisabled(): boolean {
    return this.loadingGroups;
  }

  onShareToDropdownOutsideClick(): void {
    this.isShareToMenuOpen = false;
  };

  onSelectionChanged(selectedOption: { id: string }): void {
    this.cancelFetchGroupMemberRequest$.emit();

    this.selectedShareToOption = this.shareToOptions.find(option => option.id === selectedOption.id)!;

    this.isPublicAgentShareFlow = this.selectedShareToOption.id === 'make-public';

    if (this.selectedShareToOption.id !== 'closed-group') {
      this.clearSelectedGroup();
      this.scrollableModal = false;
      this.displayClosedGroupForm = false;
      return;
    }

    if (this.groups.length) {
      this.displayClosedGroupForm = true;
      return;
    }

    this.loadingGroups = true;
    this.setUserInformation()
      .pipe(
        switchMap(() => this.groupMembershipService.fetchUserTeams().pipe(
          catchError(() => {
            this.loadingGroups = false;
            this.alertService.showError('Failed to fetch groups. Please try again later.');
            return throwError(() => null);
          })
        )),
        tap((response) => {
          this.loadingGroups = false;
          this.groups = response.value;
          this.filteredGroups = [...this.groups];
          this.displayClosedGroupForm = true;
        })
      ).subscribe();

    this.formGroup.controls['typedGroupName'].valueChanges
      .pipe(
        tap(value => {
          if (value) {
            this.filteredGroups = this.groups.filter(group =>
              group.displayName.toLowerCase().includes(value.toLowerCase())
            );
          } else {
            this.filteredGroups = [...this.groups];
          }
        })
      ).subscribe();
  };

  onGroupNameInputFocus(): void {
    this.displayGroupList = true;
  };

  onGroupNameInputOutsideClick(): void {
    this.displayGroupList = false;
  };

  onGroupItemClick(group: MemberOfGroup): void {
    this.formGroup.controls['group'].patchValue(group);
    this.formGroup.controls['typedEmail'].enable();
    this.displayGroupList = false;
    this.fetchGroupMembers(group.id);
  }

  setUserInformation(): Observable<Member> {
    return this.groupMembershipService.getUserInformation()
      .pipe(
        tap(response => {
          this.userInformation = response;
          this.setAdmins([this.userInformation!]);
        }),
        catchError(error => {
          this.alertService.showError('Failed to fetch user information. Please try again later.');
          return throwError(() => error);
        })
      );
  };

  fetchGroupMembers(groupId: string): void {
    this.loadingMembers = true;
    this.groupMembershipService.getGroupMembers(groupId)
      .pipe(
        take(1),
        takeUntil(this.cancelFetchGroupMemberRequest$),
        tap((response) => {
          this.groupMembers = response.value;
          this.groupMemberCountLabel = (this.groupMembers.length > 1)
            ? `${this.groupMembers.length} members`
            : `${this.groupMembers.length} member`;
          this.setMemberInGroupValidator();
        }),
        catchError(error => {
          this.alertService.showError('Failed to fetch group members. Please try again later.');
          return throwError(() => error);
        }),
        finalize(() => this.loadingMembers = false)
      ).subscribe();
  };

  clearSelectedGroup(): void {
    this.cancelFetchGroupMemberRequest$.emit();
    this.formGroup.controls['group'].patchValue(null);
    this.formGroup.controls['typedGroupName'].patchValue('');
    this.filteredGroups = [...this.groups];
    this.formGroup.controls['typedEmail'].disable();
    this.formGroup.controls['typedEmail'].patchValue('');
    this.setAdmins([this.userInformation!]);
    this.groupMembers = [];
    this.groupMemberCountLabel = '';
  };

  getChipType(memberCtrl: AbstractControl<Member>): any {
    if (memberCtrl.hasError(DUPLICATE_MEMBER_VALIDATOR_KEY)) {
      return ChipTypeConstant.NOTICE;
    } else if (memberCtrl.hasError(USER_NOT_IN_GROUP_VALIDATOR_KEY)) {
      return ChipTypeConstant.ERROR;
    }

    return ChipTypeConstant.DEFAULT;
  }

  onAdminChipClose(admin: AbstractControl<Member>): void {
    this.admins.removeAt(this.admins.controls.indexOf(admin));

    if (this.formGroup.controls['typedEmail'].disabled && this.admins.length < this.maxAllowedAdmins) {
      this.formGroup.controls['typedEmail'].enable();
    }
  };

  toggleGuidelinesCompliance(): void {
    this.formGroup.controls['guidelinesComply'].patchValue(!this.formGroup.controls['guidelinesComply'].value);
  };

  isAdminChipDeletionAllowed(): boolean {
    return this.formGroup.controls['typedEmail'].value === '' && this.selectedGroupAdmins.length > 1;
  }

  disableTypedEmailInputIfAdminsLimitReached(): void {
    if (this.selectedGroupAdmins.length === this.maxAllowedAdmins) {
      this.formGroup.controls['typedEmail'].disable();
    }
  }

  onNewAdminChipKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Backspace' && this.isAdminChipDeletionAllowed()) {
      this.popAdmin();
    }

    if (($event.key === 'Enter') || ($event.key === 'Tab') || ($event.key === ' ')) {
      this.addAdmin();
      this.disableTypedEmailInputIfAdminsLimitReached();
      this.formGroup.controls['typedEmail'].patchValue('');
    }

  }

  addAdmin() {
    const typedEmail: string = this.formGroup.controls['typedEmail'].value!;

    if (!typedEmail || typedEmail === '') {
      return;
    }

    const member = this.groupMembers.find(member => member.mail === typedEmail);
    if (!member) {
      this.setInvalidAdmin(typedEmail);
    } else {
      this.pushAdmin(member);
    }
  }

  setAdmins(admins: Member[]) {
    this.admins.clear();
    admins.forEach(admin => this.pushAdmin(admin));
  }

  setInvalidAdmin(email: string) {
    this.pushAdmin({ mail: email } as Member);
  }

  setMemberInGroupValidator() {
    this.admins.removeValidators(this.currentMemberInGroupValidator);
    this.currentMemberInGroupValidator = memberInGroupValidator(this.groupMembers);
    this.admins.addValidators(this.currentMemberInGroupValidator!);
  }

  pushAdmin(member: Member) {
    const memberControl = new FormControl<Member>(member);
    this.admins.push(memberControl);
  }

  popAdmin() {
    this.admins.removeAt(this.selectedGroupAdmins.length - 1);
  };

  toggleMakePublicOptionDisabled(value: any): void {
    const option = this.shareToOptions.find(opt => opt.id === 'make-public');
    if (option) {
      option['disabled'] = value;
    }
  }

  saveAndShare(): void {
    const azureGroupEmail = this.selectedGroup!.mail;
    const adminEmails = this.selectedGroupAdmins.map(admin => admin.mail);

    this.isRequestProcessing = true;

    this.agentsService.saveAndShare(this.sharingDetails!.agent!, azureGroupEmail, adminEmails)
      .pipe(
        tap(() => {
          this.isRequestProcessing = false;
          this.unsavedChangesGuard.setSkipGuard(true);
          this.onSharedAgent.emit();
        }),
        catchError((error) => {
          if (error.type === 'agent-save') {
            this.handleSaveError(error.error);
          } else if (error.type === 'agent-share') {
            this.unsavedChangesGuard.setSkipGuard(true);
            this.alertService.showError('Failed to share agent. Please try again later.');
            this.router.navigate([`/agents/${error.agentId}/edit`]);
          } else {
            this.alertService.showError('An unexpected error occurred. Please try again later.');
          }

          this.isRequestProcessing = false;
          this.closeModal();
          this.confirmationModal?.closeModal();

          return throwError(() => error);
        })
      ).subscribe();
  };

  saveAndSharePublic(): void {
    this.isRequestProcessing = true;
    this.agentsService.saveAndPublish(this.sharingDetails!.agent!)
      .pipe(
        tap(() => {
          this.isRequestProcessing = false;
          this.unsavedChangesGuard.setSkipGuard(true);
          this.onPubliclySharedAgent.emit();
          this.alertService.showSuccess("Your agent is now available under Public Agents.");
        }),
        catchError((error) => {
          if (error.type === 'agent-save') {
            this.handleSaveError(error.error);
          } else if (error.type === 'agent-publish') {
            this.unsavedChangesGuard.setSkipGuard(true);
            this.alertService.showError('Failed to publish agent. Please try again later.');
            this.router.navigate([`/agents/${error.agentId}/edit`]);
          } else {
            this.alertService.showError('An unexpected error occurred. Please try again later.');
          }

          this.closeModal();
          this.confirmationModal?.closeModal();
          this.isRequestProcessing = false;

          return throwError(() => error);
        })
      ).subscribe();
  };

  handleSaveError(response: HttpErrorResponse): void {
    if (response.error?.detail === 'Agent with the same name already exists') {
      this.alertService.showError('You already have an agent with this name. Please use a unique name for your agent.');
      return;
    }

    this.alertService.showError('Failed to save agent. Please try again later.');
  };

  showConfirmationModal(): void {
    this.confirmationModal?.openModal();
  };

  onClickHandler(): void {
    if (this.selectedShareToOption.id === 'keep-personal') {
      return this.saveAgent();
    }

    this.showConfirmationModal();
  }

  saveAgent() {
    this.agentsService.save(this.sharingDetails!.agent!).subscribe({
      next: () => {
        this.unsavedChangesGuard.setSkipGuard(true);
        this.router.navigate([`/agents`]);
      }
    });
  };

  showMemberListModal(): void {
    this.memberListModal?.openModal();
  };
};
