import { AskOurDocsRoleGuard } from "@app/core/guards/ask-our-docs-role.guard";
import { SVA2RoleGuard } from "@app/core/guards/sva2-role.guard";
import { featureFlags } from "@app/environments/environment";
import { MenuOption } from "@shared/models/navbar/menu-option";
import { BetaGuard } from "@app/core/guards/beta.guard";
import { DallEGuard } from "@app/core/guards/dall-e.guard";
import { AskOurDataRoleGuard } from "@app/core/guards/ask-our-data-role.guard";

export const getMenuOptions = (askOurDocsRoleGuard: AskOurDocsRoleGuard, sva2RoleGuard: SVA2RoleGuard,
                               betaGuard: BetaGuard, dallEGuard: DallEGuard, askOurDataRoleGuard: AskOurDataRoleGuard): MenuOption[] => {
  const menuItems: MenuOption[] = [
        {
          id: 'new-chat-menu-item',
          label: 'New chat',
          anchor: { type: 'routerLink', value: 'ask-gene' },
          icon: { type: 'src', value: '../assets/icons/chat/chat-mono.svg' }
        },
        {
          id: 'agents-menu-item',
          label: 'Agents',
          anchor: { type: 'routerLink', value: 'agents' },
          icon: { type: 'src', value: '../assets/icons/agents/robot-mono-new.svg' }
        },
        {
          id: 'ask-our-docs-menu-item',
          label: 'Ask our Docs',
          anchor: { type: 'routerLink', value: 'ask-our-docs' },
          icon: { type: 'src', value: '../assets/icons/ask-our-docs/aod-new.svg' },
          guard: [askOurDocsRoleGuard]
        },
        {
          id: 'ask-our-data-menu-item',
          label: 'Ask our Data',
          anchor: { type: 'routerLink', value: 'ask-our-data' },
          icon: { type: 'src', value: '../assets/icons/ask-our-data/aodata-new.svg' },
          guard: [askOurDataRoleGuard]
        },
        {
          id: 'my-documents-menu-item',
          label: 'My docs',
          anchor: { type: 'routerLink', value: 'uploader' },
          icon: { type: 'src', value: "../assets/icons/folder/folder-closed-mono.svg" }
        },
        {
          id: 'community-apps-menu-item',
          label: 'Community apps',
          anchor: { type: 'routerLink', value: 'iframe-apj' },
          icon: { type: 'src', value: "../assets/icons/community/users.svg" },
          guard: [betaGuard]
        },
        {
          id: 'generative-tools',
          icon: { type: 'src', value: '../assets/icons/support/support-mono.svg' },
          label: 'Generative Tools',
          customClass: 'submenu',
          submenu: {
            subMenuList: [
              {
                id: 'marketing-ads',
                label: 'Marketing Ads',
                anchor: { type: 'routerLink', value: 'tools/marketing-ads' }
              },
              {
                id: 'image-creation',
                label: 'Image Generator',
                anchor: { type: 'routerLink', value: 'image-creation' },
                guard: [dallEGuard]
              },
              {
                id: 'translation',
                label: 'Language Translation',
                anchor: { type: 'routerLink', value: 'tools/translations' }
              }
            ]
          }
        },
        {
          id: 'my-library-menu-item',
          label: 'My library',
          anchor: { type: 'routerLink', value: 'my-library' },
          icon: { type: 'src', value: '../assets/icons/clock/clock-24px-mono-new.svg' },
          customClass: 'my-library'
        },
        {
          id: 'sva2-menu-item',
          label: 'SVA Plus',
          anchor: { type: 'routerLink', value: 'sva2' },
          icon: { type: 'src', value: '../assets/icons/ask-our-docs/ask-our-docs-icon.svg' },
          guard: [sva2RoleGuard],
          featureFlag: [featureFlags.sva2]
        },
        {
          id: 'ccd-menu-item',
          label: 'Commercial Connections Dashboard',
          anchor: { type: 'routerLink', value: 'ccd' },
          icon: { type: 'src', value: '../assets/icons/ask-our-docs/ask-our-docs-icon.svg' },
          guard: [],
          featureFlag: [featureFlags.ccd]
        },
      ];

      // Finding the index of ask-our-docs-menu-item
      const askOurDocsIndex = menuItems.findIndex(item => item.id === 'ask-our-docs-menu-item');
      // Adding ask-our-docs-v2 after ask-our-docs if condition is true
      if (askOurDocsIndex !== -1 && featureFlags.askOurDocsV2) {
        menuItems.splice(askOurDocsIndex + 1, 0, {
            id: 'ask-our-docs-menu-item-new',
            label: 'Ask our Docs V2',
            anchor: { type: 'routerLink', value: 'ask-our-docs-v2' },
            icon: { type: 'src', value: '../assets/icons/ask-our-docs/aod-new.svg' },
            guard: [askOurDocsRoleGuard],
            featureFlag: [featureFlags.askOurDocsV2]
        });
      }

      return menuItems;
};
