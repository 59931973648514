import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-about-repository',
  templateUrl: './about-repository.component.html',
  styleUrls: ['./about-repository.component.css']
})
export class AboutRepositoryComponent {
  @Input() last_run_date: string = 'No last run date available';
  @Input() repositorydetails: string = 'No information available';
  @Input() repoOwner: string = 'No admin details available';
  @Input() repositoryName: string = '';
  @Input() repositorylink: string = 'No repository link available';
  @Input() linkrequired: boolean = false;


}
