<div *ngIf="feedbackForm && feedbackForm.get('responses')?.value.length > 0" class="feedback-form">
  <form [formGroup]="feedbackForm" (ngSubmit)="onSubmitFeedback()" *ngIf="showForm">
    <!-- Form header section -->
    <div class="form-header">
      <div class="rate-response-heading">Rate response</div>
      <div class="close-button-container">
        <button type="button" class="close-button" (click)="onCancelForm()">
          <svg id="close-mono.svg" width="0.875rem" height="0.875rem" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22 3.42L20.59 2L12 10.59L3.41003 2L2 3.42L10.59 12L2 20.59L3.41003 22L12 13.42L20.59 22L22 20.59L13.41 12L22 3.42Z"
              fill="#1B1B1D" />
          </svg>
        </button>
      </div>
    </div>

    <!-- Dynamic responses -->
    <div formArrayName="responses">
      <div *ngFor="let responseControls of responses.controls; let i = index" [formGroupName]="i">
        <div [ngSwitch]="responseControls.get('questionType')?.value">
          <!-- Linear Scale Question -->
          <div *ngSwitchCase="'Linear scale'">
            <div class="rating-section">
              <div for="qualityOfResponse" class="rating-label-text">
                {{ feedbackData[i].questionText }} <span *ngIf="!feedbackData[i].isRequired">(optional)</span>
              </div>
              <div class="rating-container">
                <label
                  *ngFor="let value of generateRange(feedbackData[i].startValue.value, feedbackData[i].endValue.value)"
                  class="rating-label">
                  <input type="radio" formControlName="answer" [value]="value" />
                  <span>{{ value }}</span>
                </label>
              </div>
              <div class="rating-descriptions">
                <div class="rating-description">{{ feedbackData[i].startValue?.text }}</div>
                <div class="rating-description">{{ feedbackData[i].endValue?.text }}</div>
              </div>
            </div>
          </div>

          <!-- Multiple Choice Question -->
          <div *ngSwitchCase="'Multiple choice'">
            <div class="additional-options-question">{{ feedbackData[i].questionText }}</div>
            <div formArrayName="answers" class="options-container">
              <div *ngFor="let option of feedbackData[i].options; let j = index" class="option-item">
                <div class="question-checkbox-container">
                  <div class="option-checkbox-container">
                    <input type="checkbox" [formControlName]="j" class="option-checkbox" />
                  </div>
                  <div class="option-text-container">
                    <span class="option-text">{{ option.optionText }}</span>
                  </div>
                </div>
              </div>
              <div class="option-item" *ngIf="feedbackData[i].otherOption">
                <div class="question-checkbox-container">
                  <div class="option-checkbox-container">
                    <input type="checkbox" [formControlName]="feedbackData[i].options.length" class="option-checkbox"
                      (change)="otherOptionCheckboxChange(feedbackData[i])" />
                  </div>
                  <div class="option-text-container">
                    <span class="option-text">Other - I will explain</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="other-answer-textarea-container">
              <textarea *ngIf="feedbackData[i].isOtherOptionChecked" class="wide-textarea"
                formControlName="otherAnswerText" rows="3"></textarea>
            </div>
          </div>

          <!-- Long Text Question -->
          <div *ngSwitchCase="'Long text'">
            <div class="general-feedback-section">
              <label class="improvement-label">
                <span class="general-feedback-span">{{ feedbackData[i].questionText }}</span>
              </label>
              <div class="general-feedback-text-container">
                <textarea formControlName="answer" rows="3" class="wide-textarea"></textarea>
              </div>
            </div>
          </div>

          <!-- Single Choice Question -->
            <div *ngSwitchCase="'Single choice'">
              <label>{{ feedbackData[i].questionText }}</label>
              <div *ngFor="let option of feedbackData[i].options">
                <div>
                  <input type="radio" formControlName="answer" [value]="option.optionText" (click)="onSingleChoiceOptionClick(feedbackData[i])" />
                  {{ option.optionText }}
                </div>
              </div>
              <div *ngIf="feedbackData[i].otherOption">
                <div class="question-radio-button-container">
                  <div class="option-radio-button-container">
                    <input type="radio" formControlName="answer"
                      (change)="otherOptionCheckboxChange(feedbackData[i])" />
                  </div>
                  <div class="option-text-container">
                    <span class="option-text">Other - I will explain</span>
                  </div>
                </div>
              </div>
              <div class="other-answer-textarea-container" *ngIf="feedbackData[i].otherOption === true">
                <textarea *ngIf="feedbackData[i].isOtherOptionChecked" class="wide-textarea"
                  formControlName="answer" rows="3"></textarea>
              </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Info container -->
    <div class="info-container">
      <div class="info-icon-container">
        <span class="info-icon">
          <svg id="info-bold.svg" width="17" height="17" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 2C6.48 2 2 6.47998 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.47998 17.52 2 12 2ZM13.5 19H10.5V10H13.5V19ZM13.5 8H10.5V5H13.5V8Z"
              fill="#1B1B1D" />
          </svg>
        </span>
      </div>
      <div class="info-text">
        We collect your name, email, and feedback to ensure proper routing and follow-up if needed.
      </div>
    </div>

    <div *ngIf="!hideButtons" class="button-container">
      <button type="button" class="cancel-button" (click)="onCancelForm()">
        Cancel
      </button>
      <button type="submit" class="submit-button">Submit</button>
    </div>
  </form>
</div>

<!-- success-message -->
<div *ngIf="showSuccessMessage" class="success-message-one">
  <div class="section-one">
    <div class="green-strip">
      <!-- green strip -->
    </div>
    <div class="icon-container">
      <!-- icon -->
      <svg id="checkmark-mono.svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M22 3.14L20.36 2L8.74005 18.75L3.51001 12.7L2 14L8.91003 22L9.29004 21.46L22 3.14Z" fill="#FFFFFF" />
      </svg>
    </div>
  </div>
  <div class="section-two">
    <div class="heading">Thank you for your feedback.</div>
    <div class="subsection">
      Your suggestions mean so much to us and help us improve.
    </div>
  </div>
  <div class="section-three">
    <!-- icon -->
    <svg id="close-mono.svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      (click)="closeSuccessMessage()">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M22 3.42L20.59 2L12 10.59L3.41003 2L2 3.42L10.59 12L2 20.59L3.41003 22L12 13.42L20.59 22L22 20.59L13.41 12L22 3.42Z"
        fill="#1B1B1D" />
    </svg>
  </div>
</div>