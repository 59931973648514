import { ActivatedRoute, Router } from "@angular/router";
import { PromptSettings } from '@shared/models/prompt-settings.model';
import { ChatQAService } from "@services/chat-qa/chat-qa.service";
import {
  catchError,
  EMPTY,
  lastValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  throwError
} from "rxjs";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { Prompt } from "@shared/models/prompt.model";
import { ModalSavePromptComponent } from "@app/prompt/modalsaveprompt/modal-save-prompt.component";
import { Message } from "@shared/models/message.model";
import { ChatMessage } from "@app/chat/chat-message";
import { PromptSubmitEvent } from "@shared/models/prompt-submit-event";
import { ChatWrapperComponent } from "@shared/components/chat-wrapper/chat-wrapper.component";
import { Location } from "@angular/common";
import { finalize, take, takeUntil } from "rxjs/operators";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { AlertService } from "@services/alert/alert.service";
import { MessageNode } from "@app/shared/models/message-node.model";
import { FileManagerService } from "@services/file-manager/file-manager.service";
import { FileReference } from "@shared/models/file-reference.model";
import { FileManagerDoc } from "@shared/models/file-manager/file-manager-doc.model";

@Component({
  selector: 'app-chat-new',
  templateUrl: './chat-new.component.html',
  styleUrls: ['./chat-new.component.css']
})
export class ChatNewComponent implements OnInit, OnDestroy {

  @ViewChild(ChatWrapperComponent) chatWrapperComponent!: ChatWrapperComponent;
  @ViewChild('savePromptComponent') modalSavePromptComponent?: ModalSavePromptComponent;
  @ViewChild('chatContainer') chatContainer?: ElementRef;
  @ViewChildren('assistantMessage', { read: ElementRef }) assistantMessages?: QueryList<ElementRef>;

  @Output() promptSavedEvent = new EventEmitter();

  chatId$?: Observable<string>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  imageCache = new Map<string, Observable<string | null>>();

  promptSettings?: PromptSettings;
  selectedPrompt?: Prompt;

  loadingChat = true;
  isGeneratingResponse = false;
  isStreamingResponse: boolean = false;
  isUserScrolling = false;
  isMessageCompleted = false;
  showMissingFilesNotification = false;

  messageTree: MessageNode[] = [];

  messages: {
    id: string,
    user?: MessageNode,
    assistant?: Observable<MessageNode | null>,
    error?: boolean,
    isGenerating: boolean
  }[] = [];

  protected shouldScrollToBottom: boolean = true;
  private scrollTop: number = 0;

  constructor(
    public activatedRoute: ActivatedRoute,
    private chatQAService: ChatQAService,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    private alertService: AlertService,
    private fileManagerService: FileManagerService
  ) {
  };

  ngOnInit(): void {
    this.promptSettings = this.chatQAService.promptSettings;

    this.activatedRoute.params
      .pipe(
        map(params => params['id']),
        tap((chatId) => this.chatId$ = of(chatId))
      )
      .subscribe(() => {
        this.messageTree = [];
        this.messages = [];

        if (!this.chatQAService.activePrompt) {
          this.loadExistingChat();
        } else {
          this.displayNewMessage();
        }
      });
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onActiveAssistantMessageChange(changeRequest: { assistantMessageNode: MessageNode, nextSiblingIndex: number }): void {
    const newActiveBranch = this.chatQAService.shiftActiveBranch(this.messageTree, changeRequest.assistantMessageNode, changeRequest.nextSiblingIndex);

    this.messages = newActiveBranch.map((node) => {
      if (node.role === 'USER') {
        return {
          id: node.id,
          user: node,
          isGenerating: false
        };
      }

      return {
        id: node.id,
        assistant: of(node),
        isGenerating: false
      };
    });

    let lastUserMessage = this.getLastRoleUserMessage()
    if (lastUserMessage?.content?.message?.fileReferences?.length) {
      this.setFilesInManager(lastUserMessage.content.message.fileReferences!).then(() => {});
    }
  }

  loadExistingChat(): void {
    this.shouldScrollToBottom = true;
    this.loadingChat = true;
    this.chatId$?.pipe(
      take(1),
      switchMap((chatId) => {
        if (!chatId) {
          this.router.navigate(['/ask-gene']);
          return EMPTY;
        }

        return this.chatQAService.getExistingMessages(chatId);
      }),
      catchError((error) => {
        this.router.navigate(['/error']);
        return throwError(() => error);
      }),
      tap(async (messages: MessageNode[]) => {
        this.messageTree = messages;
        const activeBranch = this.chatQAService.getActiveBranch(this.messageTree);

        this.messages = activeBranch.map((node) => {
          if (node.role === 'USER') {
            return {
              id: node.id,
              user: node,
              isGenerating: false
            };
          }

          return {
            id: node.id,
            assistant: of(node),
            isGenerating: false
          };
        })
        this.showMissingFilesNotification = false;
        let lastUserMessage = this.getLastRoleUserMessage()
        if (lastUserMessage?.content?.message?.fileReferences?.length) {
          await this.setFilesInManager(lastUserMessage.content.message.fileReferences!);
        }

        this.isMessageCompleted = true;
        this.loadingChat = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  };

  async setFilesInManager(fileReferences: FileReference[]): Promise<void> {
    const originalLength = fileReferences.length;
    let filteredLength = 0;
    fileReferences = fileReferences.filter(fileReference => {
      const titleWithoutExtension = fileReference.title.split('.').slice(0, -1).join('.').trim();
      return titleWithoutExtension.length > 0;
    });
    if (fileReferences.length> 0) {
      const fileIds: string[] = fileReferences.map((fileReference) => fileReference.id);
      const fileExtension = `${fileReferences[0].title.split('.').pop()}`!;
      const fileDocs: FileManagerDoc[] = await lastValueFrom(this.fileManagerService.getFiles(fileIds));
      filteredLength = fileDocs.length;
      let fileType = this.fileManagerService.getCategoryForFileType(fileExtension);
      this.fileManagerService.setSelectedFileType(fileType);
      this.fileManagerService.setSelectedFiles(fileDocs);
    }
    this.showMissingFilesNotification = filteredLength !== originalLength;
  }

  getLastRoleUserMessage(): MessageNode | undefined {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].user) {
        return this.messages[i].user;
      }
    }
    return undefined;
  }

  displayNewMessage(): void {
    this.shouldScrollToBottom = false;
    const activePrompt = this.chatQAService.activePrompt!;

    this.isGeneratingResponse = true;
    this.loadingChat = false;

    let previousParentId: string | null = null;
    let assistantChatMessage$: Observable<ChatMessage>;

    if (this.messages.length === 0) {
      assistantChatMessage$ = this.getChatAndAssistantMessage(activePrompt.prompt);
    } else {
      previousParentId = this.messages[this.messages.length - 1].id;
      assistantChatMessage$ = this.getAssistantMessage(activePrompt.prompt);
    }

    const parent: MessageNode = this.chatQAService.addMessageToTree(this.messageTree, activePrompt.message, previousParentId);
    const prompt = parent.content!.message;
    this.messages.push({ id: parent.id, user: parent, isGenerating: false });
    this.messages.push({ id: 'loading', assistant: of(), isGenerating: true });
    this.scrollToEnd();

    assistantChatMessage$.pipe(
      catchError(() => of(null)),
      map((assistantMessage) => {
        if (!assistantMessage) {
          this.chatWrapperComponent.setPromptText(prompt.text);
          this.messages = this.messages.slice(0, this.messages.length - 2);
          return;
        }

        const node = this.chatQAService.addMessageToTree(this.messageTree, assistantMessage.message, parent.id);
        const index = this.messages.findIndex((message) => message.id === node.id);

        const message$ = of(node).pipe(
          catchError(() => of(null)),
          tap(() => this.cdr.detectChanges())
        );

        if (index !== -1) {
          this.messages[index].assistant = message$;
        } else {
          this.messages.pop();
          this.messages.push({ id: node.id, assistant: message$, isGenerating: false });
        }
      }),
    ).subscribe();

    this.cdr.detectChanges();
    this.startAutoScrollToLastAssistantMessage();
  };

  regenerateResponse(parentId: string) {
    const message = this.messages.find((message) => message.user?.id === parentId);

    if (!message) {
      this.alertService.showError("An error occurred while regenerating the response.");
      return
    }

    this.isGeneratingResponse = true;
    this.loadingChat = false;

    const index = this.messages.findIndex((message) => message.user?.id === parentId);
    const backupMessages = this.messages;

    this.messages = this.messages.slice(0, index + 1);
    this.messages.push({ id: 'loading', assistant: of(), isGenerating: true });

    let chatId: string;

    const regeneratedAssistantMessage$ = this.chatId$!.pipe(
      tap((id) => chatId = id),
      mergeMap((id) => this.chatQAService.regenerateResponse(id, message.user!.content!.message).pipe(
        catchError((error) => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.alertService.showError("An error occurred while regenerating the response.");
          return throwError(() => error);
        }),
        finalize(() => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.chatHistoryUpdateService.notifyChatUpdated(chatId!);
          this.cdr.detectChanges();
        })
      )),
      takeUntil(this.destroy$),
      finalize(() => {
        this.isStreamingResponse = false;
        this.isGeneratingResponse = false;
        this.isMessageCompleted = true;
        this.chatHistoryUpdateService.notifyChatUpdated(chatId!);
        this.cdr.detectChanges();
      }),
      tap(() => {
        if (!this.isGeneratingResponse) {
          return;
        }

        this.isGeneratingResponse = false;
        this.isStreamingResponse = true;
      }),
      map((response) => new ChatMessage(response.message, undefined))
    );

    regeneratedAssistantMessage$.pipe(
      catchError(() => of(null)),
      map((assistantMessage) => {
        if (!assistantMessage) {
          this.messages = backupMessages;
          return;
        }

        const node = this.chatQAService.addMessageToTree(this.messageTree, assistantMessage.message, parentId);
        const index = this.messages.findIndex((message) => message.id === node.id);

        const message$ = of(node).pipe(
          catchError(() => of(null)),
          tap(() => this.cdr.detectChanges())
        );

        if (index !== -1) {
          this.messages[index].assistant = message$;
        } else {
          this.messages.pop();
          this.messages.push({ id: node.id, assistant: message$, isGenerating: false });
        }
      })
    ).subscribe();

    this.cdr.detectChanges();
    this.startAutoScrollToLastAssistantMessage();
  };

  getChatAndAssistantMessage(prompt: Prompt): Observable<ChatMessage> {
    return this.chatQAService.createChat(prompt)
      .pipe(
        catchError((error) => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.alertService.showError("An error occurred while creating the chat.");
          return throwError(() => error);
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.isMessageCompleted = true;
          this.destroy$.next(true);
          this.cdr.detectChanges();
        }),
        tap((response) => {
          if (!this.isGeneratingResponse) {
            return;
          }
          this.chatId$ = of(response.chatId);
          prompt.chatId = response.chatId;
          this.location.replaceState(`/chat/${response.chatId}`);
          this.isGeneratingResponse = false;
          this.isStreamingResponse = true;
        }),
        map((response) => new ChatMessage(response.message!, undefined))
      );
  };

  getAssistantMessage(prompt: Prompt): Observable<ChatMessage> {
    return this.chatId$!.pipe(
      mergeMap((chatId) => this.chatQAService.sendChat(chatId, prompt)
        .pipe(
          catchError((error) => {
            this.isStreamingResponse = false;
            this.isGeneratingResponse = false;
            this.alertService.showError("An error occurred while sending the chat.");
            return throwError(() => error);
          }),
          finalize(() => {
            this.isStreamingResponse = false;
            this.isGeneratingResponse = false;
            this.chatHistoryUpdateService.notifyChatUpdated(chatId);
            this.cdr.detectChanges();
          })
        )
      ),
      takeUntil(this.destroy$),
      finalize(() => {
        this.isMessageCompleted = true;
        this.isStreamingResponse = false;
        this.cdr.detectChanges();
      }),
      tap(() => {
        if (!this.isGeneratingResponse) {
          return;
        }

        this.isGeneratingResponse = false;
        this.isStreamingResponse = true;
      }),
      map((response) => new ChatMessage(response.message, undefined))
    );
  }

  openSaveModal(message: Message): void {
    this.chatId$!.pipe(
      map((chatId) => {
        const prompt = new Prompt(message.text, message.promptSettings);
        prompt.id = message.id;
        prompt.fileReferences = message.fileReferences;
        prompt.chatId = chatId;

        this.selectedPrompt = Object.assign({}, prompt);
        this.modalSavePromptComponent?.open();
      })
    ).subscribe();
  };

  savePrompt() {
    this.modalSavePromptComponent?.close();
    this.promptSavedEvent.emit();
  }

  onPromptSubmit(event: PromptSubmitEvent): void {
    this.chatQAService.setNewActivePrompt(event);
    this.chatWrapperComponent.setPromptText("");
    this.displayNewMessage();
  };

  scrollToEnd(): void {
    if (!this.chatContainer) {
      return;
    }

    this.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    this.renderer.setProperty(this.chatContainer.nativeElement, 'scrollTop', this.scrollTop);
    this.isUserScrolling = false;
  }

  startAutoScrollToLastAssistantMessage(): void {
    const lastAssistantMessage = this.assistantMessages?.last?.nativeElement;

    if (lastAssistantMessage) {
      this.isUserScrolling = false;
      this.scrollToLastAssistantMessage();

      const scrollInterval = setInterval(() => {
        if (!this.isUserScrolling) {
          this.scrollToLastAssistantMessage();
        } else {
          clearInterval(scrollInterval);
        }
      }, 500);
    }
  }

  scrollToLastAssistantMessage(): void {
    const lastAssistantMessage = this.assistantMessages?.last?.nativeElement;

    if (lastAssistantMessage) {
      const offsetTop = lastAssistantMessage.offsetTop;
      const offsetMargin = 75;
      this.scrollTop = offsetTop - offsetMargin;
      if(this.chatContainer)
        this.renderer.setProperty(this.chatContainer.nativeElement, 'scrollTop', this.scrollTop);
      this.isUserScrolling = false;
    }
  }

  onScroll(event: any) {
    this.isUserScrolling = !(event.target!.offsetHeight + event.target!.scrollTop >= event.target!.scrollHeight);
    this.scrollTop = event.target.scrollTop;
  }

  stopStreaming() {
    this.isStreamingResponse = false;
    this.destroy$.next(true);
    this.cdr.detectChanges();
  }

  getScrollTop() : number {
    return this.scrollTop;
  }
}
