<app-chat-wrapper *ngIf="!loadingChat; else loading" [contentPosition]="'bottom'"
  [isStreamingResponse]="isStreamingResponse" (onPromptSubmit)="onPromptSubmit($event)"
  (stopStreaming)="stopStreaming()">
  <div id="chat-container" #chatContainer [scrollTop]="shouldScrollToBottom? chatContainer.scrollHeight: getScrollTop()" (scroll)="onScroll($event)">
    <div class="messages-wrapper">
      <div class="message-bubble" *ngFor="let msg of messages">
        <ng-container *ngIf="msg.user">
          <app-user-message [chatMessage]="msg.user.content" (openSavePromptModal)="openSaveModal($event)" />
        </ng-container>
        <ng-container *ngIf="msg.assistant">
          <ng-container *ngIf="!msg.isGenerating; else generatingResponse">
            <ng-container *ngIf="msg.assistant | async as assistantMsg">
              <app-assistant-message #assistantMessage [messageNode]="assistantMsg" [isGeneratingResponse]="false" [isMessageCompleted]="isMessageCompleted"
              [imageCache]="imageCache"
                (regenerateResponse)="regenerateResponse($event)"
                (activeMessageChange)="onActiveAssistantMessageChange($event)" />
            </ng-container>
          </ng-container>
          <ng-template #generatingResponse>
            <app-assistant-message #assistantMessage [isGeneratingResponse]="true" [imageCache]="imageCache" />
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="missing-files-notification" *ngIf="showMissingFilesNotification">
    <core-notification
      label='Missing files: '
      message='One or more referenced files are no longer available. If you want to use them, please upload them again.'
      variant='inline'
      state='error'
      timer='0'
    ></core-notification>
  </div>

</app-chat-wrapper>
<ng-template #loading>
  <div class="loading-wheel-wrapper">
    <kmd-loading-wheel *ngIf="loadingChat"></kmd-loading-wheel>
  </div>
</ng-template>
<app-modal-save-prompt id="savePromptId" #savePromptComponent [prompt]="selectedPrompt!"
  (promptSaveEvent)="savePrompt()" />
