import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Member } from "@app/shared/models/group-membership/members/member.model";

export const DUPLICATE_MEMBER_VALIDATOR_KEY = 'duplicateMember';

function findDuplicateMembers(members: Member[]): Member[] {
  const seen = new Set<string>();
  const duplicates = new Set<Member>();

  members.forEach(member => {
    const email = member.mail.trim().toLowerCase();
    if (seen.has(email)) {
      duplicates.add(member);
    } else {
      seen.add(email);
    }
  });

  return Array.from(duplicates);
}

function clearControlError(control: AbstractControl, errorKey: string): void {
  const existingErrors = control.errors || {};
  delete existingErrors[errorKey];
  const remaingingErrors = Object.keys(existingErrors).length ? existingErrors : null;
  control.setErrors(remaingingErrors);
}

function evaluateControlErrors(control: AbstractControl, duplicateMembers: Member[]): ValidationErrors | null {
  clearControlError(control, DUPLICATE_MEMBER_VALIDATOR_KEY);
  const controlErrors = control.errors || {};
  const memberEmail = control.value.mail.trim().toLowerCase();
  const isDuplicate = duplicateMembers.some(member => member.mail.trim().toLowerCase() === memberEmail);

  if (isDuplicate) {
    controlErrors[DUPLICATE_MEMBER_VALIDATOR_KEY] = true;
  }

  return Object.keys(controlErrors).length ? controlErrors : null;
}

export function uniqueMembersValidator(): ValidatorFn {

  return (formArray: AbstractControl): ValidationErrors | null => {
    if (!formArray || !(formArray instanceof FormArray)) {
      return null;
    }

    const members = formArray.value as Member[];
    const duplicateMembers = findDuplicateMembers(members);

    formArray.controls.forEach((control) => {
      const controlErrors = evaluateControlErrors(control, duplicateMembers);
      control.setErrors(controlErrors);
    });

    return duplicateMembers.length > 0 ? { duplicateMember: true } : null;
  };
}
