import { Component } from '@angular/core';

@Component({
  selector: 'app-document-translations-notification', // This is the selector to use
  templateUrl: './document-translations-notification.component.html',
  styleUrls: ['./document-translations-notification.component.css']
})
export class DocumentTranslationsNotificationComponent {
  translationContentType: string = 'Document';
  featureFlags = {
    documentTranslationNotification: true
  };

  constructor() { }
}
