import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ResizeService {

    constructor() { };

    setObserver(element: any, callbackFn: (entry: any) => object): void {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                callbackFn(entry);
            }
        });

        observer.observe(element);
    };
};
