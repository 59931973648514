<div class="content-translations">
  <div class="title">
    <div class="toolbar-content-translations">
      <kmd-alerts kmd-error [(show)]="showErrorAlert" [alertText]="errorAlertText" [dismissible]="true"></kmd-alerts>
      <kmd-alerts kmd-success [(show)]="isCopied" [icon]="true" [alertText]="alertCopyText" [floating]="true" id="copy-success-alert"></kmd-alerts>
      <div class="left-section">
        <div class="hero-text">
          <h1>
            Language translation
          </h1>
          <p>For internal and informal content, accuracy isn't guaranteed. For external-facing content, please use
            <b><a href="https://thermofisher.sharepoint.com/sites/LanguageServices" target="_blank">translation services.</a></b>
          </p>
        </div>
      </div>
      <div class="middle-section"></div>
      <div class="right-section">
        <a href="https://thermofisher.sharepoint.com/sites/LanguageServices" target="_blank" class="button-link">
          <img src="assets/icons/flyout/flyout-14px-dark-gray-mono.svg" alt="flyout" class="flyout-icon">
          Translation Services
        </a>
      </div>
    </div>
  </div>
  <div class="document-translations-container">
    <app-document-translations-notification *ngIf="(translationContentType==='Document') && featureFlags.documentTranslationNotification"></app-document-translations-notification>
    <div class="left-section">
      <div class="content-type" *ngIf="featureFlags.documentTab">
        <h2>Choose the type of content to translate</h2>
        <div class="translations-disclaimer">
          <p>
            Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information. Please <a href="https://geneai.thermofisher.com/guidelines" target="_blank">consult our guidelines</a> for additional information.
          </p>
        </div>
        <div class="translations-content-btns">
          <core-segmentedbuttons>
            <core-segmentedbuttons-item label='Text' slot='segment' (click)="translationContentType='Text'" [selected]="translationContentType === 'Text'"></core-segmentedbuttons-item>
            <core-segmentedbuttons-item *ngIf="featureFlags.documentTab" label='Document' slot='segment' (click)="translationContentType='Document'" [selected]="translationContentType === 'Document'"></core-segmentedbuttons-item>
          </core-segmentedbuttons>
        </div>
      </div>
      <div class="translate-docs" *ngIf="(translationContentType==='Document')">
        <div class="container-docs">
          <div class="left-section">
            <app-document-translations-mobile-notification></app-document-translations-mobile-notification>
            <div class="controls">
              <div class="label-container">
                <label class="label">
                  <span>Select language to translate</span>
                  <p>*</p>
                </label>
                <div class="dropdown-container" >
                <kmd-dropdown [options]="languageList" optionLabelKey="name" [inlineLabel]="dropdownLabel" (onChange)="onLanguageChange($event.selectedOption)"></kmd-dropdown>
                </div>
              </div>
            </div>
            <div class="file-manager-container">
              <div class="top-section">
                <div id="file-manager-modal-upload">
                  <div class="select-documents-title">
                    Select documents
                  </div>
                  <p class="select-documents-description">
                    Upload your files or select from My Docs the files to translate.<br>
                    Retention policy statement.<br>
                    Your documents will be automatically downloaded, or download manually. You’ll find it in your downloads.
                    <br>DO NOT REFRESH OR YOU WILL LOSE YOUR FILES. </p>
                  <app-file-manager-upload class="file-manager-modal-upload" [uploadType]="uploadType" (onCancel)="closeModal()"
                                           (onFileProcessed)="onFileUploaded($event)" (onUploadingInProgress)="onUploadingInProgress($event)" />
                </div>
              </div>
              <div class="middle-section">
                <div id="file-manager-modal-mydocs" *ngIf="(docsExist)">
                  <app-file-manager-my-docs #myDocs [fileType]="fileType" />
                </div>
                <div id="file-manager-action-controls">
                  <app-file-manager-action-controls (onClose)="closeModal()" />
                </div>
                <div class="bottom-section">
                  <button id="translate-doc" kmdFeaturedButton (click)="translateDoc()" [disabled]="!isTranslateDocEnabled()">
                    Translate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="translate-text" *ngIf="(translationContentType==='Text')">
        <div class="container-texts">
          <div class="input-text">
            <div class="label">
              <span>Enter text to translate</span>
              <p>*</p>
            </div>
            <textarea id="inputText" [(ngModel)]="inputText" maxlength="{{maxInputCharLimit()}}"></textarea>
            <div class="bottom-container">
              <countdown-label id="translationsCountdownLabel" [maxlength]="maxInputCharLimit()" [value]="inputText" />
              <span id="clearText" (click)="clearText()" [ngClass]="isClearTextEnabled() ? 'clear-enabled' : 'clear-disabled'">
                <img src="assets/icons/close/size=16px,%20style=mono.svg" id="clearTextIcon"><span>Clear</span>
              </span>
            </div>
          </div>
          <div class="output-text">
            <div class="controls">
              <kmd-dropdown [options]="languageList" optionLabelKey="name" [inlineLabel]="dropdownLabel" (onChange)="onLanguageChange($event.selectedOption)">
              </kmd-dropdown>
              <button id="translate" kmdFeaturedButton (click)="translateText()" [disabled]="!isTranslateTextEnabled()">
                Translate
              </button>
            </div>
            <div>
              <textarea id="response" [(ngModel)]="response" readonly></textarea>
            </div>
            <div class="copy-response-container">
              <span class="copy-button" (click)="copyResponse()">
                <div [ngClass]="isCopyEnabled() ? 'copy-enabled' : 'copy-disabled'">
                  <img src="assets/icons/copy/Size=24px,%20Style=Mono.svg" class="gene-icon" id="copy-response-icon"><span>Copy</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
      <div>Generating response</div>
      <kmd-loading-wheel></kmd-loading-wheel>
    </kmd-modal>
  </div>
</div>
