import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AskOurDataRoleGuardChecker } from '../guard-checkers/ask-our-data-guard-checker';
import { SVA2RoleGuardChecker } from '../guard-checkers/sva2-guard-checker';
import { GuardChecker } from '../guard-checkers/guard-checker-interface';
import { BetaGuardChecker } from "./beta-guard-checker";
import { Injectable } from "@angular/core";
import { BetaGuard } from "../guards/beta.guard";
import { AskOurDataRoleGuard } from "../guards/ask-our-data-role.guard";
import { SVA2RoleGuard } from "../guards/sva2-role.guard";
import { DallEGuard } from "../guards/dall-e.guard";
import { DallEGuardChecker } from "./dall-e-guard-checker";
import {MenuOption} from "@shared/models/navbar/menu-option";

@Injectable({
  providedIn: 'root'
})

export class GuardCheckerService {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  guardCheckers = new Map<Function, GuardChecker>([
    [BetaGuard, new BetaGuardChecker()],
    [AskOurDataRoleGuard, new AskOurDataRoleGuardChecker()],
    [SVA2RoleGuard, new SVA2RoleGuardChecker()],
    [DallEGuard, new DallEGuardChecker()]
  ]);

  areAllOptionGuardsPassed(option: MenuOption): Observable<boolean> {
    if (option.guard && Array.isArray(option.guard) && option.guard.length > 0) {
      let checks$ = option.guard.map(guard => {
        const checker = this.guardCheckers.get(guard.constructor as any);
        return checker ? checker.check(guard) : of(true);
      });

      return combineLatest(checks$).pipe(
        map(values => values.every(value => value === true))
      );
    }
    return of(true);
  }
}
