import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AskOurDocsAddUserRequestList } from "@shared/models/ask-our-docs-add-user-request-list.model";
import { ChipTypeConstant } from "@shared/constants/chip/chip-type";
import { KmdModalService } from "gds-atom-components";
import { map, Observable, switchMap } from "rxjs";
import { AskOurDocsAddUserModalService } from "@services/ask-our-docs/add-user-modal/ask-our-docs-add-user-modal.service";
import { AODAddUserSupportedFormErrors } from "@shared/constants/ask-our-docs/add-user-modal/supported-form-errors";

@Component({
  selector: 'app-ask-our-docs-user-management-add-users-new',
  templateUrl: './ask-our-docs-user-management-add-users-new.component.html',
  styleUrls: ['./ask-our-docs-user-management-add-users-new.component.css']
})
export class AskOurDocsUserManagementAddUsersNewComponent {
  @Input() id: string = "";
  @Output() onSubmit = new EventEmitter<AskOurDocsAddUserRequestList>();

  readonly formGroup: FormGroup;
  readonly roleOptions: { User: string, Admin: string };
  readonly supportedFormErrors = AODAddUserSupportedFormErrors;
  readonly maxAdminLimit: number;
  constructor(
      private kmdModalService: KmdModalService,
      private aodAddUserModalService: AskOurDocsAddUserModalService
  ) {
      ({
          formGroup: this.formGroup,
          roleOptions: this.roleOptions,
          maxAdminLimit: this.maxAdminLimit
      } = this.aodAddUserModalService.properties);
  };

  openModal(): void {
      this.kmdModalService.open(this.id);
  };

  closeModal(): void {
      this.kmdModalService.close(this.id);
  };

  onRoleChange(event: any): void {
      this.aodAddUserModalService.onRoleChange(event);
  };

  get emails(): string[] {
      return this.formGroup.controls['emails'].value || [];
  };

  get ids(): string[] {
      return this.formGroup.controls['ids'].value || [];
  };

  get role(): string {
      return this.formGroup.controls['role'].value || '';
  };

  get typedEmail(): string {
      return (this.formGroup.controls['typedEmail'].value || '').trim();
  };

  get roleLabels(): string[] {
      return Object.values(this.roleOptions);
  };

  get emailCountLabel(): string {
      const count = this.emails.length;
      return count === 1 ? "1 email added" : `${count} emails added`;
  };

  get isConfirmActionsDisabled$(): Observable<boolean> {
      return this.aodAddUserModalService.isActionDisabled$;
  };

  get isFormError$(): Observable<boolean> {
      return this.aodAddUserModalService.isFormError$;
  };

  get isValidForm(): boolean {
      return this.aodAddUserModalService.isValidForm;
  };

  get isEmailInputDisabled(): boolean {
      return this.role === this.roleOptions.Admin && this.emails.length >= this.maxAdminLimit;
  };

  get userInputWrapperClasses$(): Observable<any> {
      return this.isFormError$.pipe(
          switchMap(isFormError => this.isExpectedFormError$(this.supportedFormErrors.MAX_LIMIT).pipe(
              map(isMaxLimitError => ({
                  generalError: isFormError,
                  maxLimitError: isMaxLimitError
              }))
          )),
          map(formErrors => ({
              'general-error': formErrors.generalError && !formErrors.maxLimitError,
              'max-limit-error': formErrors.maxLimitError,
              'disabled': this.isEmailInputDisabled
          })));
  };

  get userInputErrorIconClasses$(): Observable<any> {
      return this.userInputWrapperClasses$.pipe(
          map(errorIconClasses => ({
              'general': errorIconClasses['general-error'],
              'max-limit': errorIconClasses['max-limit-error']
          })));
  }

  onEmailInputKeydown(event: KeyboardEvent): void {
      const isEmailEmpty = !this.typedEmail;

      if (event.key === "Backspace" && isEmailEmpty) {
          return this.aodAddUserModalService.removeLastEmailFromList();
      }

      if (event.key !== "Enter" && event.key !== " ") {
          return;
      }

      event.preventDefault();

      if (isEmailEmpty) {
          return;
      }

      this.aodAddUserModalService.addEmail(this.typedEmail);
      this.aodAddUserModalService.clearTypedEmail();

      this.focusOnEmailInput();
  };

  getChipTypeForEmail$(email: string): Observable<any> {
      return this.aodAddUserModalService.isEmailInvalidForSubmission$(email)
          .pipe(
              map(({ error }) => {
                  if (error === this.supportedFormErrors.INVALID_EMAILS || error === this.supportedFormErrors.DUPLICATE_VALUES) {
                      return ChipTypeConstant.NOTICE;
                  }

                  if (!error || error === this.supportedFormErrors.MAX_LIMIT) {
                      return ChipTypeConstant.DEFAULT;
                  }

                  return ChipTypeConstant.ERROR;
              })
          );
  };

  isExpectedFormError$(error: string): Observable<boolean> {
      return this.aodAddUserModalService.isExpectedFormError$(error);
  };

  focusOnEmailInput() {
      const input = document.getElementById('email-input');

      if (input) {
          input.focus();
      }
  };

  onEmailChipClose(email: string): void {
      this.aodAddUserModalService.removeEmailAndId(email);
  };

  onConfirmActionsClick(): void {
      this.onSubmit.emit({
          role: this.role,
          emails: this.emails,
          ids: this.ids
      });

      this.clearAndClose();
  };

  onCancelClick(): void {
      this.clearAndClose();
  };

  clearAndClose(): void {
      this.aodAddUserModalService.clearEmails();
      this.closeModal();
  };
}