<div class="aod-user-manage-container">
  <div class="aod-user-manage-header">
    <a class="back-to-aod" routerLink="/ask-our-docs-v2">
      <img src="../../../assets/icons/arrows/arrowLeft.svg"><span>Ask Our Docs</span>
    </a>
    <h1 id="repoName">{{ repoName }}</h1>
  </div>
  <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
  <div class="loading-wheel-wrapper" *ngIf="!(allUsers$ | async)">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>
  <div class="aod-user-manage-content" *ngIf="(allUsers$ | async) as page">
    <!-- <div class="tabs-container"> -->
    <kmd-tabs #tabsComponent theme="red">
      <kmd-tab-pane title="Configuration" id="configTab" class="config-tab" activeId="configTab">
        <ng-template kmdTabPaneContent>
          <div class="configuration">
            <h2>Configuration Settings</h2>
          </div>
          <div class=" mb-5 form-container">
            <form [formGroup]="configForm" (ngSubmit)="submitForm()">
              <div class="alert-container">
                <kmd-alerts kmd-success [show]="true" *ngIf="alertType ==='success'" [icon]="true"
                  [dismissible]="true">{{ alertMessage }}</kmd-alerts>
                <kmd-alerts kmd-error [show]="true" *ngIf="alertType ==='error'" [icon]="true">{{ alertMessage
                  }}</kmd-alerts>
                <kmd-alerts kmd-warning [show]="true" *ngIf="alertType ==='warning'" [icon]="true">{{ alertMessage
                  }}</kmd-alerts>
              </div>
              <!-- <div class="form-section">
                  <label for="dropdowns-container">Select your Repository</label>
                  <kmd-dropdown class="dropdowns-container" formControlName="selectedRepo" ngDefaultControl [options]="repoOptions" optionLabelKey="app" [externalLabel]="true"></kmd-dropdown>
                </div> -->

              <div class="form-section">
                <label class="required-label">System instructions</label>
                <div class="input-group">
                  <input class="form-input" placeholder="Input text value" formControlName="system_message"
                    kmdFormInput />
                  <div>
                    <div *ngIf="configForm.get('system_message')?.touched && configForm.get('system_message')?.invalid"
                      class="error-message">System prompt is required</div>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <label class="required-label">About repository</label>
                <div class="input-group">
                  <input class="form-input" placeholder="Input text value" formControlName="usecase_description"
                    kmdFormInput />
                  <div>
                    <div
                      *ngIf="configForm.get('usecase_description')?.touched && configForm.get('usecase_description')?.invalid"
                      class="error-message">This is a required field</div>
                  </div>
                </div>
              </div>
              <div class="toggle-button">
                <div class="toggle-button-form-section">
                  <label for="small-toggle" class="mr-2">Citation Download</label>
                  <kmd-toggle ngDefaultControl formControlName="enable_citation_download"></kmd-toggle>
                </div>
                <div class="ftoggle-button-form-section">
                  <label for="small-toggle" class="mr-2">Show repository link</label>
                  <kmd-toggle ngDefaultControl formControlName="enable_repository_link"></kmd-toggle>
                </div>
              </div>

              <div class="Welcome-message-form-section">
                <div>
                  <label class="welcome-text">Welcome message</label>
                </div>
                <div class="form-selection">
                  <label class="required-label">Title</label>
                  <div class="input-group">
                    <input class="form-input" [value]="title_value" placeholder="Input text value" kmdFormInput
                      readonly />
                  </div>
                </div>
                <div class="form-selection">
                  <label class="required-label text-spacing">Subtitle</label>
                  <div class="input-group">
                    <input class="form-input" placeholder="Input text value" formControlName="subtitle" kmdFormInput />
                    <div>
                      <div *ngIf="configForm.get('subtitle')?.touched && configForm.get('subtitle')?.invalid"
                        class="error-message">This is a required field</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <label for="small-toggle" class="mr-2 prompt-suggestions-label text-spacing">Prompt suggestions</label>
                <kmd-toggle id="enable_prompt" formControlName="enable_tile" ngDefaultControl
                  (change)="togglePrompt()"></kmd-toggle>
                <div formArrayName="tile_prompts">
                  <div class="prompt-row" *ngFor="let row of tilePrompts.controls; let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <div class="title-header-container">
                        <label kmdFormLabel class="required-label">Title</label>
                        <small class="text-muted ml-2">
                          {{row.get('shortPrompt')?.value ? row.get('shortPrompt')?.value.toString().length : 0}}/50
                        </small>
                      </div>
                      <input class="input-group-inline form-input" placeholder="Input text value"
                        formControlName="shortPrompt" kmdFormInput />
                      <div *ngIf="row.get('shortPrompt')?.hasError('duplicate')" class="error-message">Duplicate short
                        prompts are not allowed</div>
                      <div *ngIf="row.get('shortPrompt')?.touched && row.get('shortPrompt')?.invalid"
                        class="error-message">Short prompt is required</div>
                      <div *ngIf="row.get('shortPrompt')?.hasError('tooLong')" class="note-message">Character limit
                        reached. Exceeding this limit may cause the prompt to overflow outside the tile on mobile
                        screens</div>
                    </div>
                    <div class="button-container library-icomoon mb=1">
                      <button kmdFeaturedButton type="button" size="mini" (click)="moveUp(tilePrompts, i)"
                        [disabled]="i==0"><i class="icon-up-arrow"></i></button>
                      <button kmdFeaturedButton type="button" size="mini" (click)="moveDown(tilePrompts, i)"
                        [disabled]="i === tilePrompts.controls.length - 1"><i class="icon-down-arrow"></i></button>
                      <button kmdFeaturedButton type="button" size="mini" (click)="removePrompt(i)"><i
                          class="icon-delete"></i></button>
                    </div>
                    <div class="input-group">
                      <div class="title-header-container">
                        <label kmdFormLabel class="required-label">Prompt</label>
                        <small class="text-muted ml-2">
                          {{row.get('longPrompt')?.value ? row.get('longPrompt')?.value.toString().length : 0}}/500
                        </small>
                      </div>
                      <input type="text" class="form-input" placeholder="Input text value" minlength="1"
                        formControlName="longPrompt" kmdFormInput />
                      <div *ngIf="row.get('longPrompt')?.touched && row.get('longPrompt')?.invalid"
                        class="error-message">Long prompt is required</div>
                    </div>
                  </div>
                  <div class="save-prompt-button-container">
                    <button kmdSecondaryButton type="button" size="mini" (click)="addPrompts()">Add Prompt</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="fixed-footer">
            <div class="submit">
              <button kmdFeaturedButton type="submit" (click)="submitForm()">Save</button>
            </div>
          </div>
        </ng-template>
      </kmd-tab-pane>
      <kmd-tab-pane title="User access" id="userAccessTab">
        <ng-template kmdTabPaneContent>
          <div class="user-access">
            <h2>User Access</h2>
          </div>
          <div class="aod-user-manage-toolbar" [formGroup]="formGroup">
            <div class="toolbar-section left">
              <div class="aod-user-manage-search">
                <input id="search-input" class="search-term" type="text" formControlName="search" kmdFormInput
                  placeholder="Search by User Email" autocomplete="off" />
                <i class="kmd-icon-close-mono kmd-close-black-icon search-close" *ngIf="!isSearchEmpty()"
                  (click)="clearSearch()"></i>
              </div>
              <div class="aod-user-manage-role-filter">
                <kmd-dropdown id="role-dropdown" formControlName="role" [options]="roleFilters" inlineLabel="Role"
                  (onChange)="onRoleDropdownChange($event)"></kmd-dropdown>
              </div>
            </div>
            <div class="toolbar-section right">
              <div id="assign-role" class="toolbar-control" [class.aod-btn-disabled]="selectedUsers.size === 0">
                <div id="assign-role-btn" (click)="toggleAssignRoles()">
                  <label>Assign role</label>
                  <img
                    src="{{ showRoles ? '../../../assets/icons/caret/caretUp-11px-mono-blue.svg' : '../../../assets/icons/caret/caretDown-11px-mono-blue.svg' }}">
                </div>
                <div id="assign-aod-roles" [hidden]="!showRoles">
                  <core-contextmenu>
                    <core-contextmenu-item height='42px' label='User' variant='default' id="aod-user-role"
                      (click)="revokeAdminAccess()" />
                    <core-contextmenu-item height='42px' label='Admin' variant='default' id="aod-admin-role"
                      (click)="grantAdminAccess()" />
                  </core-contextmenu>
                </div>
              </div>
              <div id="remove-users-btn" class="toolbar-control" (click)="openRemoveUsersModal()"
                [class.aod-btn-disabled]="selectedUsers.size === 0">
                <img src="../../../assets/icons/bin/aod-bin.svg">
                <label>Remove</label>
              </div>
              <div id="add-users-btn" class="toolbar-control" (click)="openAddUsersModal()">
                <img src="../../../assets/icons/plus/plus-24px-mono.svg">
                <label>Add users</label>
              </div>
            </div>
          </div>
          <div class="aod-user-manage-results-counter" *ngIf="!isSearchEmpty()">
            <span class="aod-results-counter-span">
              {{
              isSearchEmpty() ? page.totalElements + ' results' : page.totalElements + ' results for "' +
              formGroup.controls.search.value + '"'
              }}
            </span>
          </div>
          <div class="aod-user-selected-bar" *ngIf="selectedUsers.size > 0">
            <span>{{ getSelectionCounterLabel(selectedUsers.size) }}</span>
            <a class="aod-clear-selection" (click)="clearSelection()">
              <img src="../assets/icons/close/size=16px, style=mono.svg"> Clear selection
            </a>
          </div>
          <div class="aod-user-manage-table">
            <div class="aod-table-wrapper">
              <table *ngIf="page.content.length > 0; else noUserView">
                <thead>
                  <tr class="table-head-tr">
                    <th class="head-checkbox">
                      <input #headCheckbox class="head-item table-th-checkbox" type="checkbox"
                        (click)="toggleCheckboxes($event,true)" />
                    </th>
                    <th class="head-user-email">
                      <div class="head-item" (click)="sortByEmail()">
                        <label>User email</label>
                        <div class="sorting-action-icon">
                          <i class="icon-caret-up user-email-up"></i>
                          <i class="icon-caret-down user-email-down"></i>
                        </div>
                      </div>
                    </th>
                    <th class="head-role">
                      <div class="head-item" (click)="sortByRole()"
                        [ngClass]="activeRoleFilter !== ALL_ROLES_FILTER ? 'disabled-role-sort' : ''">
                        <label>Role</label>
                        <div class="sorting-action-icon">
                          <i class="icon-caret-up role-up"></i>
                          <i class="icon-caret-down role-down"></i>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-body-tr" *ngFor="let user of page.content"
                    [ngClass]="{'tr-checked': selectedUsers.has(user.id)}">
                    <td class="body-checkbox">
                      <input id="user-checkbox" class="body-item table-td-checkbox" type="checkbox" [value]="user.id"
                        [checked]="selectedUsers.has(user.id)" (click)="toggleCheckboxes($event,false)" />
                    </td>
                    <td class="body-user-email">
                      <div class="body-item">
                        {{ user.email }}
                      </div>
                    </td>
                    <td class="body-role">
                      <div class="body-item">
                        {{ user.role }}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="aod-user-manage-pagination" *ngIf="page.content.length > 0">
                <kmd-pagination #pagination [totalResults]="page.totalElements" (onPageChange)="onPageChange($event)"
                                [resultsPerPage]="RESULTS_PER_PAGE" [resultsPerPageOptions]="[20, 100, 200]">
                </kmd-pagination>
              </div>
            </div>
            <div class="aod-no-search-results" *ngIf="page.content.length === 0 && !isSearchEmpty()">
              <img src="../../../assets/images/search.svg">
              <p class="no-matches">No matches for "{{ formGroup.controls.search.value }}"</p>
              <p class="advice">Try using different terms</p>
            </div>
          </ng-template>
        </kmd-tab-pane>
        <kmd-tab-pane title="Feedback form" id="feedbackFormTab">
          <ng-template kmdTabPaneContent>
            <div class="feedback-form">
              <!-- Calling admin feedback form component -->
              <app-admin-landing-feedback-form [indexName]="indexName" (redirectionToCustomizableFeedback)="navigateToFeedback()"></app-admin-landing-feedback-form>
            </div>
          </ng-template>
        </kmd-tab-pane>
      </kmd-tabs>
    <!-- </div> -->
  </div>
  <ng-template #noUserView>
    <div class="aod-user-manage-no-user-view" *ngIf="isSearchEmpty()">
      <h2 class="aod-user-manage-no-user-content">There's no assigned admins or users</h2>
    </div>
  </ng-template>
</div>

<app-ask-our-docs-user-management-add-users id="addUsersModal" (onSubmit)="addUsers($event)" #addUsersModal />
<app-ask-our-docs-user-management-remove-users [id]="groupId" [users]="selectedUsers" [admins]="repoAdmins"
  #removeUsersModal (afterRemoveUsers)="reloadTable()" />

<kmd-modal id="revokeSelfAdminModal" headline="Change your role" modalSize="mini">
  <p class="modal-message">
    You cannot change your own role from the repository. Please ask another admin to change your role.
  </p>
  <div class="aod-buttons-container">
    <button class="cancel-button" kmdFeaturedButton (click)="closeModal()">Close</button>
  </div>
</kmd-modal>