<app-modal [id]="id" [headline]="headline" [subHeadline]="subHeadline">
  <div #modalContent class="modal-inner-content" [ngClass]="{'scrollable': scrollableModal}">
    <div class="top-modal-content">
      <div class="share-to-wrapper">
        <label class="input-title">
          Share to<label class="required">*</label>
        </label>
        <div class="custom-select-wrapper">
          <div class="share-to-input-wrapper">
            <div class="share-to-dropdown-selector" (click)="onShareToDropdownClick()" [ngClass]="{ 'disabled': isDropdownDisabled }"
              (clickOutside)="onShareToDropdownOutsideClick()">
              <p>{{selectedShareToOption.label}}</p>
              <i class="dropdown-caret" [ngClass]="{'up': isShareToMenuOpen}"></i>
            </div>
            <div class="share-to-dropdown-options" *ngIf="isShareToMenuOpen">
              <ng-container *ngFor="let option of shareToOptions">
                <div [ngClass]="!option.disabled ? 'share-to-option' : 'disabled-option'" *ngIf="!option.hideOption"
                  (click)="!option.disabled && onSelectionChanged(option)">
                  <p>{{option.label}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="loadingGroups" class="loading-groups-spinner">
            <kmd-loading-wheel></kmd-loading-wheel>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isPublicAgentShareFlow">
        <div class="agreement-container">
          <p class="agreement-text">
            By sharing your agent you are agreeing to display your name in the agent information. 
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="displayClosedGroupForm">
        <form id="group-selection-form" [formGroup]="formGroup">
          <div class="group-form-container">
            <div class="title-container">
              <label class="input-title">
                Add from your Microsoft group<label class="required">*</label>
              </label>
            </div>
            <div class="group-input-wrapper">
              <div id="group-input" class="form-group-input-field">
                <ng-container *ngIf="!isGroupSelected">
                  <input type="text" id="group-name-input" (focus)="onGroupNameInputFocus()"
                    formControlName="typedGroupName" (clickOutside)="onGroupNameInputOutsideClick()" autocomplete="off">
                </ng-container>
                <ng-container *ngIf="isGroupSelected">
                  <div class="group-details-wrapper">
                    <div class="group-details">
                      <p>{{selectedGroup!.displayName}}</p>
                      <p *ngIf="groupMembers.length" id="group-member-count" (click)="showMemberListModal()">{{groupMemberCountLabel}}</p>
                    </div>
                    <i class="close-btn" (click)="clearSelectedGroup()"></i>
                  </div>
                </ng-container>
              </div>
              <div id="group-dropdown" *ngIf="filteredGroups.length && displayGroupList">
                <ng-container *ngFor="let group of filteredGroups">
                  <div class="group-item" (click)="onGroupItemClick(group)">
                    <p class="group-name">{{ group.displayName }}</p>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="filteredGroups.length === 0 && displayGroupList">
                <p>No groups found matching your search. Please try a different name.</p>
              </div>
            </div>
            <p class="input-description">
              Enter your Microsoft group name or reference the <a
                href="https://myaccount.microsoft.com/groups/groups-i-belong-to" target="_blank">groups you are a member
                of</a>.
            </p>
          </div>
          <div class="group-form-container">
            <div class="title-container">
              <label class="input-title">
                Add admin emails (max {{maxAllowedAdmins}} users)<label class="required">*</label>
              </label>
            </div>
            <div id="emails-input-wrapper" class="form-group-input-field"
              [ngClass]="{'disabled-input': isEmailInputDisabled}">
              <ng-container *ngIf="admins && admins.controls.length">
                <ng-container *ngFor="let adminCtrl of admins.controls; let i = index">
                  <app-chip [text]="adminCtrl.value.mail" [closeable]="i > 0" [type]="getChipType(adminCtrl)" (close)="onAdminChipClose(adminCtrl)" />
                </ng-container>
              </ng-container>
              <div *ngIf="loadingMembers" class="loading-emails-spinner">
                <kmd-loading-wheel></kmd-loading-wheel>
              </div>
              <input appNoSpaces (keydown)="onNewAdminChipKeyDown($event)"
                *ngIf="!loadingMembers || groupMembers.length === 0" type="text" id="emails-input" autocomplete="off"
                formControlName="typedEmail">
            </div>
            <div class="emails-input-bottom">
              <span id="selected-emails-count" class="input-description">{{selectedGroupAdmins.length}}/{{maxAllowedAdmins}}</span>
              <div *ngIf="admins.errors" class="error-messages">
                <p id="duplicate-emails-error" class="error-message notice" *ngIf="admins.hasError('duplicateMember')">
                  Duplicate emails are not allowed.
                </p>
                <p id="user-not-in-group-error" class="error-message error" *ngIf="admins.hasError('userNotInGroup')">
                  These email addresses are not recognized. Please enter valid company email(s).
                </p>
              </div>
            </div>
          </div>
          <div class="agreement-container">
            <p class="agreement-text">
              By sharing your agent you are agreeing to display your name and any other admins under the “Admin:” field.
            </p>
            <div class="guidelines-wrapper">
              <core-checkbox label="" (onClick)="toggleGuidelinesCompliance()" [checked]="isComplianceChecked" />
              <label>
                I confirm I've read and comply with <a href="https://geneai.thermofisher.com/guidelines"
                  target="_blank">Agent
                  creation guidelines.</a>
              </label>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="modal-buttons-container" [ngClass]="{'basic-modal-buttons': !displayClosedGroupForm }">
      <ng-container *ngIf="displayClosedGroupForm">
        <div class="find-group-help-link-wrapper">
          <a href="" target="_blank">Can't find your group?</a>
        </div>
      </ng-container>
      <div class="modal-buttons">
        <button id="discard-changes-btn" kmdInfoButton (click)="closeModal()">Cancel</button>
        <button id='continue-editing-btn' kmdFeaturedButton [disabled]="isFormSubmitDisabled" (click)="onClickHandler()">Continue</button>
      </div>
    </div>
  </div>
</app-modal>
<app-agent-sharing-confirmation-modal #confirmationModal [isPublicShare]="isPublicAgentShareFlow" [sharingDetails]="sharingDetails" [group]="selectedGroup!" [forceDisable]="isRequestProcessing"
  [admins]="selectedGroupAdmins" (onEditAgentClickEvent)="closeModal()" (onShareAgentClickEvent)="saveAndShare()" (onSharePublicAgentClickEvent)="saveAndSharePublic()"/>
<app-agent-sharing-group-member-list-modal #memberListModal [group]="selectedGroup" [groupMembers]="groupMembers" />