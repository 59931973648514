import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client'

@Injectable()
export class BasehttpInterceptor implements HttpInterceptor {

  constructor(@Inject('BASE_API_URL') private baseUrl: string,
              @Inject('ASK_DATA_BASE_API_URL') private APIbaseUrl: string,
              private oidcSecurityService: OidcSecurityService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!request.url.includes(this.baseUrl)
      && !request.url.includes(this.APIbaseUrl) && !request.url.includes("v1/get_file")) {
      return next.handle(request);
    }


    return this.oidcSecurityService.getAccessToken().pipe(
      map((idToken) => request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + idToken) })),
      flatMap((newRequest) => next.handle(newRequest))
    )
  }
}
