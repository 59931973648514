import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '@app/shared/models/user-data.model';
import { AgentListPagedComponent } from "@app/agents/agent-list-paged/agent-list-paged.component";

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AgentListComponent implements OnInit {
  @ViewChild('privateAgents') privateAgents?: AgentListPagedComponent;
  @ViewChild('publicAgents') publicAgents?: AgentListPagedComponent;
  @ViewChild('sharedAgents') sharedAgents?: AgentListPagedComponent; 

  isUserApprover: boolean = false;
  myAgentsTabSelected: boolean = true;
  publicAgentTabSelected: boolean = false;
  sharedAgentsTabSelected: boolean = false;
  userData$!: Observable<UserData>;
  tabChangeEvents = {
    MY_AGENTS: 'my-agents',
    PUBLIC_AGENTS: 'public-agents',
    SHARED_AGENTS: 'shared-agents' 
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.userData$ = this.oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    )
    this.route.queryParams.subscribe(params => {
      this.tabSelectedInit(params['tab']);
    });

    this.checkForApproverRole();
  }

  checkForApproverRole(): void {
    this.route.data
      .pipe(map(data => data['oidcRoles']))
      .subscribe((roles) => {
        if (!roles) return;

        this.isUserApprover = roles.includes("agent-request-approver")
      });
  };

  hasPublicTabSelected(tabName: string): boolean {
    return tabName === this.tabChangeEvents.PUBLIC_AGENTS;
  }

  hasSharedTabSelected(tabName: string): boolean {
    return tabName === this.tabChangeEvents.SHARED_AGENTS;
  }

  tabSelectedInit(tabName: string) {
    if (this.hasPublicTabSelected(tabName)) {
      this.myAgentsTabSelected = false;
      this.publicAgentTabSelected = true;
      this.sharedAgentsTabSelected = false;
    } else if (this.hasSharedTabSelected(tabName)) {
      this.myAgentsTabSelected = false;
      this.publicAgentTabSelected = false;
      this.sharedAgentsTabSelected = true;
    }
  }

  handleTabChange(event: string): void {
    switch (event) {
      case this.tabChangeEvents.MY_AGENTS:
        if (this.privateAgents) {
          this.privateAgents.emptySearchText();
          this.privateAgents.reloadAgents();
        }
        break;
      case this.tabChangeEvents.PUBLIC_AGENTS:
        if (this.publicAgents) {
          this.publicAgents.emptySearchText();
          this.publicAgents.reloadAgents();
        }
        break;
      case this.tabChangeEvents.SHARED_AGENTS:
        if (this.sharedAgents) {
          this.sharedAgents.emptySearchText();
          this.sharedAgents.reloadAgents();
        }
        break;
      default:
        break;
    }
  };

  goToCreateNewAgent() {
    this.router.navigate(["/agents/builder"])
  }
}