import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { sva2Roles } from "@shared/constants/roles/roles";

@Injectable({
  providedIn: 'root'
})
export class SVA2RoleGuard  {

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private router: Router,
  ) {
  }

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.hasSVA2Roles();
  }

  hasSVA2Roles() {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => {
        let roles = userData?.userData?.['roles'];
        return roles?.some((role: string) => role == sva2Roles.Index) || false;
      })
    );
  }

}
