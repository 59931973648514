import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsGroupAdminGuard  {

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private groupMembershipService: GroupMembershipService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAskOurDocRepoGroupAdminRole(route.params['groupId']).pipe(map((hasGroupAdmin) => {
      if (hasGroupAdmin){
        return true;
      }else{
        return this.router.createUrlTree(['/error']);
      }
    }));
  }

  hasAskOurDocRepoGroupAdminRole(groupId: string): Observable<boolean> {
    return this.oidcIdentityService.userData$.pipe(
      switchMap(userData => {
        return this.groupMembershipService.getGroupOwners(groupId).pipe(
          map(ownersResponse$ => {
            return ownersResponse$.value.some((owner: any) => owner.mail === userData.userData?.['email']);
          })
        );
      }),
      catchError(() => of(false))
    );
  }

}

