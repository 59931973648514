<div class="header-section">
  <div class="repo-name-container" (clickOutside)="closeDropdownMenu()">
    <span (click)="repoDropdownTrigger()" class="repo-name">
      {{repositoryName}} <img src="../../../assets/icons/ask-our-docs/caret-down.svg">
    </span>
    <app-context-menu #modelContextMenu [ngClass]="{'closed': !showModelDropdown}" [items]="modelList"
      [slideDirection]="'down-to-up'" [isRepoDropdown]="true" (isContextMenuOpen)="isContextMenuOpen($event)"
      (itemClicked)="menuItemSelected(($event))" />
  </div>
</div>

<app-prompt-page *ngIf="!isChatInitiated" (exampleSelectEvent)="setPrompt($event)" [banner]="true"
  [showHeaderMessage]="true" [repoDetails]="repoDetails" [userName]="userName" [promptExamplesType]="'ASK_GENE'" />

<div class="container" *ngIf="isChatInitiated">
  <div class="response-section">
    <ng-container *ngFor="let item of conversation.messages; let i = index">
      <div *ngIf="item.role === 'user'" class="user-prompt-box">
        <div class="response-message-section">
          <span>{{item.content}}</span>
        </div>
        <div class="icons-section">
          <div class="part-one">
            <!-- commenting this part of code for now will be uncommented later -->
            <!-- <svg class="size-16px" id="save-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" [kmdTooltip]="savePromptTooltipContent" tooltipPlacement="bottom">
              <path d="M15 5.83002H13V8.83002H15V5.83002Z" fill="#1B1B1D" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16.99 3H3V21H21V7L16.99 3ZM7.5 5H16.5V10H7.5V5ZM18.5 18.5H5.5V12.5H18.5V18.5Z" fill="#1B1B1D" />
            </svg>
            <kmd-tooltip-content #savePromptTooltipContent>
              <span>Save prompt</span>
            </kmd-tooltip-content> -->
          </div>
          <div class="part-two" (click)="copyUserPrompt(item.content)">
            <svg id="copy-bold.svg" width="17" height="17" viewBox="0 0 24 28" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.4464 5.51717L5.4464 15.9433H3L3 3L15.9727 3L15.9727 5.51717L5.4464 5.51717ZM21 7.0066L6.94254 7.0066L6.94254 21H21V7.0066Z"
                fill="#1B1B1D" />
            </svg>
            <span>Copy</span>
          </div>
        </div>
      </div>
      <div *ngIf="item.role === 'assistant'" class="response-box">
        <div class="header-section">
          <img src="../../../assets/icons/chat-assistant/chat-assistant-icon.svg" alt="">
          <div style="display: flex; flex-direction: row;" class="generating-response-container" *ngIf="item.content === ''">
            <div>
              <kmd-loading-wheel></kmd-loading-wheel>
            </div>
            <div>
              <span> Generating response...</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="item.content !== ''">
          <div class="response-message-section">
            <markdown [data]="item.content"></markdown>
          </div>
          <ng-container *ngIf="item.citations?.length > 0">
            <div class="expandable-references-section">
              <div class="reference-header">
                <div class="reference-section">
                  <p-accordion class="reference-links-accordian" (onOpen)="onTabOpen()" (onClose)="onTabClose()">
                    <p-accordionTab>
                      <ng-template pTemplate="header">
                        <div class="accordian-header-container">
                          <div class="title-container">
                            <div class="accordian-header-border">
                              <div class="icon-container">
                                <img class="quotation-closed-icon"
                                  src="../../../assets/icons/ask-our-docs/quotation-closed.svg" alt="">
                              </div>
                            </div>
                            <div>
                              <div>
                                <h4 class="reference-heading">Reference</h4>
                              </div>
                              <div class="reference-sub-heading-container">
                                <span class="reference-sub-heading">
                                  These are the sources Gen.AI used to reference the response.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="reference-part-icon-container">
                            <svg class="chevron-reference-section" *ngIf="!isExpanded" id="chevron-down-mono.svg"
                              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.59 6.28999L22 7.70999L12 17.71L2 7.70999L3.42 6.28999L12 14.88L20.59 6.28999Z"
                                fill="#1B1B1D" />
                            </svg>
                            <svg class="chevron-reference-section" *ngIf="isExpanded" id="chevron-up-mono.svg"
                              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.59 17.71L22 16.29L12 6.28999L2 16.29L3.42 17.71L12 9.11998L20.59 17.71Z"
                                fill="#1B1B1D" />
                            </svg>
                          </div>
                        </div>
                      </ng-template>
                      <ng-container *ngIf="item.citations">
                        <div *ngIf="!isMobileScreen()" class="links-container">
                          <div class="reference-link-box" *ngFor="let citation of item.citations.slice(0,3)">
                            <span class="link" (click)="openSourceFile(citation)">{{ decodeLink(citation.filepath)
                              }}</span>
                            <div class="file-type-container">
                              <span class="file-type">[{{ getFileType(citation.filepath) }}]</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isMobileScreen()" class="links-container-mobile">
                          <div class="reference-link-box-mobile" *ngFor="let citation of item.citations.slice(0,3)">
                            <span class="link" (click)="openSourceFile(citation)">{{ decodeLink(citation.filepath)
                              }}</span>
                            <div class="file-type-container">
                              <span class="file-type">[{{ getFileType(citation.filepath) }}]</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isMobileScreen()" class="links-container-mobile">
                          <div class="reference-link-box-mobile" *ngFor="let citation of item.citations.slice(0,3)">
                            <span class="link" (click)="openSourceFile(citation)">{{ replaceSpaces(citation.filepath)
                              }}</span>
                            <div class="file-type-container">
                              <span class="file-type">[{{ getFileType(citation.filepath) }}]</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.citations?.length > 3">
                          <span class="see-all-references-text" (click)="openDrawer(item.citations)">See all
                            references</span>
                        </div>
                      </ng-container>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="icons-section">
            <div class="part-one">
              <!-- <div class="download-icon-container">
                Commenting this for now and will be used later -->
                <!-- <svg class="size-16px" id="download-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg" [kmdTooltip]="downloadTooltipContent" tooltipPlacement="bottom">
                  <path d="M8.62766 6.10651V1.99951H15.8277V6.10651H20.4937L11.9957 15.9545L3.50466 6.10651H8.62766Z"
                    fill="#1B1B1D" />
                  <path d="M21.3151 22.0005H2.68506V18.9995H21.3151V22.0005Z" fill="#1B1B1D" />
                </svg>
                <kmd-tooltip-content #downloadTooltipContent>
                  <span>Download</span>
                </kmd-tooltip-content> 
              </div> -->
              
              <div (click)="showFeedbackForm(item, true)" class="thumbs-up-icon-container" [class.selected]="item.selectedFeedback === 'useful'">
                <svg class="size-16px" id="thumbs-up-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg" [kmdTooltip]="usefulTooltipContent" tooltipPlacement="bottom">
                  <path d="M6.33006 19.4171V8.4401H1.56616V19.4171H6.33006Z" [ngClass]="{'blue-fill': item.selectedFeedback === 'useful'}" />
                  <path
                    d="M10.1758 19.4229C9.56765 18.9764 8.88338 18.6897 8.33006 18.5118V9.5989H9.19076C9.22176 9.6099 9.92276 9.5999 11.3278 8.5179C13.1908 7.0849 15.4228 4.9299 15.9248 3.5839C16.2058 2.8279 16.7168 2.6429 16.9958 2.6009C17.4778 2.5239 17.9858 2.7549 18.3428 3.2139C18.8998 3.9279 18.9718 4.9669 18.5378 5.9909C18.0158 7.2279 17.6488 8.1719 17.4308 8.7529H20.1578C21.3468 8.7649 22.1068 9.6309 22.1068 10.7109C22.1068 11.2149 21.9148 11.6749 21.6028 12.0229C22.1048 12.3769 22.4338 12.9639 22.4338 13.6259C22.4338 14.3509 22.0398 14.9839 21.4568 15.3219C21.7218 15.6559 21.8808 16.0799 21.8808 16.5399C21.8808 17.3639 21.3708 18.0719 20.6518 18.3609C20.8628 18.6729 20.9868 19.0499 20.9868 19.4549C20.9868 20.5349 20.1128 21.4129 19.0378 21.4129C18.9831 21.4129 18.9232 21.4131 18.8583 21.4132C17.3039 21.4174 12.9068 21.4292 10.1758 19.4229Z"
                    [ngClass]="{'blue-fill': item.selectedFeedback === 'useful'}" />
                </svg>
                <kmd-tooltip-content #usefulTooltipContent>
                  <span>Useful</span>
                </kmd-tooltip-content>
              </div>
              <div (click)="showFeedbackForm(item, false)" class="thumbs-down-icon-container" [class.selected]="item.selectedFeedback === 'notUseful'">
                <svg class="size-16px" id="thumbs-down-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg" [kmdTooltip]="notUsefulTooltipContent" tooltipPlacement="bottom">
                  <path
                    d="M8.33011 5.48813V14.4011H9.18981C9.22181 14.3901 9.92181 14.4001 11.3278 15.4821C13.1908 16.9151 14.4228 19.0701 14.9238 20.4161C15.2058 21.1721 15.7168 21.3571 15.9958 21.3991C16.4778 21.4761 16.9858 21.2451 17.3428 20.7861C17.8998 20.0721 17.9718 19.0331 17.5378 18.0091C17.2229 17.2627 17.3284 16.6231 17.4137 16.1054C17.4699 15.7651 17.5173 15.4775 17.4308 15.2471H20.1578C21.3468 15.2351 22.1058 14.3691 22.1058 13.2891C22.1058 12.7851 21.9148 12.3251 21.6028 11.9771C22.1048 11.6231 22.4338 11.0361 22.4338 10.3741C22.4338 9.64907 22.0398 9.01607 21.4568 8.67807C21.7218 8.34407 21.8808 7.92007 21.8808 7.46007C21.8808 6.63607 21.3708 5.92807 20.6518 5.63907C20.8628 5.32707 20.9868 4.95007 20.9868 4.54507C20.9868 3.46507 20.1128 2.58707 19.0378 2.58707C18.9832 2.58707 18.9232 2.58691 18.8584 2.58673C17.3039 2.58257 12.9068 2.57079 10.1758 4.57707C9.5677 5.02355 8.88343 5.31031 8.33011 5.48813Z"
                    [ngClass]="{'blue-fill': item.selectedFeedback === 'notUseful'}" />
                  <path d="M6.33011 15.5604V4.58237H1.56641V15.5604H6.33011Z" [ngClass]="{'blue-fill': item.selectedFeedback === 'notUseful'}" />
                </svg>
                <kmd-tooltip-content #notUsefulTooltipContent>
                  <span>Not useful</span>
                </kmd-tooltip-content>
              </div>
            </div>
            <div class="part-two" (click)="copyAssistantPrompt(item.content)">
              <svg class="size-16px" id="copy-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.4464 5.51717L5.4464 15.9433H3L3 3L15.9727 3L15.9727 5.51717L5.4464 5.51717ZM21 7.0066L6.94254 7.0066L6.94254 21H21V7.0066Z"
                  fill="#1B1B1D" />
              </svg>
              <span>Copy</span>
            </div>
          </div>
          <!-- Feedback Form -->
          <app-feedback-form [currentIndex]="i" [userEmail]="userEmail" [indexName]="id" [conversation]="conversation" [userName]="userName" [repoDetails]="repoDetails" [answerHelpful]="answerHelpful" (closeForm)="closeFeedbackForm(item)" *ngIf="item.showFeedbackForm"></app-feedback-form>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div class="prompt-settings-wrapper">
  <app-drawer class="reference-drawer" [attr.attributeName]="isAppDrawerOpen ? 'open' : 'close'" appClickOutsideDrawer
    (appClickOutsideDrawer)="isAppDrawerOpen && closeDrawer()" [position]="'right'"
    (onStateChange)="onDrawerStateChange($event)">
    <span title="Reference">
      <span class="title">Reference</span>
      <p class="sub-title-text">These are the sources Gen.AI used to reference the response.</p>
    </span>
    <ng-container *ngIf="currentCitations as citations">
      <div class="reference-container">
        <span class="sources-used-text">{{citations.length}} Sources used</span>
        <div class="reference-box" *ngFor="let citation of citations; let i = index"
          [style.height]="expandedIndex === i ? 'auto' : '14rem'">
          <blockquote class="citation">
            {{ expandedIndex === i ? citation.content : (citation.content | slice:0:100) }}
            <span class="toggle-text" (click)="toggleText(i)">
              {{ expandedIndex === i ? 'Read less' : 'Read more' }}
            </span>
          </blockquote>
          <span class="link" (click)="openSourceFile(citation)" style="cursor: pointer;">
            {{ decodeLink(citation.title) }}
          </span>
          <span class="file-type">[{{ getFileType(citation.title) }}]</span>
        </div>
      </div>
    </ng-container>
  </app-drawer>
</div>

<!-- Repository chat history -->
<kmd-modal *ngIf="!isMobileScreen()" [id]="'repositoryHistory'" modalSize="large">
  <app-ask-our-docs-v2-chat-history (conversationChatHistory)="conversationChatHistoryClicked($event)" [internalIndexName]="internalIndexName" #chatHistoryComponent></app-ask-our-docs-v2-chat-history>
</kmd-modal>  

<!-- Chat History Mobile -->
<div #popOver2 *ngIf="isMobileScreen()" class="repository-history-mobile">
  <p-dialog [modal]="true" [(visible)]="visible" [position]="position" [dismissableMask]="true"
    [showHeader]="false" [style]="{ width: '100%', height: '90%' }">
    <div class="chat-history-container-mobile">
      <div class="pull-bar"> </div>
      <app-ask-our-docs-v2-chat-history (conversationChatHistory)="conversationChatHistoryClicked($event)" [internalIndexName]="internalIndexName" #chatHistoryComponent></app-ask-our-docs-v2-chat-history>
    </div>
  </p-dialog>
</div>

<!-- About Repository Modal -->
<kmd-modal id="about-repository-modal" class="custom-modal"  modalSize="medium">
  <app-about-repository [repositoryName]="repositoryName" [repoOwner]="repoOwner" [last_run_date]="last_run_date" [repositorydetails]="repositorydetails" [linkrequired]="linkrequired" [repositorylink]="repositorylink" ></app-about-repository>
</kmd-modal>

<app-chat-wrapper class="ask-our-docs-new-chat-wrapper" [addFileButtonVisibility]="false"
  [modelChangeButtonVisibility]="false" [promptSettingsButtonVisibility]="false" [contentPosition]="'bottom'"
  (onPromptSubmit)="onPromptSubmit($event)">
</app-chat-wrapper>