<div class="agent-chat-container">
  <kmd-alerts kmd-success id="success-alert" [(show)]="alertVisible" [icon]="true"
              [alertText]="'New agent created, now you can chat with it.'" [floating]="true"
              [dismissible]="true"></kmd-alerts>
  <div class="agent-chat-toolbar">
    <div class="agent-chat-toolbar-content max-width-agent-chat">
      <div class="toolbar-buttons">
        <a id="backtoAgents" (click)="backToAgents()" class="back-to-agents">
          <img src="../assets/icons/arrows/arrowLeft.svg"> Back to Agents</a>

        <div class="agent-chat-toolbar-buttons">
          <button kmdFeaturedButton class="agent-btn mobile" (click)="navigateToAgentChat()">
            <img class="icon-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">New Chat
          </button>
        </div>
      </div>
      <div class="agent-name-like-container">
        <div class="agent-information">
          <h1>{{ agent?.name }}</h1>
          <app-agent-rating *ngIf="displayRating()" agent-rating [agent]="agent" [displayLikeCountOnly]="false"/>
          <p class="last-update" *ngIf="isPublicAgent() && agent?.updated">Last
            update: {{ getFormattedDate(agent?.updated!) }}</p>
        </div>
        <button kmdFeaturedButton class="agent-btn desktop" (click)="navigateToAgentChat()">
          <img class="icon-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">New Chat
        </button>
      </div>
      <p class="agent-description">{{ agent?.description }}</p>
    </div>
  </div>
  <div class="max-width-agent-chat" id="chat-list-container">
    <div id="chat-list">
      <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
      <div class="empty-message-container" *ngIf="selectedChatId == undefined; else messagesChat">
        <p>
          Remember to check any Agent responses for accuracy.
          NOTE: Agents should <strong>not</strong> be relied upon for legal or
          scientific advice or be used for making decisions about people.
          For more guidelines relating to Agents,
          please see the <a href="/guidelines" target="_blank">Gene.AI Guidelines</a>.
        </p>
        <img id="agent-avatar-image" src="../../../../assets/images/agent-avatar.png"/>
        <h2>{{ agent?.name }}</h2>
        <p id="empty-message-agent-description" *ngIf="!hasConversationStarters(); else conversationStarters">{{
            agent?.description
          }}</p>
        <ng-template #conversationStarters>
          <app-agent-conversation-starter [conversationStarters]="agent?.conversationStarters!"
                                          (conversationStarterEvent)="handleConversationStarter($event)"></app-agent-conversation-starter>
        </ng-template>
      </div>
      <ng-template #messagesChat>
        <div class="table" #table [scrollTop]="table.scrollHeight">
          <app-chat #appChat [username]="(userData$ | async)?.name || 'NA'" [chatType]="'agents'"
                    [generatingResponse]="generatingResponse"
                    [streamingResponse]="streamingResponse"
                    [(lastUserMessage)]="lastUserMessage"
                    [(lastSystemMessage)]="lastSystemMessage"
                    [chatId]="selectedChatId" (responseSelectEvent)="handleResponseSelected($event)"
                    [showSavePrompt]=false></app-chat>

        </div>
        <button id="stopGenerateButtonAgent" *ngIf="displayStopGenerateButton()"
                class="stop-generate-button-agent" kmdInfoButton kmdBlockButton
                (click)="stopGenerateResponse()">
          <img src="assets/icons/stop/stop-bold.svg"/>
          Stop Response
        </button>
      </ng-template>
    </div>
    <app-agent-rating-button *ngIf="isPublicAgent() && selectedChatId" [agent]="agent"
                             (onRatedEvent)="onRatedEvent($event)"/>
    <app-prompt-input #promptInputComponent class="prompt-input-padding-bottom"
                      [username]="(userData$ | async)?.name || 'NA'" [chatId]="selectedChatId"
                      [promptSettings]="promptSettings" [disabled]="isSubmitDisabled"
                      (promptSentEvent)="onSubmit($event)" [showPromptSettingsButton]="false"></app-prompt-input>
  </div>
</div>
<kmd-alerts kmd-error id="rating-error-alert" [(show)]="showRatingError" [dismissible]="true" [floating]="true"
            alertText="An error occurred while rating the agent. Please try again later."/>
<kmd-alerts id="invalidPromptAlert" kmd-warning [(show)]="showInvalidPrompt"
            [alertText]="invalidPromptText"></kmd-alerts>
