
export const SortOptions = [
    {app: 'Sorting A - Z', value: {id: 1, name: 'Sorting A - Z', code: 1}, code: 1},
    {app: 'Sorting Z - A', value: {id: 1, name: 'Sorting Z - A', code: -1}, code: -1}];

export type SortOption = typeof SortOptions[number];

export interface DBList {
    id: number;
    name?: string;
    friendly_name?: string;
    role_suffix?: string;
    description?: string;
};

export interface TableList {
    database_name: string;
    role_suffix: string;
    tables_views_list: {
        name: string;
        is_selected: string;
        state: string;
        type?: string;
    }[];
}

export const DatabaseTypes = [{app: 'Microsoft SQL server', value: 'mssql', code: 'mssql'},
     {app: 'Oracle', value: 'ORACLE', code: 'ORACLE'},
     {app: 'Amazon Redshift', value: 'REDSHIFT', code: 'REDSHIFT'}] as const;
export type DatabaseType = typeof DatabaseTypes[number];

export type RoleSuffixOptions = {
    app?: string,
    value?: string,
    code?: string
};

export interface Pair {
    id?: number;
    question: string;
    sql: string;
    sql_curation_status?: string;
}


export class ChatMessage {
    id?: string;
    text: string;
    author: ChatAuthor;
    created: Date | string;
    prompt: string;
    parentId: string | undefined;
    children?: number;
    imageRevisedPrompt?: string;
    imageUrl?: string;
    generatedFilesIds?: string[];
    chatId: number;
    summary?: string;

    constructor(message: string, author: ChatAuthor, created: Date | string, prompt: string);
    constructor(message: string, author: ChatAuthor, created: Date | string, prompt: string, parentId: string | undefined, chatId: number);

    constructor(message: string, author: ChatAuthor, created: Date | string, prompt: string, parentId: string | undefined, chatId: number, summary: string);

    public constructor(...args: any[]) {
      this.text = args[0];
      this.author = args[1];
      this.created = args[2];
      this.prompt = args[3];
      this.parentId = args[4];
      this.chatId = args[5];
      this.summary = args[6];
    };
  }

  export class ChatAuthor {
    role: string

    constructor(role: string) {
      this.role = role
    }
  }
