<div class="file-manager-modal-container">
  <div id="file-manager-modal-file-actions" class="top-section">
    <div class="segmented-actions-wrapper">
      <div class="segmented-actions">
        <div id="text-button" class="custom-button"
          [ngClass]="{ 'selected': isFileTypeSelected('text'), 'disabled' : isUploading }"
          (click)="setSelectedFileType(this.fileUploadOptions['TEXT'])">
          <label>Text</label>
        </div>
        <div id="spreadsheets-button" class="custom-button"
          [ngClass]="{ 'selected': isFileTypeSelected('spreadsheets'), 'disabled' : isUploading  }"
          (click)="setSelectedFileType(this.fileUploadOptions['SPREADSHEETS'])">
          <label>Spreadsheet</label>
        </div>
      </div>
      <div class="beta-chip-wrapper" *ngIf="isFileTypeSelected('spreadsheets')">
        <app-chip [text]="'Beta'" [type]="betaBadgeDetails" />
      </div>
      <div id="search-bar-container">
        <img class="search-icon" src="../../../../../assets/icons/search/search.svg" alt="">
        <input type="text" class="search-term" kmdFormInput id="file-search-input"
          [placeholder]="isMobile ? 'Search' : 'Search documents'" autocomplete="off" (input)="onSearchDocument($event)"
          [(ngModel)]="search" />
      </div>
    </div>
    <div class="file-type-help-text" *ngIf="isFileTypeSelected('text')">
      Upload your documents for query. <a
        href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Ask%20My%20Docs.aspx?csf=1&web=1&e=dsIv1i&CID=58f1399c-026a-4ed8-9e10-6127d3ec23e2"
        target="_blank">Learn more</a>
    </div>
    <div class="file-type-help-text" *ngIf="isFileTypeSelected('spreadsheets')">
      Upload your spreadsheets for query. See <a
        href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Our-Data.aspx?csf=1&web=1&e=szXWYn&OR=Teams-HL&CT=1727285922117&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA4MTcwMDQyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&CID=6697aa55-d375-45a6-b921-16ccbcb8b260"
        target="_blank">formatting details and more.</a>
      <div class="beta-chip-wrapper inline" *ngIf="isFileTypeSelected('spreadsheets')">
        <app-chip [text]="'Beta'" [type]="betaBadgeDetails" />
      </div>
    </div>
  </div>
  <div class="middle-section">
    <div class="file-manager-modal-mobile-scrollable">
      <div id="file-manager-modal-upload">
        <app-file-manager-upload [uploadType]="selectedFileType" (onCancel)="closeModal()"
          (onFileProcessed)="onFileUploaded($event)" (onUploadingInProgress)="onUploadingInProgress($event)" />
      </div>
      <div id="file-manager-modal-mydocs">
        <app-file-manager-my-docs [fileType]="selectedFileType.fileType" [searchTerm]="searchTerm" />
      </div>
    </div>
  </div>
  <div class="bottom-section">
    <div id="file-manager-action-controls">
      <app-file-manager-action-controls (onClose)="closeModal()" />
    </div>
    <div id="confirmation-modal-wrapper" class="custom-modal">
      <file-manager-confirmation-modal [fileType]="selectedFileType.fileType" [headline]="getConfirmationModalHeadline()"
        (onConfirm)="onConfirmConfirmationModal($event)" (onCancel)="onCancelConfirmationModal()" />
    </div>
    <div id="acknowledgement-modal-wrapper" class="custom-modal">
      <app-file-manager-acknowledgement-modal (onCancel)="closeAcknowledgementModal()"
        (onConfirm)="onAcknowledgementAccepted()" />
    </div>
  </div>
</div>
